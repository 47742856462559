import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import Button from '../others/Button'
import SelectorForm from '../fields/SelectorForm'
import Input from '../fields/Input'
import { FormAttributes } from './interfaces'

function AddKamarSantri({closeForm,data,showForm,submit,options_}:FormAttributes) {
    const methdoAddKamarSantri = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid_santri: yup.string().required("tidak boleh kosong"),
                uuid_kamar: yup.string().required("tidak boleh kosong"),
            })
        )
    })
    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            methdoAddKamarSantri.reset({ uuid_santri: data?.santri.uuid, uuid_kamar: "" })
        }
    }, [showForm, data])
    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Tambah Kamar</h1>
                </div>
                <form onSubmit={methdoAddKamarSantri.handleSubmit(submit)} className='px-6 py-7'>
                    <div className='mb-4 space-y-1'>
                        <Input className="w-full" label="Nama" value={data?.santri?.nama ? data?.santri?.nama : ""} read={true} />
                        <SelectorForm label="Kamar" method={methdoAddKamarSantri} methodName="uuid_kamar" options={options_||[]} />
                    </div>
                    <div className='flex justify-end'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default AddKamarSantri