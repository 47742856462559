import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import InputForm from '../fields/InputForm'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import Button from '../others/Button'
import SelectorForm from '../fields/SelectorForm'
import { penjemputOpt } from '../others/Penjemput'
import { FormAttributes } from './interfaces'

function AddPenjemput({closeForm,showForm,submit,uuid}:FormAttributes) {
    const methodAddListPenjemput = useForm({
        mode:"all",
        resolver:yupResolver(
            yup.object().shape({
                nama:yup.string().required("tidak boleh kosong"),
                status_penjemput:yup.string().required("tidak boleh kosong"),
                no_telpon:yup.string().required("tidak boleh kosong"),
                uuid_santri:yup.string().required("tidak boleh kosong"),
            })
        )
    })
    useEffect(()=>{
        if(showForm && uuid !== ""){
            methodAddListPenjemput.reset({uuid_santri:uuid,nama:"",status_penjemput:""})
        }
    },[showForm,uuid])

    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Tambah List Penjemput</h1>
                </div>
                <form onSubmit={methodAddListPenjemput.handleSubmit(submit)} className='px-6 py-7'>
                    <InputForm label="Nama Penjemput" method={methodAddListPenjemput} methodName="nama" />
                    <SelectorForm label="Status" method={methodAddListPenjemput} methodName="status_penjemput" options={penjemputOpt} />
                    <InputForm label="No Hp" method={methodAddListPenjemput} methodName="no_telpon" />
                    <div className='flex justify-end mt-5'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
        )
}

export default AddPenjemput