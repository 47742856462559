import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiPembinaan } from "../../api/pembinan";
import { ErrorData } from "../interfaces";
import { PembinaanAttributes } from "../../type";


export const addPembinaan =  createAsyncThunk<any,PembinaanAttributes,{rejectValue:ErrorData}>(
    'pembinaan/post',
    async(data:PembinaanAttributes,{rejectWithValue})=>{
        try{
            const res : any = await ApiPembinaan.postPembinaan(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)