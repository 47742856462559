import { createSlice } from "@reduxjs/toolkit";
import { stat } from "fs/promises";
import { addPembinaan } from "../actions/pembinaan";
import { Pembinaan } from "../interfaces";

const initialState : Pembinaan = {
    pembinaanAll : [],
    pembinaanOne : {},
    msgPembinaan : "",
    loadingPembinaan : false
}

export const pembinaanStore = createSlice({
    name:"pembinaan",
    initialState,
    reducers : {
        clearPembinaan : (state)=>{
            state.pembinaanAll = []
            state.msgPembinaan = ""
        },
        clearPembinaanOne : (state)=>{
            state.pembinaanOne = {}
            state.msgPembinaan = ""
        }
    },
    extraReducers : builder =>{
        builder
        .addCase(addPembinaan.pending,(state)=>{
            state.loadingPembinaan = true
        })
        .addCase(addPembinaan.fulfilled,(state,action)=>{
            state.loadingPembinaan = false
            state.msgPembinaan = action.payload.msg
        })
        .addCase(addPembinaan.rejected,(state,action)=>{
            state.loadingPembinaan = false
            state.msgPembinaan = action.payload?.message
        })
    }
})
export const pembinaanActions = pembinaanStore.actions
export default pembinaanStore.reducer