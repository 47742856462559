import React, { useEffect, useState } from 'react'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { prestasiSantriActions } from '../store/slice/prestasiSantri'
import Alert from '../templates/Alert'
import AddAkumulasi from '../component/form/AddAkumulasi'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import Button from '../component/others/Button'
import { CiCalculator2 } from 'react-icons/ci'
import { allPrestasiSantri } from '../store/actions/prestasiSantri'
import { addAkumulasiPoint } from '../store/actions/akumulasiPoint'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'
import { formatDayNoTime } from '../helper/formatTime'
import Loading from '../templates/Loading'

function AkumulasiPrestasiAll() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const navigate = useNavigate()
    const { prestasiSantriAll, loadingPrestasiSantri, msgPrestasiSantri } = useSelector((state: RootState) => state.prestasiSantri)
    const { msgAkumulasi } = useSelector((state: RootState) => state.akumulasiPoint)
    const [showFormAddAkumulasi, setShowFormAddAkumulasi] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [tableHead, setTableHead] = useState(["No", "Tahun Ajar", "Lembaga", "Nama", "Kelas", "Ruang","Tanggal", "Ket", "Jumlah Point", "Akumulasikan"])
    const [saveOne, setSaveOne] = useState<any>({})
    const saveAkumulasi = (e: {}) => {
        dispatch(addAkumulasiPoint({ uuid: saveOne.uuid, post: e }))
        setShowFormAddAkumulasi(false)
        setShowAlert(true)
    }
    useEffect(() => {
        dispatch(prestasiSantriActions.clearPrestasiSantri())
    }, [])
    useEffect(() => {
        if (showAlert) {
            const interval = setInterval(() => {
                setShowAlert(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            dispatch(allPrestasiSantri("0"))
        }
    }, [showAlert])
    return (
        <div>
            <Loading show={loadingPrestasiSantri}/>
            <Alert show={showAlert} msg={msgAkumulasi} />
            <AddAkumulasi showForm={showFormAddAkumulasi} closeForm={setShowFormAddAkumulasi} data={saveOne} submit={saveAkumulasi} />
            <HeaderUi title="Prestasi santri" />
            <Table>
                <div className='flex justify-end'>
                    <Button type="button" title="Telah diproses" onClick={() => navigate("/home/prestasi/succes")} />
                </div>
            </Table>
            <Table>
                <div>
                    {Object.keys(prestasiSantriAll).length === 0 ?
                        <EmptyTable title="Tidak ada prestasi" />
                        :
                        <TableFields head={tableHead}>
                            {prestasiSantriAll.map((d: any, id: any) => (
                                <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                    <td className='px-6 py-3'>{id + 1}</td>
                                    <td className='px-6 py-3'>{d.thn_ajar}</td>
                                    <td className='px-6 py-3'>{d.santri?.lembaga?.nama}</td>
                                    <td className='px-6 py-3'>{d.santri?.nama}</td>
                                    <td className='px-6 py-3'>{d.santri.kelas}</td>
                                    <td className='px-6 py-3'>{d.santri.ruang}</td>
                                    <td className='px-6 py-3'>{d.tanggal_kejadian ? formatDayNoTime(d.tanggal_kejadian) : "-"}</td>
                                    <td className='px-6 py-3'>{d.prestasi?.judul}</td>
                                    <td className='px-6 py-3'>{d.prestasi?.jumlahPoint}</td>
                                    <td className='px-6 py-3'><CiCalculator2 className='w-8 h-8 mx-10 cursor-pointer' onClick={() => {
                                        setSaveOne(d)
                                        setShowFormAddAkumulasi(true)
                                    }} /></td>
                                </tr>
                            ))}
                        </TableFields>
                    }
                </div>
            </Table>
        </div>
    )
}

export default AkumulasiPrestasiAll