import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiSantri } from "../../api/santri";
import { ErrorData } from "../interfaces";

export const searchSantri = createAsyncThunk<[],string,{rejectValue:ErrorData}>(
    'santri/search',
    async (key:string,{rejectWithValue})=>{
        try{
            const res = await ApiSantri.getByKey(key)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const oneSantri = createAsyncThunk<{},any,{rejectValue:ErrorData}>(
    'santri/one',
    async(uuid:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiSantri.getOne(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const oneStatusSantri = createAsyncThunk<{},any,{rejectValue:ErrorData}>(
    'santri/status/one',
    async(uuid:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiSantri.getOneStatus(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const updateStatusMondokSantri = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'santri/status/update',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiSantri.putStatus(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)