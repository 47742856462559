import React, { useEffect, useState } from 'react'
import HeaderUi from '../templates/HeaderUi'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import { allLembaga, allThnAjar } from '../store/actions/options'
import Table from '../templates/Table'
import Selector from '../component/fields/Selector'
import Button from '../component/others/Button'
import { pointSantriAction } from '../store/slice/PointSantri'
import { generatePdfPointLembaga } from '../store/actions/pointSantri'
import { generatePdfPrestasiLembaga } from '../store/actions/prestasiSantri'
import { prestasiSantriActions } from '../store/slice/prestasiSantri'
import { formatDatePdf } from '../helper/formatTime'

function RekapPointLembaga() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { tahunAjar, lembagaAll } = useSelector((state: RootState) => state.options)
    const { loadingPointSantri, msgPointSantri, succesPdfPoint } = useSelector((state: RootState) => state.pointSantri)
    const { loadingPrestasiSantri, msgPrestasiSantri, succesPdfPrestasi } = useSelector((state: RootState) => state.prestasiSantri)
    const [tahun, setTahun] = useState<any>({})
    const [lembaga, setLembaga] = useState<any>({})
    const [title, setTitle] = useState<string>('')
    const tahunOpt = tahunAjar.map((d: any) => {
        return { value: d.tahun, label: d.tahun }
    })
    const lembagaOpt = lembagaAll.map((d: any) => {
        return { value: d.kode_lembaga, label: d.nama }
    })
    useEffect(() => {
        dispatch(allThnAjar())
        dispatch(allLembaga())
    }, [])
    useEffect(() => {
        if (succesPdfPoint || succesPdfPrestasi) {
            const date = new Date()
            window.open(`http://localhost:8000/${title}_${lembaga?.label}_${formatDatePdf(date)}.pdf`)
            dispatch(pointSantriAction.clearPdf())
            dispatch(prestasiSantriActions.clearPdf())
        }
    }, [succesPdfPoint,succesPdfPrestasi])
    return (
        <div>
            <HeaderUi title="Rekap Point Lembaga" />
            <Table>
                <div className='flex justify-between w-full'>
                    <div className='flex space-x-4'>
                        <div className='w-72'>
                            <Selector title="Tahun Ajar" value={tahun} onChange={(e: any) => setTahun(e)} options={tahunOpt} />
                        </div>
                        <div className='w-72'>
                            <Selector title="Lembaga" value={lembaga} onChange={(e: any) => setLembaga(e)} options={lembagaOpt} />
                        </div>
                    </div>
                    <div className='mt-6 space-x-5'>
                        <Button type="button" title="Download PDF Point" onClick={() => {
                            setTitle("Point")
                            dispatch(generatePdfPointLembaga({ kode_lembaga: lembaga?.value, thn_ajar: tahun?.value }))
                        }} />
                        <Button type="button" title="Download PDF Prestasi" onClick={() => {
                            setTitle("Prestasi")
                            dispatch(generatePdfPrestasiLembaga({ kode_lembaga: lembaga?.value, thn_ajar: tahun?.value }))
                        }} />
                    </div>
                </div>
            </Table>
        </div>
    )
}

export default RekapPointLembaga