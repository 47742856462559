import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import InputForm from '../component/fields/InputForm';
import { HiEye, HiEyeSlash } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../templates/Loading';
import AlertLogin from '../templates/AlertLogin';
import { isLogin } from '../store/actions/auth';
import { RootState, TypeDispatch, useAppDispatch } from '../store';
import logo from "../assets/aspirasi.png"


function Login() {
    const dispatch : TypeDispatch<any> = useAppDispatch()
    const {loadingAuth,msgAuth} = useSelector((state:RootState)=>state.auth)
    const [eye, setEye] = useState<boolean>(false)
    const [alertLogin, setAlertLogin] = useState<boolean>(false)
    const methodLogin = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                username: yup.string().required("Username Tidak Boleh Kosong"),
                password: yup.string().required("Password Tidak Boleh Kosong").min(8, "Password Minimal 8 Karakater"),
                sistem : yup.string(),
            })
        ),
        defaultValues:{sistem:"perizinan"}
    })
    const login = (e:any)=>{
        dispatch(isLogin(e))
        setAlertLogin(true)
    }
    useEffect(()=>{
        if (alertLogin) {
            const interval = setInterval(() => {
                setAlertLogin(false)
            }, 2000)
            return () => clearInterval(interval)
        }
    },[alertLogin])
    return (
        <div className='overflow-x-hidden bg-gray-100'>
            <Loading show={loadingAuth} />
            <AlertLogin show={alertLogin} msg={msgAuth} />
            <form className='relative md:w-[30vw] md:h-[100vh] h-[100vh] flex flex-col justify-center mx-auto md:px-0 px-3' onSubmit={methodLogin.handleSubmit(login)}>
                <div className='md:flex flex-col'>
                    <div className='flex justify-center md:ml-3'>
                        <img src={logo} className="w-40 h-40"/>
                    </div>
                    <h1 className="md:text-[3vmin] text-xl mb-5 md:text-left text-center md:mt-3 mt-1 mx-auto">ASPIRASI</h1>
                    <h1 className="md:text-[3vmin] text-xl mb-5 md:text-left text-center md:mt-3 mt-1 mx-auto">Asrama Pengasuhan dan Rekam Santri</h1>
                </div>
                <div className='space-y-8 mb-8'>
                    <InputForm
                        label="username"
                        method={methodLogin}
                        methodName="username"
                    />
                    <InputForm
                        type={eye ? "text" : "password"}
                        label="Password"
                        method={methodLogin}
                        methodName="password"
                        icon={eye ? <HiEye className='w-6 h-6 -ml-8 cursor-pointer absolute right-5 mt-3' onClick={() => setEye(!eye)} /> : <HiEyeSlash className='w-6 h-6 my-auto -ml-8 cursor-pointer absolute right-5 mt-3' onClick={() => setEye(!eye)} />}
                    />
                </div>
                <button
                    className="from-sky-600 to-sky-300 py-3 shadow-xl hover:from-sky-700 hover:to-sky-400 bg-gradient-to-bl font-medium rounded-lg"
                    type="submit"
                >Masuk</button>
            </form>
        </div>
    )
}

export default Login