import React, { useEffect, useState } from 'react'
import { HiOutlineTrash } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import Selector from '../component/fields/Selector'
import { genderOpt, milikOpt } from '../component/Gender'
import Button from '../component/others/Button'
import DialogDelete from '../component/others/DialogDelete'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { allKamarSantri, allKamarSantriByUuid, dropKamarSantri } from '../store/actions/kamarSantri'
import { allListKamar, allListKamarByGender } from '../store/actions/listKamar'
import { kamarSantriAction } from '../store/slice/kamarSantri'
import { listKamarAction } from '../store/slice/listKamar'
import Alert from '../templates/Alert'
import HeaderUi from '../templates/HeaderUi'
import Loading from '../templates/Loading'
import Table from '../templates/Table'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'

function Kamarsantri() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { listKamarAll, loadingListKamar, msgListKamar } = useSelector((state: RootState) => state.listKamar)
    const { kamarSantriAll, loadingKamarSantri, msgKamarSantri } = useSelector((state: RootState) => state.kamarSantri)
    const [tableHead, setTableHead] = useState(["No", "Nuwb", "Nama", "Lembaga", "kamar", "Delete"])
    const { role, super_admin, generalUser } = useSelector((state: RootState) => state.auth)
    const [gender, setGender] = useState<any>({})
    const [kamar, setKamar] = useState<any>({ value: "-", label: "Semua" })
    const [saveOne, setSaveOne] = useState<any>({})
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const kamarOpt = listKamarAll.map((d: any) => {
        return { value: d.uuid, label: d.namaKamar }
    })
    kamarOpt.splice(0, 0, { value: "-", label: "Semua" })
    const hapus = () => {
        dispatch(dropKamarSantri(saveOne.uuid))
        setShowDelete(false)
        setShowAlert(true)
    }
    useEffect(() => {
        dispatch(listKamarAction.clearList())
        dispatch(kamarSantriAction.clearKamarSantri())
    }, [])
    useEffect(() => {
        if (super_admin) {
            if (gender.value !== '-') {
                dispatch(allListKamarByGender(gender.value))
            }
            else {
                dispatch(allListKamar())
            }
        }
        else {
            if (role === "putra") {
                dispatch(allListKamarByGender("L"))
            }
            else {
                dispatch(allListKamarByGender("P"))
            }
        }
    }, [gender, role, super_admin])
    useEffect(() => {
        if (showAlert) {
            const interval = setInterval(() => {
                setShowAlert(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            if (kamar.value !== undefined) {
                if (generalUser) {
                    dispatch(allKamarSantriByUuid({ uuid: kamar.value, gender: gender.value }))
                }
                else {
                    dispatch(allKamarSantriByUuid({ uuid: kamar.value, gender: "-" }))

                }
            }
        }
    }, [showAlert, gender, kamar])
    return (
        <div>
            <Loading show={loadingKamarSantri} />
            <Alert show={showAlert} msg={msgKamarSantri} />
            <DialogDelete msg="Anda yakin ingin menghapus?" show={showDelete} close={setShowDelete} onClick={hapus} />
            <HeaderUi title="Kamar Santri" />
            <Table>
                <div className=' grid grid-cols-3 gap-4'>
                    <div className={`${generalUser ? "block" : "hidden"}`}>
                        <Selector title="Asrama" value={gender} onChange={(e: any) => setGender(e)} options={milikOpt} />
                    </div>
                    <Selector title="Kamar" value={kamar} onChange={(e: any) => setKamar(e)} options={kamarOpt} />
                </div>
            </Table>
            <Table>
                {Object.keys(kamarSantriAll).length === 0 ?
                    <EmptyTable title='Tidak ada santri' />
                    :
                    <TableFields head={tableHead}>
                        {kamarSantriAll.map((d: any, id: any) => (
                            <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                <td className='px-6 py-3'>{id + 1}</td>
                                <td className='px-6 py-3'>{d.santri?.nuwb}</td>
                                <td className='px-6 py-3'>{d.santri?.nama}</td>
                                <td className='px-6 py-3'>{d.santri?.lembaga?.nama}</td>
                                <td className='px-6 py-3'>{d.list_kamar?.namaKamar}</td>
                                <td className='px-6 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                    setSaveOne(d)
                                    setShowDelete(true)
                                }} /></td>
                            </tr>
                        ))}
                    </TableFields>
                }
            </Table>
        </div>
    )
}

export default Kamarsantri