import { createSlice } from "@reduxjs/toolkit";
import { allPerizinan,addPerizinan,editPerizinan,dropPerizinan, editStatusPerizinan, getCetakKartu, allPerizinanBySantri, allPerizinanByKey } from "../actions/perizinan";
import { PerizinanSantri } from "../interfaces";

const initialState : PerizinanSantri = {
    perizinanAll : [],
    perizinanOne : {},
    loadingPerizinan: false,
    msgPerizinan : ""
}

export const perizinanSantriStore = createSlice({
    name:"perizinan",
    initialState,
    reducers:{
        clearPerizinanSantri : (state)=>{
            state.perizinanAll = []
            state.msgPerizinan = ""
        },
        clearOnePerizinanSantri : (state)=>{
            state.perizinanOne = {}
            state.msgPerizinan = ""
        }
    },
    extraReducers: builder =>{
        builder
        .addCase(allPerizinan.pending,(state)=>{
            state.loadingPerizinan = true
        })
        .addCase(allPerizinan.fulfilled,(state,action)=>{
            state.loadingPerizinan = false
            state.perizinanAll = action.payload
        })
        .addCase(allPerizinan.rejected,(state,action)=>{
            state.loadingPerizinan = false
            state.perizinanAll = []
            state.msgPerizinan = action.payload?.message
        })
        .addCase(allPerizinanByKey.pending,(state)=>{
            state.loadingPerizinan = true
        })
        .addCase(allPerizinanByKey.fulfilled,(state,action)=>{
            state.loadingPerizinan = false
            state.perizinanAll = action.payload
        })
        .addCase(allPerizinanByKey.rejected,(state,action)=>{
            state.loadingPerizinan = false
            state.perizinanAll = []
            state.msgPerizinan = action.payload?.message
        })
        .addCase(allPerizinanBySantri.pending,(state)=>{
            state.loadingPerizinan = true
        })
        .addCase(allPerizinanBySantri.fulfilled,(state,action)=>{
            state.loadingPerizinan = false
            state.perizinanAll = action.payload
        })
        .addCase(allPerizinanBySantri.rejected,(state,action)=>{
            state.loadingPerizinan = false
            state.perizinanAll = []
            state.msgPerizinan = action.payload?.message
        })
        .addCase(getCetakKartu.pending,(state)=>{
            state.loadingPerizinan = true
        })
        .addCase(getCetakKartu.fulfilled,(state,action)=>{
            state.loadingPerizinan = false
            state.perizinanOne = action.payload
        })
        .addCase(getCetakKartu.rejected,(state,action)=>{
            state.loadingPerizinan = false
            state.perizinanOne = {}
            state.msgPerizinan = action.payload?.message
        })
        .addCase(addPerizinan.pending,(state)=>{
            state.loadingPerizinan = true
        })
        .addCase(addPerizinan.fulfilled,(state,action)=>{
            state.loadingPerizinan = false
            state.msgPerizinan = action.payload.msg
        })
        .addCase(addPerizinan.rejected,(state,action)=>{
            state.loadingPerizinan = false
            state.msgPerizinan = action.payload?.message
        })
        .addCase(editPerizinan.pending,(state)=>{
            state.loadingPerizinan = true
        })
        .addCase(editPerizinan.fulfilled,(state,action)=>{
            state.loadingPerizinan = false
            state.msgPerizinan = action.payload.msg
        })
        .addCase(editPerizinan.rejected,(state,action)=>{
            state.loadingPerizinan = false
            state.msgPerizinan = action.payload?.message
        })
        .addCase(dropPerizinan.pending,(state)=>{
            state.loadingPerizinan = true
        })
        .addCase(dropPerizinan.fulfilled,(state,action)=>{
            state.loadingPerizinan = false
            state.msgPerizinan = action.payload.msg
        })
        .addCase(dropPerizinan.rejected,(state,action)=>{
            state.loadingPerizinan = false
            state.msgPerizinan = action.payload?.message
        })
        .addCase(editStatusPerizinan.pending,(state)=>{
            state.loadingPerizinan = true
        })
        .addCase(editStatusPerizinan.fulfilled,(state,action)=>{
            state.loadingPerizinan = false
            state.msgPerizinan = action.payload.msg
        })
        .addCase(editStatusPerizinan.rejected,(state,action)=>{
            state.loadingPerizinan = false
            state.msgPerizinan = action.payload?.message
        })
    }
})
export const perizinanSantriActions = perizinanSantriStore.actions
export default perizinanSantriStore.reducer