import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { RootState, TypeDispatch, useAppDispatch } from '../store';
import { useSelector } from 'react-redux';
import { allDasboardSantriByLem, allDashboardListPerizinan } from '../store/actions/dashboard';
import HeaderUi from '../templates/HeaderUi';
import EmptyTable from '../templates/EmptyTable';
import Table from '../templates/Table';
import CardDashboard from '../templates/CardDashboard';
import Loading from '../templates/Loading';

function DashboardSantriByLembaga() {
    const { gender } = useParams<any>()
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { dashboardByLembaga, loadingDashboard } = useSelector((state: RootState) => state.dashboard)
    const [showRekapData, setShowRekapData] = useState<any>([])
    useEffect(() => {
        if (gender !== "") {
            dispatch(allDasboardSantriByLem(gender))
        }
    }, [gender])
    return (
        <div>
            <Loading show={loadingDashboard} />
            <HeaderUi title="Dashboard Santri Per lembaga" />
            <Table>
                {dashboardByLembaga.length === 0 ?
                    <EmptyTable title="Tidak ada list" />
                    :
                    <div className='grid md:grid-cols-4 grid-cols-1 w-full gap-5'>
                        {dashboardByLembaga.map((d: any, id: number) => (
                            <Link to={`/home/dashboard/perizinan/in-asrama/${d.kode_lembaga}`}>
                                <CardDashboard key={id} title={d?.nama} value={d?.total} />
                            </Link>
                        ))}
                    </div>
                }
            </Table>
        </div>
    )
}

export default DashboardSantriByLembaga