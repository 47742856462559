import { createSlice } from "@reduxjs/toolkit";
import { PointAbsensiAttributes } from "../interfaces";
import { deletePointAbsensi, getAllPointAbsensi, postPointAbsensi } from "../actions/pointAbsensi";

const initialState:PointAbsensiAttributes={
    allPointAbsensi:[],
    loadingPointAbsensi :false,
    messagePointAbsensi:""
}

export const PointAbsensiStore = createSlice({
    name:"pointAbsensi",
    initialState,
    reducers:{
        clearPointAbsensi:(state)=>{
            state.allPointAbsensi = []
            state.messagePointAbsensi = ""
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getAllPointAbsensi.pending,(state)=>{
            state.loadingPointAbsensi = true
        })
        .addCase(getAllPointAbsensi.fulfilled,(state,action)=>{
            state.loadingPointAbsensi = false
            state.allPointAbsensi = action.payload
        })
        .addCase(getAllPointAbsensi.rejected,(state,action)=>{
            state.loadingPointAbsensi = false
            state.allPointAbsensi = []
            state.messagePointAbsensi = action.payload
        })
        .addCase(postPointAbsensi.pending,(state)=>{
            state.loadingPointAbsensi = true
        })
        .addCase(postPointAbsensi.fulfilled,(state,action)=>{
            state.loadingPointAbsensi = false
            state.messagePointAbsensi = action.payload?.msg
        })
        .addCase(postPointAbsensi.rejected,(state,action)=>{
            state.loadingPointAbsensi = false
            state.messagePointAbsensi = action.payload
        })
        .addCase(deletePointAbsensi.pending,(state)=>{
            state.loadingPointAbsensi = true
        })
        .addCase(deletePointAbsensi.fulfilled,(state,action)=>{
            state.loadingPointAbsensi = false
            state.messagePointAbsensi = action.payload?.msg
        })
        .addCase(deletePointAbsensi.rejected,(state,action)=>{
            state.loadingPointAbsensi = false
            state.messagePointAbsensi = action.payload
        })
    }
})

export const PointAbsensiActions = PointAbsensiStore.actions
export default PointAbsensiStore.reducer;