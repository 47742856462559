import React, { useEffect, useState } from 'react'
import Table from '../templates/Table'
import HeaderUi from '../templates/HeaderUi'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import { allPerizinanByLembaga } from '../store/actions/dashboard'
import { useParams } from 'react-router-dom'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'
import { formatDay } from '../helper/formatTime'
import Loading from '../templates/Loading'

function DashboardPerizinanByLembaga() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const route = useParams<any>()
    const { perizinanByLembaga,loadingDashboard } = useSelector((state: RootState) => state.dashboard)
    const [headTable, setHeadTable] = useState(["No", "Nuwb","Lembaga", "Nama","Kelas","Ruang","Kamar", "Dari", "Sampai", "Penjemput", "No Hp", "Keterangan"])
    useEffect(() => {
        dispatch(allPerizinanByLembaga(route.kode_lembaga))
    }, [route])
    console.log(perizinanByLembaga)
    return (
        <div>
            <Loading show={loadingDashboard}/>
            <HeaderUi title="Perizinan per lembaga" />
            <Table>
                {perizinanByLembaga.length === 0 ?
                    <EmptyTable title="Tidak ada list" />
                    :
                    <TableFields head={headTable}>
                        {perizinanByLembaga.map((d: any, i: number) => (
                            <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={i}>
                                <td className='px-6 py-3'>{i + 1}</td>
                                <td className='px-6 py-3'>{d.santri?.nuwb}</td>
                                <td className='px-6 py-3'>{d.santri?.lembaga?.nama}</td>
                                <td className='px-6 py-3'>{d.santri?.nama}</td>
                                <td className='px-6 py-3'>{d.santri?.kelas}</td>
                                <td className='px-6 py-3'>{d.santri?.ruang || "-"}</td>
                                <td className='px-6 py-3'>{d.santri?.kamar_santri?.list_kamar?.namaKamar}</td>
                                <td className='px-6 py-3'>{d.dari ? formatDay(d.Dari):"-"}</td>
                                <td className='px-6 py-3'>{d.sampai ? formatDay(d.sampai):"-"}</td>
                                <td className='px-6 py-3'>{d.listPenjemput?.nama}</td>
                                <td className='px-6 py-3'>{d.listPenjemput?.no_telpon}</td>
                                <td className='px-6 py-3'>{d.keterangan}</td>
                            </tr>
                        ))}
                    </TableFields>
                }
            </Table>
        </div>
    )
}

export default DashboardPerizinanByLembaga