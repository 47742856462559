import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiPrestasi } from "../../api/prestasi";
import { ErrorData } from "../interfaces";

export const allPrestasiSantri = createAsyncThunk<[], string, { rejectValue: ErrorData }>(
    'prestasi-santri/all',
    async (approve: string, { rejectWithValue }) => {
        try {
            const res: any = await ApiPrestasi.getPrestasiSantri(approve)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err: any) {
            return rejectWithValue({ message: err.response.data.msg })
        }
    }
)
export const allCanceledPrestasiSantri = createAsyncThunk<[], void, { rejectValue: ErrorData }>(
    'prestasi-santri/canceled/all',
    async (_, { rejectWithValue }) => {
        try {
            const res: any = await ApiPrestasi.getCanceledPrestasiSantri()
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err: any) {
            return rejectWithValue({ message: err.response.data.msg })
        }
    }
)

export const allPrestasiBySantrinThn = createAsyncThunk<[], any, { rejectValue: ErrorData }>(
    'prestasi-santri/santri/thn',
    async (data: any, { rejectWithValue }) => {
        try {
            const res: any = await ApiPrestasi.getPrestasiBySantrinThn(data.uuid, data.thn)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err: any) {
            return rejectWithValue({ message: err.response.data.msg })
        }
    }
)

export const allPrestasiSantriByDate = createAsyncThunk(
    'get/prestasi-santri/by/date',
    async(data:{start:string,end:string},{rejectWithValue})=>{
        try{
            const res = await ApiPrestasi.getByDate(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err: any) {
            return rejectWithValue({ message: err.response.data.msg })
        }
    }
)
export const addPrestasiSantri = createAsyncThunk<any, {}, { rejectValue: ErrorData }>(
    'prestasi-santri/post',
    async (data: any, { rejectWithValue }) => {
        try {
            const res: any = await ApiPrestasi.postPrestasiSantri(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err: any) {
            return rejectWithValue({ message: err.response.data.msg })
        }
    }
)
export const updatePrestasiSantri = createAsyncThunk<any, {}, { rejectValue: ErrorData }>(
    'prestasi-santri/edit',
    async (data: any, { rejectWithValue }) => {
        try {
            const res: any = await ApiPrestasi.updatePrestasiSantri(data.uuid, data.update)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err: any) {
            return rejectWithValue({ message: err.response.data.msg })
        }
    }
)
export const dropPrestasiSantri = createAsyncThunk<any, {}, { rejectValue: ErrorData }>(
    'prestasi-santri/delete',
    async (data: any, { rejectWithValue }) => {
        try {
            const res: any = await ApiPrestasi.deletePrestasiSantriByUser(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err: any) {
            return rejectWithValue({ message: err.response.data.msg })
        }
    }
)
export const dropCanceledPrestasiSantri = createAsyncThunk<any, {}, { rejectValue: ErrorData }>(
    'prestasi-santri/approve/delete',
    async (data: any, { rejectWithValue }) => {
        try {
            const res: any = await ApiPrestasi.deleteCanceledPrestasiSantri(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err: any) {
            return rejectWithValue({ message: err.response.data.msg })
        }
    }
)

export const generatePdfPrestasiSantri = createAsyncThunk<any, any, { rejectValue: ErrorData }>(
    'prestasi-santri/generate/pdf/santri',
    async (data: any, { rejectWithValue }) => {
        try {
            const res = await ApiPrestasi.getPdfSantri(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err: any) {
            return rejectWithValue({ message: err.response.data.msg })
        }
    }
)

export const generatePdfPrestasiLembaga = createAsyncThunk<any, any, { rejectValue: ErrorData }>(
    'prestasi-santri/generate/pdf/lembaga',
    async (data: any, { rejectWithValue }) => {
        try {
            const res = await ApiPrestasi.getPdfLembaga(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err: any) {
            return rejectWithValue({ message: err.response.data.msg })
        }
    }
)