import React, { useEffect, useState } from 'react'
import { HiOutlineChevronDown } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import AddSistem from '../component/form/AddSistem'
import AddUser from '../component/form/AddUser'
import EditSistem from '../component/form/EditSistem'
import EditUser from '../component/form/EditUser'
import Button from '../component/others/Button'
import DialogDelete from '../component/others/DialogDelete'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { allSistem } from '../store/actions/options'
import { addSistem, addUser, allUser, dropUser, editByAdmin } from '../store/actions/user'
import Alert from '../templates/Alert'
import HeaderUi from '../templates/HeaderUi'
import Loading from '../templates/Loading'
import Table from '../templates/Table'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'

function ListUser() {
  const dispatch: TypeDispatch<any> = useAppDispatch()
  const { generalUser } = useSelector((state: any) => state.auth)
  const { userAll, msgUser, loadingUser } = useSelector((state: RootState) => state.user)
  const [showFormAddUser, setShowFormAddUser] = useState<boolean>(false)
  const [showFormAddSystem, setShowFormAddSystem] = useState<boolean>(false)
  const [showFormEditUser, setShowFormEditUser] = useState<boolean>(false)
  const [showFormEditSistem, setShowFormEditSistem] = useState<boolean>(false)
  const [showFormDeleteUser, setShowFormDeleteUser] = useState<boolean>(false)
  const [alertUser, setAlertUser] = useState<boolean>(false)
  const [dropActions, setDropActions] = useState<boolean>(false)
  const [saveOne, setSaveOne] = useState<any>({})
  const [tableHead, setTableHead] = useState(["No","Nama", "Username", "Sistem", "Role", "Super", "Action"])
  const [uuid, setUuid] = useState<string>("")
  const [checkId, setCheckId] = useState<number>(0)
  const tambahUser = (e: any) => {
    dispatch(addUser(e))
    setShowFormAddUser(false)
    setAlertUser(true)
  }
  const tambahSistem = (e: any) => {
    dispatch(addSistem({ uuid: uuid, post: e }))
    setShowFormAddSystem(false)
    setAlertUser(true)
  }
  const editUser = (e: any) => {
    dispatch(editByAdmin({ uuid: uuid, update: e }))
    setShowFormEditUser(false)
    setAlertUser(true)
  }
  const hapusUser = () => {
    dispatch(dropUser(uuid))
    setShowFormDeleteUser(false)
    setAlertUser(true)
  }
  useEffect(() => {
    if (alertUser) {
      const interval = setInterval(() => {
        setAlertUser(false)
      }, 2000)
      return () => clearInterval(interval)
    }
    else {
      if (!showFormEditSistem) {
        if (generalUser === "true") {
          dispatch(allUser("-"))
        }
        else {
          dispatch(allUser("perizinan"))
        }
      }
    }
  }, [alertUser, showFormEditSistem])
  useEffect(() => {
    dispatch(allSistem())
  }, [])
  return (
    <div>
      <Loading show={loadingUser} />
      <Alert show={alertUser} msg={msgUser} />
      <AddUser showForm={showFormAddUser} closeForm={setShowFormAddUser} submit={tambahUser} />
      <AddSistem showForm={showFormAddSystem} closeForm={setShowFormAddSystem} submit={tambahSistem} />
      <EditUser showForm={showFormEditUser} closeForm={setShowFormEditUser} data={saveOne} submit={editUser} />
      <EditSistem showForm={showFormEditSistem} closeForm={setShowFormEditSistem} data={saveOne} submit={()=>console.log()} />
      <DialogDelete msg="Anda yakin ingin menghapus user ini?" show={showFormDeleteUser} close={setShowFormDeleteUser} onClick={hapusUser} />
      <HeaderUi title="List User" />
      <Table>
        <div className='flex justify-end'>
          <Button type="button" title="Tambah" onClick={() => setShowFormAddUser(true)} />
        </div>
      </Table>
      <Table>
        {userAll.length === 0 ?
          <EmptyTable title="Tidak Ada User" />
          :
          <TableFields head={tableHead}>
            {userAll.map((d: any, id: number) => (
              <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                <td className='px-6 py-3'>{id + 1}</td>
                <td className='px-6 py-3'>{d.nama}</td>
                <td className='px-6 py-3'>{d.username}</td>
                <td className='px-6 py-3'>{d.sistem.map((e: any, id: number) => (
                  <h1 key={id} className=''>{e.nama_sistem}</h1>
                ))}</td>
                <td className='px-6 py-3'>
                  {d.sistem.map((f: any, id: number) => (
                    <h1 key={id} className=''>{f.nama_role}</h1>
                  ))}
                </td>
                <td className='px-6 py-3'>{d.super_admin ? "Super Admin" : "Admin"}</td>
                <td className='px-6 py-3 relative'><HiOutlineChevronDown className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                  setDropActions(!dropActions)
                  setCheckId(id)
                }} /><div className={`border absolute top-8 z-50 shadow-lg bg-slate-100 ease-in-out transition-all duration-200 ${dropActions ? `mt-2 bg-opacity-100 visible` : `-mt-0 text-transparent bg-opacity-0 invisible`} ${id === checkId ? "block" : "hidden"} `}>
                    <div className='py-2 px-2 hover:bg-slate-200 cursor-pointer' onClick={() => {
                      setDropActions(!dropActions)
                      setSaveOne(d)
                      setShowFormEditUser(!showFormEditUser)
                      setUuid(d.uuid)
                    }}>
                      Edit User
                    </div>
                    <div className='py-2 px-2 hover:bg-slate-200 cursor-pointer' onClick={() => {
                      setShowFormAddSystem(!showFormAddSystem)
                      setUuid(d.uuid)
                      setDropActions(!dropActions)
                    }}>
                      Tambah Sistem
                    </div>
                    <div className='py-2 px-2 hover:bg-slate-200 cursor-pointer' onClick={() => {
                      setSaveOne(d)
                      setUuid(d.uuid)
                      setDropActions(!dropActions)
                      setShowFormEditSistem(!showFormEditSistem)
                    }}>
                      Edit Sistem
                    </div>
                    <div className='py-2 px-2 hover:bg-slate-200 cursor-pointer' onClick={() => {
                      setShowFormDeleteUser(!showFormDeleteUser)
                      setUuid(d.uuid)
                      setDropActions(!dropActions)
                    }}>
                      Delete
                    </div>
                  </div></td>
              </tr>
            ))}
          </TableFields>
        }
      </Table>
    </div>
  )
}

export default ListUser