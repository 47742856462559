import api from "./http";

export const ApiDashboard = {
    getDashboardSantri : () =>{
        return api.get(`/perizinan/dashboard`)
    },
    getDasboardSantriByLembaga : (gender:string)=>{
        return api.get(`/perizinan/dashboard/lembaga/${gender}`)
    },
    getPerizinanByIzin :(id:string)=>{
        return api.get(`/perizinan/dashboard/filter/${id}`)
    },
    getDashboardListPerizinan :(gender:string)=>{
        return api.get(`/perizinan/dashboard/rekap/${gender}`)
    },
    getMostManyPoint : () =>{
        return api.get(`/perizinan/dashboard/many/point`)
    },
    getMostManyPrestasi : ()=>{
        return api.get(`/perizinan/dashboard/many/prestasi`)
    },
    getMostManyPerizinan : ()=>{
        return api.get(`/perizinan/dashboard/many/perizinan`)
    },
    getPerizinanByLembaga : (kode_lembaga:string)=>{
        return api.get(`/perizinan/dashboard/lembaga/filter/${kode_lembaga}`)
    },
    getSantriInAsrama:(kode_lembaga:string) =>{
        return api.get(`/perizinan/dashboard/santri/in-asrama/${kode_lembaga}`)
    }
}