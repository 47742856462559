import React, { useEffect, useState } from 'react'
import { HiOutlinePencilSquare, HiOutlineTrash } from 'react-icons/hi2'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Selector from '../component/fields/Selector'
import AddPointSantri from '../component/form/AddPointSantri'
import EditPointSantri from '../component/form/EditPointSantri'
import Button from '../component/others/Button'
import { allListPoint } from '../store/actions/listPoint'
import { allThnAjar } from '../store/actions/options'
import { addPointSantri, allPointSantriByUuid, dropPointSantri, editPointSantri, generatePdfSantri } from '../store/actions/pointSantri'
import Alert from '../templates/Alert'
import HeaderUi from '../templates/HeaderUi'
import Loading from '../templates/Loading'
import Table from '../templates/Table'
import { pointSantriAction, pointSantriStore } from '../store/slice/PointSantri'
import { FaPeopleArrows } from 'react-icons/fa'
import { RxCross2 } from "react-icons/rx";
import AddPembinaan from '../component/form/AddPembinaan'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { addPembinaan } from '../store/actions/pembinaan'
import DeletePointByUser from '../component/form/DeletePointByUser'
import { oneSantri } from '../store/actions/santri'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'
import { formatDatePdf, formatDayNoTime, formatWithoutDay } from '../helper/formatTime'

function PointSantri() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { tahunAjar } = useSelector((state: RootState) => state.options)
    const { pointSantriAll, loadingPointSantri, msgPointSantri, succesPdfPoint } = useSelector((state: RootState) => state.pointSantri)
    const { santriOne } = useSelector((state: RootState) => state.santri)
    const { msgPembinaan, loadingPembinaan } = useSelector((state: RootState) => state.pembinaan)
    const { listPointAll } = useSelector((state: RootState) => state.listPoint)
    const [showFormAddBimbingan, setShowFormAddBimbingan] = useState<boolean>(false)
    const [showFormAddPoint, setShowFormAddPoint] = useState<boolean>(false)
    const [showFormEditPoint, setShowFormEditPoint] = useState<boolean>(false)
    const [showDeletePoint, setShowDeletePoint] = useState<boolean>(false)
    const [showAlertPoint, setShowAlertPoint] = useState<boolean>(false)
    const [showAlertPembinaan, setShowAlertPembinaan] = useState<boolean>(false)
    const [saveOne, setSaveOne] = useState<any>({})
    const [tableHead, setTableHead] = useState(["No", "Tanggal", "Point", "Deskripsi", "Jumlah", "Edit", "Delete", "Pembinaan"])
    const [totalPoint, setTotalPoint] = useState<number>(0)
    const [tahun, setTahun] = useState<any>({})
    const tahunOpt = tahunAjar.map((d: any) => {
        return { value: d.tahun, label: d.tahun }
    })
    const pointOpt = listPointAll.map((d: any) => {
        return { value: d.uuid, label: d.judul }
    })
    const tambah = (e: any) => {
        dispatch(addPointSantri(e))
        setShowFormAddPoint(false)
        setShowAlertPoint(true)
    }
    const edit = (e: any) => {
        dispatch(editPointSantri({ uuid: saveOne.uuid, update: e }))
        setShowFormEditPoint(false)
        setShowAlertPoint(true)
    }
    const hapusProcessed = (e: any) => {
        dispatch(dropPointSantri(e))
        setShowDeletePoint(false)
        setShowAlertPoint(true)
    }
    const bimbingan = (e: any) => {
        dispatch(addPembinaan(e))
        setShowFormAddBimbingan(false)
        setShowAlertPembinaan(true)
    }
    useEffect(() => {
        if (showAlertPoint) {
            const interval = setInterval(() => {
                setShowAlertPoint(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            if (Object.keys(tahun).length !== 0) {
                dispatch(allPointSantriByUuid({ uuid: params.uuid, thn: tahun?.value?.replace("/", "-") }))
            }
        }
    }, [showAlertPoint, params, tahun, loadingPembinaan])
    useEffect(() => {
        if (showAlertPembinaan) {
            const interval = setInterval(() => {
                setShowAlertPembinaan(false)
            }, 2000)
            return () => clearInterval(interval)
        }
    }, [showAlertPembinaan])
    useEffect(() => {
        if (Object.keys(tahunAjar).length !== 0) {
            tahunAjar.map((d: any) => {
                if (d.active) {
                    setTahun({ value: d.tahun, label: d.tahun })
                }
            })
        }
    }, [tahunAjar])
    useEffect(() => {
        setTotalPoint(0)
        if (Object.keys(pointSantriAll).length !== 0) {
            let total_: number = 0
            pointSantriAll.map((d: any) => {
                total_ += parseInt(d.list_point?.jumlahPoint)
            })
            setTotalPoint(total_)
        }
    }, [pointSantriAll])
    useEffect(() => {
        dispatch(pointSantriAction.clearPointSantri())
        dispatch(allThnAjar())
        dispatch(allListPoint())
        dispatch(oneSantri(params?.uuid))
    }, [params])
    useEffect(() => {
        if (succesPdfPoint) {
            const date = new Date()
            window.open(`https://upload-ppdb.ponpesabuhurairah.id/perizinan/PELANGGARAN_${santriOne?.santri?.nuwb}_${formatDatePdf(date)}.pdf`)
            dispatch(pointSantriStore.actions.clearPdf())
        }
    }, [succesPdfPoint])
    console.log(pointSantriAll)
    return (
        <div>
            <Alert show={showAlertPoint} msg={msgPointSantri} />
            <Alert show={showAlertPembinaan} msg={msgPembinaan} />
            <Loading show={loadingPointSantri} />
            <AddPembinaan showForm={showFormAddBimbingan} closeForm={setShowFormAddBimbingan} submit={bimbingan} data={saveOne} />
            <AddPointSantri showForm={showFormAddPoint} closeForm={setShowFormAddPoint} submit={tambah} thnOpt={tahunOpt} pointOpt={pointOpt} uuid={params?.uuid} />
            <EditPointSantri showForm={showFormEditPoint} closeForm={setShowFormEditPoint} submit={edit} data={saveOne} thnOpt={tahunOpt} pointOpt={pointOpt} />
            <DeletePointByUser showForm={showDeletePoint} closeForm={setShowDeletePoint} data={saveOne} submit={hapusProcessed} />
            <HeaderUi title="Point Santri" />
            <Table>
                <div className='flex justify-between w-full'>
                    <div className='w-72'>
                        <Selector title="Tahun Ajar" value={tahun} onChange={(e: any) => setTahun(e)} options={tahunOpt} />
                    </div>
                    <div className='mt-6 space-x-5'>
                        <Button type="button" title="Tambah" onClick={() => setShowFormAddPoint(true)} />
                        <Button type="button" title="Download PDF" onClick={() => {
                            dispatch(generatePdfSantri({ nuwb: santriOne?.santri?.nuwb, thn_ajar: tahun?.value }))
                            setShowAlertPoint(!showAlertPoint)
                        }} />
                    </div>
                </div>
            </Table>
            <Table>
                <div>
                    <div className='flex justify-between'>
                        {Object.keys(santriOne).length !== 0 &&
                            <div className='grid grid-cols-4 gap-4'>
                                <div>
                                    <div className='flex space-x-1 text-lg font-light pr-2'>
                                        <h1 className='font-normal'>Nama</h1>
                                        <h1>:</h1>
                                        <h1>{santriOne?.santri?.nama}</h1>
                                    </div>
                                    <div className='w-full h-[2px] bg-gray-400 mt-2'></div>
                                </div>
                                <div>
                                    <div className='flex space-x-1 text-lg font-light pr-3'>
                                        <h1 className='font-normal'>Lembaga</h1>
                                        <h1>:</h1>
                                        <h1>{santriOne?.santri?.lembaga?.nama}</h1>
                                    </div>
                                    <div className='w-full h-[2px] bg-gray-400 mt-2'></div>
                                </div>
                                <div>
                                    <div className='flex space-x-1 text-lg font-light pr-2'>
                                        <h1 className='font-normal'>Kelas</h1>
                                        <h1>:</h1>
                                        <h1>{santriOne?.santri?.kelas}</h1>
                                    </div>
                                    <div className='w-full h-[2px] bg-gray-400 mt-2'></div>
                                </div>
                                <div>
                                    <div className='flex space-x-1 text-lg font-light pr-2'>
                                        <h1 className='font-normal'>Ruang</h1>
                                        <h1>:</h1>
                                        <h1>{santriOne?.santri?.ruang}</h1>
                                    </div>
                                    <div className='w-full h-[2px] bg-gray-400 mt-2'></div>
                                </div>
                                <h1 className='mx-2 text-blue-700 text-lg'>Jumlah Point : {totalPoint}</h1>
                            </div>
                        }
                        {/* <div>
                            <Button title="Cetak" onClick={()=>navigate(`/home/point/cetak/${params?.uuid}/${tahun?.value?.replace("/","-")}`)}/>
                        </div> */}
                    </div>
                    {Object.keys(pointSantriAll).length === 0 ?
                        <EmptyTable title='Tidak ada point' />
                        :
                        <TableFields head={tableHead}>
                            {pointSantriAll.map((d: any, id: any) => (
                                <tr className={`bg-white border-b hover:bg-gray-100 overflow-y-auto ${d.canceledPoint === null ? "text-gray-900" : "text-red-700"}`} key={id}>
                                    <td className='px-6 py-3'>{id + 1}</td>
                                    <td className='px-6 py-3'>{d.tanggal_kejadian ? formatDayNoTime(d.tanggal_kejadian) : "-"}</td>
                                    <td className='px-6 py-3'>{d.list_point?.judul}</td>
                                    <td className='px-6 py-3'>{d.deskripsi ? d.deskripsi : "-"}</td>
                                    <td className='px-6 py-3'>{d.list_point?.jumlahPoint}</td>
                                    <td className='px-6 py-3'>{d.approve ? <RxCross2 className='w-6 h-6' /> : <HiOutlinePencilSquare className={`w-6 h-6  cursor-pointer`} onClick={() => {
                                        if (d.canceledPoint === null) {
                                            setSaveOne(d)
                                            setShowFormEditPoint(true)
                                        }
                                    }} />}</td>
                                    <td className='px-6 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                        if (d.canceledPoint === null) {
                                            setSaveOne(d)
                                            setShowDeletePoint(true)
                                        }
                                    }} /></td>
                                    <td className='px-6 py-3'>{d.pembinaan !== null ?
                                        <h1>Sudah melakukan pembinaan</h1>
                                        :
                                        <FaPeopleArrows className='w-6 h-6 mx-6  cursor-pointer' onClick={() => {
                                            if (d.canceledPoint === null) {
                                                setSaveOne(d)
                                                setShowFormAddBimbingan(true)
                                            }
                                        }} />
                                    }
                                    </td>
                                </tr>
                            ))}
                        </TableFields>
                    }
                </div>
            </Table>
        </div>
    )
}

export default PointSantri