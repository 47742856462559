import { createSlice } from "@reduxjs/toolkit";
import { allListPrestasi,allListPrestasiByKey,addListPrestasi,dropListPrestasi,editListPrestasi, allListPrestasiByUuid } from "../actions/listPrestasi";
import { ListPrestasi } from "../interfaces";

const initialState : ListPrestasi = {
    listPrestasiAll : [],
    listPrestasiOne : {},
    loadingPrestasi : false,
    msgPrestasi : ""
}

export const listPrestasiStore = createSlice({
    name:"prestasi",
    initialState,
    reducers : {
        clearPrestasi : (state)=>{
            state.listPrestasiAll = []
            state.msgPrestasi = ""
        },
        clearlitsPrestasiOne : (state)=>{
            state.listPrestasiOne = {}
            state.msgPrestasi = ""
        }
    },
    extraReducers : builder =>{
        builder
        .addCase(allListPrestasi.pending,(state)=>{
            state.loadingPrestasi = true
        })
        .addCase(allListPrestasi.fulfilled,(state,action)=>{
            state.loadingPrestasi = false
            state.listPrestasiAll = action.payload
        })
        .addCase(allListPrestasi.rejected,(state,action)=>{
            state.loadingPrestasi = false
            state.listPrestasiAll = []
            state.msgPrestasi = action.payload?.message
        })
        .addCase(allListPrestasiByUuid.pending,(state)=>{
            state.loadingPrestasi = true
        })
        .addCase(allListPrestasiByUuid.fulfilled,(state,action)=>{
            state.loadingPrestasi = false
            state.listPrestasiOne = action.payload
        })
        .addCase(allListPrestasiByUuid.rejected,(state,action)=>{
            state.loadingPrestasi = false
            state.listPrestasiOne = {}
            state.msgPrestasi = action.payload?.message
        })
        .addCase(allListPrestasiByKey.pending,(state)=>{
            state.loadingPrestasi = true
        })
        .addCase(allListPrestasiByKey.fulfilled,(state,action)=>{
            state.loadingPrestasi = false
            state.listPrestasiAll = action.payload
        })
        .addCase(allListPrestasiByKey.rejected,(state,action)=>{
            state.loadingPrestasi = false
            state.listPrestasiAll = []
            state.msgPrestasi = action.payload?.message
        })
        .addCase(addListPrestasi.pending,(state)=>{
            state.loadingPrestasi = true
        })
        .addCase(addListPrestasi.fulfilled,(state,action)=>{
            state.loadingPrestasi = false
            state.msgPrestasi = action.payload.msg
        })
        .addCase(addListPrestasi.rejected,(state,action)=>{
            state.loadingPrestasi = false
            state.msgPrestasi = action.payload?.message
        })
        .addCase(editListPrestasi.pending,(state)=>{
            state.loadingPrestasi = true
        })
        .addCase(editListPrestasi.fulfilled,(state,action)=>{
            state.loadingPrestasi = false
            state.msgPrestasi = action.payload.msg
        })
        .addCase(editListPrestasi.rejected,(state,action)=>{
            state.loadingPrestasi = false
            state.msgPrestasi = action.payload?.message
        })
        .addCase(dropListPrestasi.pending,(state)=>{
            state.loadingPrestasi = true
        })
        .addCase(dropListPrestasi.fulfilled,(state,action)=>{
            state.loadingPrestasi = false
            state.msgPrestasi = action.payload.msg
        })
        .addCase(dropListPrestasi.rejected,(state,action)=>{
            state.loadingPrestasi = false
            state.msgPrestasi = action.payload?.message
        })
    }
})
export const listPrestasiActions = listPrestasiStore.actions
export default listPrestasiStore.reducer