import { CreateSliceOptions, createSlice } from "@reduxjs/toolkit";
import { allListPerizinan,addListPerizinan,updateListPerizinan,dropListPerizinan } from "../actions/listIzin";
import { ListIzin } from "../interfaces";

const initialState : ListIzin = {
    listIzinAll : [],
    loadingListIzin : false,
    msgListIzin : ""
}

export const listIzinStore = createSlice({
    name:"listIzin",
    initialState,
    reducers : {
        clearListIzin  : (state)=>{
            state.listIzinAll = []
            state.msgListIzin = ""
        }
    },
    extraReducers : builer =>{
        builer
        .addCase(allListPerizinan.pending ,(state,action)=>{
            state.loadingListIzin = true
        })
        .addCase(allListPerizinan.fulfilled ,(state,action)=>{
            state.loadingListIzin = false
            state.listIzinAll = action.payload
        })
        .addCase(allListPerizinan.rejected ,(state,action)=>{
            state.loadingListIzin = false
            state.listIzinAll = []
            state.msgListIzin = action.payload?.message
        })
        .addCase(addListPerizinan.pending ,(state,action)=>{
            state.loadingListIzin = true
        })
        .addCase(addListPerizinan.fulfilled ,(state,action)=>{
            state.loadingListIzin = false
            state.msgListIzin = action.payload.msg
        })
        .addCase(addListPerizinan.rejected ,(state,action)=>{
            state.loadingListIzin = false
            state.msgListIzin = action.payload?.message
        })
        .addCase(updateListPerizinan.pending ,(state,action)=>{
            state.loadingListIzin = true
        })
        .addCase(updateListPerizinan.fulfilled ,(state,action)=>{
            state.loadingListIzin = false
            state.msgListIzin = action.payload.msg
        })
        .addCase(updateListPerizinan.rejected ,(state,action)=>{
            state.loadingListIzin = false
            state.msgListIzin = action.payload?.message
        })
        .addCase(dropListPerizinan.pending ,(state,action)=>{
            state.loadingListIzin = true
        })
        .addCase(dropListPerizinan.fulfilled ,(state,action)=>{
            state.loadingListIzin = false
            state.msgListIzin = action.payload.msg
        })
        .addCase(dropListPerizinan.rejected ,(state,action)=>{
            state.loadingListIzin = false
            state.msgListIzin = action.payload?.message
        })
    }
})

export const listIzinActions = listIzinStore.actions
export default listIzinStore.reducer