import { CanceledPointAttributes, DeletePointSantriAttributes, ListPointAttributes, PdfSantriAttributes, PdfLembagaAttributes, PointSantriAttributes } from "../type";
import api from "./http";

export const ApiPoint = {
    getList : () =>{
        return api.get(`/perizinan/list-point`)
    },
    getListByKey : (key:string) =>{
        return api.get(`/perizinan/list-point/${key}`)
    },
    getListByUuid : (uuid:string)=>{
        return api.get(`/perizinan/list-point/one/${uuid}`)
    },
    postList : (data:ListPointAttributes)=>{
        return api.post(`/perizinan/list-point`,data)
    },
    updateList : (uuid:string,data:ListPointAttributes)=>{
        return api.put(`/perizinan/list-point/${uuid}`,data)
    },
    deleteList : (uuid:string)=>{
        return api.delete(`/perizinan/list-point/${uuid}`)
    },
    getPointSantri : (approve:string) =>{
        return api.get(`/perizinan/point-santri/${approve}`)
    },
    getBySantriandThnAjr : (uuid:string,thn:string)=>{
        return api.get(`/perizinan/point-santri/${uuid}/${thn}`)
    },
    getByDate : (data:{start:string,end:string})=>{
        return api.post(`/perizinan/point-santri/report-point`,data)
    },
    postPointSantri : (data:PointSantriAttributes) =>{
        return api.post(`/perizinan/point-santri`,data)
    },
    updatePointSantri : (uuid:string,data:PointSantriAttributes) =>{
        return api.put(`/perizinan/point-santri/${uuid}`,data)
    },
    deletePointSantriByUser : (data:DeletePointSantriAttributes)=>{
        return api.post(`/perizinan/point-santri/delete`,data)
    },
    getAkumulasi : () =>{
        return api.get(`/perizinan/akumulasi-point`)
    },
    getAkumulasiByKey : (key:string,thn:string)=>{
        return api.get(`/perizinan/akumulasi-point/${key}/${thn}`)
    },
    postAkumulasi : (uuid:string,data:any) =>{
        return api.post(`/perizinan/akumulasi-point/${uuid}`,data)
    },
    deleteAkumulasi : (uuid:string)=>{
        return api.delete(`/perizinan/akumulasi-point/${uuid}`)
    },
    getCanceledPointSantri : () =>{
        return api.get(`/perizinan/point-santri/point/canceled`)
    },
    deleteCanceledPointSantri : (data:CanceledPointAttributes)=>{
        return api.post(`/perizinan/point-santri/approve`,data)
    },
    getPdfSantri : (data:PdfSantriAttributes)=>{
        return api.post(`/perizinan/point-santri/generate-pdf/santri`,data)
    },
    getPdfLembaga :(data:PdfLembagaAttributes)=>{
        return api.post(`/perizinan/point-santri/generate-pdf/lembaga`,data)
    }
}