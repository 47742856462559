import moment from "moment-timezone";
import "moment/locale/id"

export function formatNormalDate(date:Date):string{
    return moment(date).tz("Asia/Makassar").format("YYYY-MM-DD HH:mm:ss")
}

export function formatDay(date:Date):string{
    return moment(date).tz("Asia/Makassar").format("dddd, DD MMMM YYYY HH:mm:ss")
}

export function formatDayNoTime(date:Date):string{
    return moment(date).tz("Asia/Makassar").format("dddd, DD MMMM YYYY")
}


export function formatWithoutDay(date:Date):string{
    return moment(date).tz("Asia/Makassar").format("DD MMMM YYYY")
}

export function formatTimeOnly(date:Date|string):string{
    return moment(date).tz("Asia/Makassar").format("HH:mm:ss")
}

export function formatDatePdf(date:Date):string{
    return moment(date).tz("Asia/Makassar").format("YYYY-MM-DD")
}