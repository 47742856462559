import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { persistStore } from "redux-persist";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import thunk, { ThunkDispatch } from "redux-thunk";
import akumualasiPointStore from "./slice/akumulasiPoint";
import authStore from "./slice/auth";
import kamarSantriStore from "./slice/kamarSantri";
import listKamarStore from "./slice/listKamar";
import listPointStore from "./slice/listPoint";
import optionsStore from "./slice/options";
import pembinaanStore from "./slice/pembinaan";
import perizinanSantriStore from "./slice/perizinan";
import pointSantriStore from "./slice/PointSantri";
import santriStore from "./slice/santri";
import userStore from "./slice/user";
import listPrestasiStore from "./slice/listPrestasi";
import prestasiSantriStore from "./slice/prestasiSantri";
import listIzin from "./slice/listIzin";
import listPenjemput from "./slice/listPenjemput";
import dashboardStore from "./slice/dashboard";
import absensiStore from "./slice/absensi";
import PointAbsensiStore from "./slice/pointAbsensi";

export const store = configureStore({
    reducer: {
        auth: persistReducer({
            key: "perizinan",
            storage
        }, authStore),
        santri: santriStore,
        listKamar: listKamarStore,
        listPoint: listPointStore,
        listPrestasi: listPrestasiStore,
        listIzin: listIzin,
        listPenjemput: listPenjemput,
        kamarSantri: kamarSantriStore,
        pointSantri: pointSantriStore,
        prestasiSantri: prestasiSantriStore,
        akumulasiPoint: akumualasiPointStore,
        perizinanSantri: perizinanSantriStore,
        pembinaan: pembinaanStore,
        user: userStore,
        options: optionsStore,
        dashboard: dashboardStore,
        absensi: absensiStore,
        pointAbsensi: PointAbsensiStore

    },
    middleware: [thunk]
})
export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type TypeDispatch<T> = ThunkDispatch<T, any, AnyAction>
export const useAppDispatch = () => useDispatch<TypeDispatch<RootState>>()
// export const useAppDispatch = ()=> useDispatch<AppDispatch>()
