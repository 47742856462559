import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { HiEye, HiEyeSlash } from 'react-icons/hi2'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import InputForm from '../fields/InputForm'
import RadioForm from '../fields/RadioForm'
import Button from '../others/Button'
import { FormAttributes } from './interfaces'

function EditUser({closeForm,showForm,submit,data}:FormAttributes) {
    const [eye, setEye] = useState(false)
    const methodEditUser = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                nama: yup.string().required("Tidak Boleh Kosong"),
                username: yup.string().required("Tidak Boleh Kosong"),
                password: yup.string(),
                general_user: yup.string().oneOf(["true", "false"], "Pilih Salah Satu").required("Tidak Boleh Kosong")
            })
        )
    })
    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            methodEditUser.reset({nama:data?.nama,username:data?.username,password:"",general_user:data?.general_user?.toString()})
        }
    }, [data])
    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[90vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Formulir Edit User</h1>
                </div>
                <form onSubmit={methodEditUser.handleSubmit(submit)} className='px-6 py-7 space-y-1'>
                    <div className='md:grid md:grid-cols-1 md:gap-5'>
                        <InputForm
                            label="Nama"
                            method={methodEditUser}
                            methodName="nama"
                        />
                        <InputForm
                            label="username"
                            method={methodEditUser}
                            methodName="username"
                        />
                        <InputForm
                            type={eye ? "text" : "password"}
                            label="Password"
                            method={methodEditUser}
                            methodName="password"
                            icon={eye ? <HiEye className='w-6 h-6 -mr-3 cursor-pointer absolute right-5 mt-3' onClick={() => setEye(!eye)} /> : <HiEyeSlash className='w-6 h-6 my-auto -mr-3 cursor-pointer absolute right-5 mt-3' onClick={() => setEye(!eye)} />}
                        />
                        <div>
                            <h1 className='font-light text-lg mb-2'>General User</h1>
                            <div className='flex my-auto md:space-x-0 space-x-2'>
                                <RadioForm method={methodEditUser} methodName="general_user" value_="true" label="Iya" />
                                <RadioForm method={methodEditUser} methodName="general_user" value_="false" label="Tidak" />
                            </div>
                            {methodEditUser.formState.errors["general_user"] && (
                                <p className="text-red-600 mt-1 mx-1">
                                    {methodEditUser.formState.errors["general_user"].message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className='flex justify-end'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>)
}

export default EditUser