import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import InputForm from '../fields/InputForm'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import RadioForm from '../fields/RadioForm'
import Button from '../others/Button'
import SelectorForm from '../fields/SelectorForm'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { FormAttributes } from './interfaces'

function AddPerizinan({closeForm,showForm,submit,uuid}:FormAttributes) {
    const {listIzinAll }= useSelector((state:RootState)=>state.listIzin)
    const {listPenjemputAll} = useSelector((state:RootState)=>state.listPenjemput)
    const methdoAddPerizinan = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                dari: yup.string().required("tidak boleh kosong"),
                sampai: yup.string().required("tidak boleh kosong"),
                keterangan: yup.string().required("tidak boleh kosong"),
                status: yup.string().required("tidak boleh kosong"),
                uuid_santri: yup.string().required("tidak boleh kosong"),
                id_izin : yup.number().required("tidak boleh kosong"),
                uuid_penjemput : yup.string().required("tidak boleh kosong")
            })
        )
    })
    const izinOpt = listIzinAll.map((d:any)=>{
        return {value:d.id,label:d.nama}
    })
    const penjemputOpt = listPenjemputAll.map((d:any)=>{
        return {value:d.uuid,label:d.nama}
    })
    useEffect(()=>{
        if(showForm && uuid !== ""){
            methdoAddPerizinan.reset({uuid_santri:uuid,status:"false",dari:"",sampai:"",keterangan:"",uuid_penjemput:""})
        }
    },[showForm,uuid])
    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Perizinan</h1>
                </div>
                <form onSubmit={methdoAddPerizinan.handleSubmit(submit)} className='px-6 py-7'>
                    <div className='mb-4 space-y-1'>
                        <InputForm label="Dari" type="datetime-local" method={methdoAddPerizinan} methodName="dari" />
                        <InputForm label="Sampai" type="datetime-local" method={methdoAddPerizinan} methodName="sampai" />
                        <SelectorForm label="Izin" method={methdoAddPerizinan} methodName="id_izin" options={izinOpt} />
                        <InputForm label="Keterangan" method={methdoAddPerizinan} methodName="keterangan" />
                        <SelectorForm label="Penjemput" method={methdoAddPerizinan} methodName="uuid_penjemput" options={penjemputOpt} />
                    </div>
                    <div className='flex justify-end'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default AddPerizinan