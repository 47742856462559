import React, { Dispatch, SetStateAction } from 'react'
import Dialog from '../../templates/Dialog'

interface DialogDeleteAttributes{
    show:boolean;
    close:Dispatch<SetStateAction<boolean>>
    onClick:()=>void;
    msg:string;
}

function DialogDelete({show,close,msg,onClick}:DialogDeleteAttributes) {
    return (
        <Dialog show={show} toggle={close}>
            <div className='bg-white md:w-[60vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>{msg}</h1>
                </div>
                <div className='p-5 w-full '>
                    <button onClick={onClick} className='border border-sky-400 w-full  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300'>Iya</button>
                </div>
            </div>
        </Dialog>)
}

export default DialogDelete