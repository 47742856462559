import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiPerizinan } from "../../api/perizinan";
import { ErrorData } from "../interfaces";


export const allListPerizinan =  createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'list/perizinan/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiPerizinan.getListPerizinan()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const addListPerizinan =  createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'list/perizinan/post',
    async(data:any,{rejectWithValue})=>{
        try{
            const res = await ApiPerizinan.postListIzin(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const updateListPerizinan = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'list/perizinan/update',
    async(data:any,{rejectWithValue})=>{
        try{
            const res =await ApiPerizinan.putListIzin(data.id,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const dropListPerizinan = createAsyncThunk<any,string,{rejectValue:ErrorData}>(
    'list/perizinan/drop',
    async(id:string,{rejectWithValue})=>{
        try{
            const res = await ApiPerizinan.deleteListIzin(id)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)