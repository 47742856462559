import { createSlice } from "@reduxjs/toolkit";
import { getAllAbsensi, postAbsensi } from "../actions/absensi";
import { AbsensiAttributes } from "../interfaces";

const initialState:AbsensiAttributes = {
    allAbsensi:[],
    loadingAbsensi:false,
    messageAbsensi:""
}

export const AbsensiStore = createSlice({
    name:'absensi',
    initialState,
    reducers:{
        clearAbsensi:(state)=>{
            state.allAbsensi = []
            state.messageAbsensi = ""
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getAllAbsensi.pending,(state)=>{
            state.loadingAbsensi = true
        })
        .addCase(getAllAbsensi.fulfilled,(state,action)=>{
            state.loadingAbsensi = false
            state.allAbsensi = action.payload
            state.messageAbsensi = ""
        })
        .addCase(getAllAbsensi.rejected,(state,action)=>{
            state.loadingAbsensi = false
            state.allAbsensi = []
            state.messageAbsensi = action.payload
        })
        .addCase(postAbsensi.pending,(state)=>{
            state.loadingAbsensi = true
        })
        .addCase(postAbsensi.fulfilled,(state,action)=>{
            state.loadingAbsensi = false
            state.messageAbsensi = action.payload?.msg
        })
        .addCase(postAbsensi.rejected,(state,action)=>{
            state.loadingAbsensi = false
            state.messageAbsensi = action.payload
        })
    }
})

export const AbsensiActions = AbsensiStore.actions;
export default AbsensiStore.reducer;

