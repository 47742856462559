import { createSlice } from "@reduxjs/toolkit";
import { allKamarSantri,addkamarSantri,editKamarSantri,dropKamarSantri, allKamarSantriByUuid } from "../actions/kamarSantri";
import { KamarSantri } from "../interfaces";

const initialState : KamarSantri = {
    kamarSantriAll : [],
    kamarSantriOne : {},
    loadingKamarSantri : false,
    msgKamarSantri : ""
}
export const kamarSantriStore = createSlice({
    name : "kamarSantri",
    initialState,
    reducers : {
        clearKamarSantri : (state)=>{
            state.kamarSantriAll = []
            state.msgKamarSantri = ""
        },
        clearOneKamarSantri : (state)=>{
            state.kamarSantriOne = {}
            state.msgKamarSantri = ""
        }
    },
    extraReducers : builder =>{
        builder
        .addCase(allKamarSantri.pending,(state)=>{
            state.loadingKamarSantri = true
        })
        .addCase(allKamarSantri.fulfilled,(state,action)=>{
            state.loadingKamarSantri = false
            state.kamarSantriAll =  action.payload
        })
        .addCase(allKamarSantri.rejected,(state,action)=>{
            state.loadingKamarSantri = false
            state.kamarSantriAll =  []
            state.msgKamarSantri = action.payload?.message
        })
        .addCase(allKamarSantriByUuid.pending,(state)=>{
            state.loadingKamarSantri = true
        })
        .addCase(allKamarSantriByUuid.fulfilled,(state,action)=>{
            state.loadingKamarSantri = false
            state.kamarSantriAll =  action.payload
        })
        .addCase(allKamarSantriByUuid.rejected,(state,action)=>{
            state.loadingKamarSantri = false
            state.kamarSantriAll =  []
            state.msgKamarSantri = action.payload?.message
        })
        .addCase(addkamarSantri.pending,(state)=>{
            state.loadingKamarSantri = true
        })
        .addCase(addkamarSantri.fulfilled,(state,action)=>{
            state.loadingKamarSantri = false
            state.msgKamarSantri =  action.payload.msg
        })
        .addCase(addkamarSantri.rejected,(state,action)=>{
            state.loadingKamarSantri = false
            state.msgKamarSantri = action.payload?.message
        })
        .addCase(editKamarSantri.pending,(state)=>{
            state.loadingKamarSantri = true
        })
        .addCase(editKamarSantri.fulfilled,(state,action)=>{
            state.loadingKamarSantri = false
            state.msgKamarSantri =  action.payload.msg
        })
        .addCase(editKamarSantri.rejected,(state,action)=>{
            state.loadingKamarSantri = false
            state.msgKamarSantri = action.payload?.message
        })
        .addCase(dropKamarSantri.pending,(state)=>{
            state.loadingKamarSantri = true
        })
        .addCase(dropKamarSantri.fulfilled,(state,action)=>{
            state.loadingKamarSantri = false
            state.msgKamarSantri =  action.payload.msg
        })
        .addCase(dropKamarSantri.rejected,(state,action)=>{
            state.loadingKamarSantri = false
            state.msgKamarSantri = action.payload?.message
        })
    }
})
export const kamarSantriAction = kamarSantriStore.actions
export default kamarSantriStore.reducer