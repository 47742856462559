import React from 'react'
import {Controller} from "react-hook-form"
import Select from 'react-select';

interface SelectorFormAttributes{
    methodName:string;
    label:string;
    method:any;
    placeholder?:string;
    options:Array<{label:string|number,value:string|number}>
    value_?:{label:string|number,value:string|number}
    onChange_?:(e:any)=>void;
    defaultValue?:any;
    isMulti?:boolean;
    onBlur?:boolean;
    disabled?:boolean;
    className?:string;
}

function SelectorForm({label,method,methodName,onChange_,options,value_,defaultValue,disabled,isMulti,onBlur,placeholder,className}:SelectorFormAttributes) {
    const { error } = method.getFieldState(methodName)
    return (
        <div className={`w-full space-y-1 ${className}`}>
            <label className="md:mx-0 text-md font-light text-gray-600">{label}</label>
            <Controller
                control={method.control}
                name={methodName}
                defaultValue={defaultValue}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                }) => {
                    return <Select
                        className={className}
                        styles={{
                            control: (baseStyles, state:any) => ({
                                ...baseStyles,
                                borderRadius: "0px",
                                paddingTop: "1px",
                                paddingBottom: "1px",
                                borderColor: state.isTouched ? "#624d4d" : "#624d4d",
                                borderWidth: "2px"
                            }),
                        }}
                        classNamePrefix="select"
                        placeholder={placeholder}
                        options={options}
                        isMulti={isMulti}
                        onBlur={onBlur}
                        onChange={(e:any) => {
                            if (isMulti) {
                                onChange(e.map((d:any) =>
                                    d.value))
                                if (onChange_) {
                                    onChange(e);
                                }
                            }
                            else {
                                onChange(e.value);
                                if (onChange_) {
                                    onChange(e);
                                }
                            }
                        }}
                        isDisabled={disabled}
                        ref={ref}
                        value={onChange_ ? value_ : options?.find((o:any) => o.value === value) || {}}
                    />
                }}
            />
            {error && <p className='text-red-600 mt-1 mx-1'>{error.message}</p>}
        </div>
    );
}

export default SelectorForm