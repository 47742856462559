import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiKamar } from "../../api/kamar";
import { ErrorData } from "../interfaces";


export const allKamarSantri =  createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'kamar/santri/get',
    async(_,{rejectWithValue})=>{
        try{
            const res : any = await ApiKamar.getKamarSantri()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const allKamarSantriByUuid = createAsyncThunk<[],any,{rejectValue:ErrorData}>(
    'kamar/santri/get/uuid',
    async(key:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiKamar.getByUuidKamar(key.uuid,key.gender)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const addkamarSantri = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'kamar/santri/post',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiKamar.postKamarSantri(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const editKamarSantri = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'kamar/santri/update',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiKamar.updatekamarSantri(data.uuid,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const dropKamarSantri = createAsyncThunk<any,string,{rejectValue:ErrorData}>(
    'kamar/santri/delete',
    async(uuid:string,{rejectWithValue})=>{
        try{
            const res : any =  await ApiKamar.deletekamarSantri(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){ 
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)