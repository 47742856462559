import React, { useEffect, useState } from 'react'
import { HiOutlinePencilSquare, HiOutlineTrash } from 'react-icons/hi2'
import { FaFilter } from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux'
import Selector from '../component/fields/Selector'
import AddListKamar from '../component/form/AddListKamar'
import EditListKamar from '../component/form/EditListKamar'
import Button from '../component/others/Button'
import { addListKamar, allListKamar, allListKamarByGender, dropListKamar, editListKamar } from '../store/actions/listKamar'
import { listKamarAction } from '../store/slice/listKamar'
import Alert from '../templates/Alert'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import DialogDelete from '../component/others/DialogDelete'
import Loading from '../templates/Loading'
import { genderOpt } from '../component/Gender'
import { RootState, TypeDispatch, useAppDispatch} from '../store'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'

function ListKamar() {
    const dispatch : TypeDispatch<any> = useAppDispatch()
    const { listKamarAll, msgListKamar,loadingListKamar } = useSelector((state: RootState) => state.listKamar)
    const {role,super_admin,generalUser} = useSelector((state: RootState)=>state.auth)
    const [saveOne, setSaveOne] = useState<any>({})
    const [gender, setGender] = useState<any>({ value: "_", label: "semua" })
    const [showFormAddKamar, setShowFormAddKamar] = useState<boolean>(false)
    const [showFormEditKamar, setShowFormEditKamar] = useState<boolean>(false)
    const [showDeleteKamar,setShowDeleteKamar] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [tableHead,setTableHead] = useState(["No","Kamar","Jumlah Kasur","Asrama","Edit","Delete"])
    const tambah = (e: any) => {
        console.log(e)
        dispatch(addListKamar(e))
        setShowFormAddKamar(false)
        setShowAlert(true)
    }
    const edit = (e: any) => {
        dispatch(editListKamar({ uuid: saveOne.uuid, update: e }))
        setShowFormEditKamar(false)
        setShowAlert(true)
    }
    const hapus = () =>{
        dispatch(dropListKamar(saveOne.uuid))
        setShowDeleteKamar(false)
        setShowAlert(true)
    }
    useEffect(() => {
        if (showAlert) {
            const interval = setInterval(() => {
                setShowAlert(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            dispatch(listKamarAction.clearList())
            dispatch(allListKamarByGender(gender.value))
        }
    }, [showAlert, gender])
    return (
        <div>
            <Alert msg={msgListKamar} show={showAlert} />
            <Loading show={loadingListKamar}/>
            <AddListKamar showForm={showFormAddKamar} closeForm={setShowFormAddKamar} submit={tambah} />
            <EditListKamar showForm={showFormEditKamar} closeForm={setShowFormEditKamar} submit={edit} data={saveOne} />
            <DialogDelete msg="Anda yakin ingin menghapus?" show={showDeleteKamar} close={setShowDeleteKamar} onClick={hapus}/>
            <HeaderUi title="List Kamar" />
            <Table>
                <div className='flex justify-between'>
                    <div className={`w-72 ${generalUser ? "block" : "hidden"}`}>
                        <Selector title="saring" value={gender} onChange={(e: any) => setGender(e)} options={genderOpt} />
                    </div>
                    <div className='flex items-end'>
                        <Button type="button" title="Tambah" onClick={() => setShowFormAddKamar(true)} />
                    </div>

                </div>
            </Table>
            <Table>
                {Object.keys(listKamarAll).length === 0 ?
                <EmptyTable title="Tidak ada list kamar"/>
                    :
                    <TableFields head={tableHead}>
                                {listKamarAll.map((d: any, id: any) => (
                                    <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                        <td className='px-6 py-3'>{id + 1}</td>
                                        <td className='px-10 py-3'>{d.namaKamar}</td>
                                        <td className='px-16 py-3'>{d.jumlahKasur}</td>
                                        <td className='px-6 py-3'>{d.milikAsrama === "L" ? "Putra" : "Putri"}</td>
                                        <td className='px-6 py-3'><HiOutlinePencilSquare className='w-6 h-6  cursor-pointer' onClick={() => {
                                            setSaveOne(d)
                                            setShowFormEditKamar(true)
                                        }} /></td>
                                        <td className='px-6 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-3' onClick={()=>{
                                            setSaveOne(d)
                                            setShowDeleteKamar(true)
                                        }} /></td>
                                    </tr>
                                ))}

                    </TableFields>
                }
            </Table>
        </div>
    )
}

export default ListKamar