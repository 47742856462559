import { createSlice } from "@reduxjs/toolkit";
import { allLembaga, allListLembaga, allRole, allSistem, allThnAjar } from "../actions/options";
import { Options } from "../interfaces";

const initialState : Options = {
    tahunAjar : [],
    lembagaAll : [],
    listLembagaAll : [],
    sistemAll : [],
    roleAll : [],
    loadingOptions : false,
    msgOptions : ""
}

export const optionsStore = createSlice({
    name:"options",
    initialState,
    reducers:{
        clearThn : (state)=>{
            state.tahunAjar = []
            state.msgOptions = ""
        },
        clearLembaga : (state)=>{
            state.lembagaAll = []
            state.msgOptions = ""
        },
        clearSistem : (state)=>{
            state.sistemAll = []
            state.msgOptions = ""
        },
        clearRole : (state)=>{
            state.roleAll = []
            state.msgOptions = ""
        },
        clearListLembaga : (state)=>{
            state.listLembagaAll = []
            state.msgOptions = ""
        }
    },extraReducers: builder =>{
        builder
        .addCase(allThnAjar.pending,(state)=>{
            state.loadingOptions = true
        })
        .addCase(allThnAjar.fulfilled,(state,action)=>{
            state.loadingOptions = false
            state.tahunAjar = action.payload
        })
        .addCase(allThnAjar.rejected,(state,action)=>{
            state.loadingOptions = false
            state.tahunAjar = []
            state.msgOptions = action.payload
        })
        .addCase(allLembaga.pending,(state)=>{
            state.loadingOptions = true
        })
        .addCase(allLembaga.fulfilled,(state,action)=>{
            state.loadingOptions = false
            state.lembagaAll = action.payload
        })
        .addCase(allLembaga.rejected,(state,action)=>{
            state.loadingOptions = false
            state.lembagaAll = []
            state.msgOptions = action.payload
        })
        .addCase(allSistem.pending,(state)=>{
            state.loadingOptions = true
        })
        .addCase(allSistem.fulfilled,(state,action)=>{
            state.loadingOptions = false
            state.sistemAll = action.payload
        })
        .addCase(allSistem.rejected,(state,action)=>{
            state.loadingOptions = false
            state.sistemAll = []
            state.msgOptions = action.payload
        })
        .addCase(allRole.pending,(state)=>{
            state.loadingOptions = true
        })
        .addCase(allRole.fulfilled,(state,action)=>{
            state.loadingOptions = false
            state.roleAll  = action.payload
        })
        .addCase(allRole.rejected,(state,action)=>{
            state.loadingOptions = false
            state.roleAll  = []
            state.msgOptions = action.payload
        })
        .addCase(allListLembaga.pending,(state)=>{
            state.loadingOptions = true
        })
        .addCase(allListLembaga.fulfilled,(state,action)=>{
            state.loadingOptions = false
            state.listLembagaAll = action.payload
        })
        .addCase(allListLembaga.rejected,(state,action)=>{
            state.loadingOptions = false
            state.listLembagaAll = []
            state.msgOptions = action.payload
        })
    }
})
export const optionsActions = optionsStore.actions
export default optionsStore.reducer