import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiPoint } from "../../api/point";
import { ErrorData } from "../interfaces";

export const allAkumulasipoint = createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'point/akumulasi/get',
    async(_,{rejectWithValue})=>{
        try{
            const res : any = await ApiPoint.getAkumulasi()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const allAkumulasipointByKey = createAsyncThunk<[],any,{rejectValue:ErrorData}>(
    'point/akumulasi/get/key',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiPoint.getAkumulasiByKey(data.key,data.thn)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const addAkumulasiPoint = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'point/akumualasi/post',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiPoint.postAkumulasi(data.uuid,data.post)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const dropAkumulasi = createAsyncThunk<any,string,{rejectValue:ErrorData}>(
    'point/akumulasi/delete',
    async(uuid:string,{rejectWithValue})=>{
        try{
            const res : any = await ApiPoint.deleteAkumulasi(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)