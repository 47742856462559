import api from "./http";

export const ApiPointAbsensi ={
    getAll:()=>{
        return api.get(`/perizinan/point-absensi`)
    },
    post:(data:{uuid_point:string})=>{
        return api.post(`/perizinan/point-absensi`,data)
    },
    delete:(uuid:string)=>{
        return api.delete(`/perizinan/point-absensi/${uuid}`)
    }
}

