import React from 'react'
import { IoPeopleCircleOutline } from 'react-icons/io5'

type CardDashboard = {
    id? : number,
    title : string,
    value : string | number,
    onClick? : ()=> void
}

function CardDashboard({id,title,value,onClick}:CardDashboard) {
    return (
        <div key={id} className=' gap-4 w-[100%] md:w-[100%] rounded-lg shadow-xl flex transition-colors ease-in-out duration-300 text-white bg-[#1e81b0] p-4 cursor-pointer' onClick={onClick}>
            <div className='my-auto flex flex-col justify-center w-full'>
                <h1 className='font-extrabold text-xl '>{title}</h1>
                <h1 className='font-semibold text-xl text-center w-full my-6 '>{value} Santri</h1>
            </div>
        </div>
    )
}

export default CardDashboard