import { createSlice } from "@reduxjs/toolkit";
import { allListPoint,addListPoint,editListPoint,dropListPoint, allListPointByKey, allListPointByUuid } from "../actions/listPoint";
import { ListPoint } from "../interfaces";

const initialState : ListPoint = {
    listPointAll : [],
    listPointOne : {},
    loadingListPoint : false,
    msgListPoint : ""
}

export const listPointStore = createSlice({
    name:"listPoint",
    initialState,
    reducers:{
        clearListPoint : (state)=>{
            state.listPointAll = []
            state.msgListPoint = ""
        },
        clearOneListPoint : (state)=>{
            state.listPointOne = {}
            state.msgListPoint = ""
        }
    },
    extraReducers: builder =>{
        builder
        .addCase(allListPoint.pending,(state)=>{
            state.loadingListPoint = true
        })
        .addCase(allListPoint.fulfilled,(state,action)=>{
            state.loadingListPoint = false
            state.listPointAll = action.payload
        })
        .addCase(allListPoint.rejected,(state,action)=>{
            state.loadingListPoint = false
            state.listPointAll = []
            state.msgListPoint = action.payload?.message
        })
        .addCase(allListPointByUuid.pending,(state)=>{
            state.loadingListPoint = true
        })
        .addCase(allListPointByUuid.fulfilled,(state,action)=>{
            state.loadingListPoint = false
            state.listPointOne = action.payload
        })
        .addCase(allListPointByUuid.rejected,(state,action)=>{
            state.loadingListPoint = false
            state.listPointOne = []
            state.msgListPoint = action.payload?.message
        })
        .addCase(allListPointByKey.pending,(state)=>{
            state.loadingListPoint = true
        })
        .addCase(allListPointByKey.fulfilled,(state,action)=>{
            state.loadingListPoint = false
            state.listPointAll = action.payload
        })
        .addCase(allListPointByKey.rejected,(state,action)=>{
            state.loadingListPoint = false
            state.listPointAll = []
            state.msgListPoint = action.payload?.message
        })
        .addCase(addListPoint.pending,(state)=>{
            state.loadingListPoint = true
        })
        .addCase(addListPoint.fulfilled,(state,action)=>{
            state.loadingListPoint = false
            state.msgListPoint = action.payload.msg
        })
        .addCase(addListPoint.rejected,(state,action)=>{
            state.loadingListPoint = false
            state.msgListPoint = action.payload?.message
        })
        .addCase(editListPoint.pending,(state)=>{
            state.loadingListPoint = true
        })
        .addCase(editListPoint.fulfilled,(state,action)=>{
            state.loadingListPoint = false
            state.msgListPoint = action.payload.msg
        })
        .addCase(editListPoint.rejected,(state,action)=>{
            state.loadingListPoint = false
            state.msgListPoint = action.payload?.message
        })
        .addCase(dropListPoint.pending,(state)=>{
            state.loadingListPoint = true
        })
        .addCase(dropListPoint.fulfilled,(state,action)=>{
            state.loadingListPoint = false
            state.msgListPoint = action.payload.msg
        })
        .addCase(dropListPoint.rejected,(state,action)=>{
            state.loadingListPoint = false
            state.msgListPoint = action.payload?.message
        })
    }
})
export const listPointAction = listPointStore.actions
export default listPointStore.reducer