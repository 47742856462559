import React, { useEffect, useState } from 'react'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import Input from '../component/fields/Input'
import Button from '../component/others/Button'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import TableFields from '../templates/TableFields'
import { formatDayNoTime } from '../helper/formatTime'
import EmptyTable from '../templates/EmptyTable'
import { allPointSantriByDate } from '../store/actions/pointSantri'
import Loading from '../templates/Loading'
import { prestasiSantriActions } from '../store/slice/prestasiSantri'
import { pointSantriAction } from '../store/slice/PointSantri'
import { allPrestasiSantriByDate } from '../store/actions/prestasiSantri'

export default function ReportPoint() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { pointSantriAll, loadingPointSantri, msgPointSantri } = useSelector((state: RootState) => state.pointSantri)
    const { prestasiSantriAll, loadingPrestasiSantri, msgPrestasiSantri } = useSelector((state: RootState) => state.prestasiSantri)
    const [getDari, setGetDari] = useState('')
    const [getSampai, setGetSampai] = useState('')
    const head = ["No", "Tanggal", "Nuwb", "Nama Santri", "Lembaga", "Kamar", "Kelas", "Ruang", "Point", "Deskripsi", "Jumlah Point"]
    const filterPelanggaran = () => {
        dispatch(prestasiSantriActions.clearPrestasiSantri())
        dispatch(allPointSantriByDate({ start: getDari, end: getSampai }))
    }
    const filterPrestasi = () => {
        dispatch(pointSantriAction.clearPointSantri())
        dispatch(allPrestasiSantriByDate({ start: getDari, end: getSampai }))
    }
    useEffect(() => {
        dispatch(prestasiSantriActions.clearPrestasiSantri())
        dispatch(pointSantriAction.clearPointSantri())
    }, [dispatch])
    return (
        <div>
            <HeaderUi title='Report Point' />
            <Loading show={loadingPointSantri || loadingPrestasiSantri} />
            <Table>
                <div className='flex justify-between'>
                    <div className='flex flex-wrap gap-4'>
                        <div>
                            <Input type='date' label='Dari' value={getDari} onChange={(e) => setGetDari(e.target.value)} />
                        </div>
                        <div>
                            <Input type='date' label='Sampai' value={getSampai} onChange={(e) => setGetSampai(e.target.value)} />
                        </div>
                    </div>
                    <div className='flex justify-end items-end gap-4'>
                        <Button title='Filter Prestasi' onClick={filterPrestasi} />
                        <Button title='Filter Pelanggaran' onClick={filterPelanggaran} />
                    </div>
                </div>
                {pointSantriAll.length > 0 ?
                    <TableFields head={head}>
                        {pointSantriAll.map((d: any, id: number) => (
                            <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                <td className='px-6 py-3'>{id + 1}</td>
                                <td className='px-6 py-3'>{d.tanggal_kejadian ? formatDayNoTime(d.tanggal_kejadian) : '-'}</td>
                                <td className='px-6 py-3'>{d.santri?.nuwb}</td>
                                <td className='px-6 py-3'>{d.santri?.nama}</td>
                                <td className='px-6 py-3'>{d.santri?.lembaga?.nama}</td>
                                <td className='px-6 py-3'>{d.santri?.kamar_santri?.list_kamar?.namaKamar}</td>
                                <td className='px-6 py-3'>{d.santri?.kelas}</td>
                                <td className='px-6 py-3'>{d.santri?.ruang}</td>
                                <td className='px-6 py-3'>{d.list_point?.judul}</td>
                                <td className='px-6 py-3 uppercase'>{d.deskripsi}</td>
                                <td className='px-6 py-3'>{d.list_point?.jumlahPoint}</td>
                            </tr>
                        ))}
                    </TableFields>
                    :
                    prestasiSantriAll.length > 0 ?
                        <TableFields head={head}>
                            {prestasiSantriAll.map((d: any, id: number) => (
                                <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                    <td className='px-6 py-3'>{id + 1}</td>
                                    <td className='px-6 py-3'>{d.tanggal_kejadian ? formatDayNoTime(d.tanggal_kejadian) : '-'}</td>
                                    <td className='px-6 py-3'>{d.santri?.nuwb}</td>
                                    <td className='px-6 py-3'>{d.santri?.nama}</td>
                                    <td className='px-6 py-3'>{d.santri?.lembaga?.nama}</td>
                                    <td className='px-6 py-3'>{d.santri?.kamar_santri?.list_kamar?.namaKamar}</td>
                                    <td className='px-6 py-3'>{d.santri?.kelas}</td>
                                    <td className='px-6 py-3'>{d.santri?.ruang}</td>
                                    <td className='px-6 py-3'>{d.prestasi?.judul}</td>
                                    <td className='px-6 py-3 uppercase'>{d.deskripsi}</td>
                                    <td className='px-6 py-3'>{d.prestasi?.jumlahPoint}</td>
                                </tr>
                            ))}
                        </TableFields>
                        :
                        <EmptyTable title='Tidak ada laporan yang tersedia' />
                }
            </Table>
        </div>
    )
}
