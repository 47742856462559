import { ListIzinAttributes, PerizinanAtrributes } from "../type";
import api from "./http";

export const ApiPerizinan = {
    getListPerizinan: () => {
        return api.get(`/perizinan/list-izin`)
    },
    postListIzin: (data: ListIzinAttributes) => {
        return api.post(`/perizinan/list-izin`, data)
    },
    putListIzin: (uuid: string, data: ListIzinAttributes) => {
        return api.put(`/perizinan/list-izin/${uuid}`, data)
    },
    deleteListIzin: (uuid: string) => {
        return api.delete(`/perizinan/list-izin/${uuid}`)
    },
    getPerizinan: () => {
        return api.get(`/perizinan/perizinan-santri`)
    },
    getPerizinanByKey: (key: string) => {
        return api.get(`/perizinan/perizinan-santri/search/${key}`)
    },
    getPerizinanBySantri: (uuid: string) => {
        return api.get(`/perizinan/perizinan-santri/${uuid}`)
    },
    postPerizinan: (data: PerizinanAtrributes) => {
        return api.post(`/perizinan/perizinan-santri`, data)
    },
    updatePerizinan: (uuid: string, data: PerizinanAtrributes) => {
        return api.put(`/perizinan/perizinan-santri/${uuid}`, data)
    },
    deletePerizinan: (uuid: string) => {
        return api.delete(`/perizinan/perizinan-santri/${uuid}`)
    },
    changeStatus: (uuid: string) => {
        return api.get(`/perizinan/perizinan-santri/balik/${uuid}`)
    },
    getKartu: (uuid: string) => {
        return api.get(`/perizinan/perizinan-santri/kartu/${uuid}`)
    }
}