import React from 'react'

type EmptyTable = {
    title: string
}

function EmptyTable({ title }: EmptyTable) {
    return (
        <div className='flex justify-center p-4'>
            <h1 className='text-2xl'>{title}</h1>
        </div>
    )
}

export default EmptyTable