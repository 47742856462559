import { KamarSantriAttributes, ListKamarAttributes } from "../type";
import api from "./http";

export const ApiKamar = {
    getList : ()=>{
        return api.get(`perizinan/list-kamar`)
    },
    getListByGender : (key:string) =>{
        return api.get(`/perizinan/list-kamar/${key}`)
    },
    postList : (data:ListKamarAttributes) =>{
        return api.post(`/perizinan/list-kamar`,data)
    },
    updateList : (uuid:string,data:ListKamarAttributes) =>{
        return api.put(`/perizinan/list-kamar/${uuid}`,data)
    },
    deleteList : (uuid:string)=>{
        return api.delete(`/perizinan/list-kamar/${uuid}`)
    },
    getKamarSantri : () =>{
        return api.get(`/perizinan/kamar-santri`)
    },
    getByUuidKamar : (uuid:string,gender:string,)=>{
        return api.get(`/perizinan/kamar-santri/${uuid}/${gender}`)
    },
    postKamarSantri : (data:KamarSantriAttributes) =>{
        return api.post(`/perizinan/kamar-santri`,data)
    },
    updatekamarSantri : (uuid:string,data:KamarSantriAttributes)=>{
        return api.put(`/perizinan/kamar-santri/${uuid}`,data)
    },
    deletekamarSantri : (uuid:string)=>{
        return api.delete(`/perizinan/kamar-santri/${uuid}`)
    }
}