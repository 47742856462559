import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import { getCetakKartu } from '../store/actions/perizinan'
import { perizinanSantriActions } from '../store/slice/perizinan'
import ReactToPrint from 'react-to-print'
import { formatDay, formatDayNoTime, formatTimeOnly } from '../helper/formatTime'


function CetakPerizinan() {
    let componentRef = useRef<HTMLDivElement>(null);
    const { uuid } = useParams()
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { perizinanOne, loadingPerizinan } = useSelector((state: RootState) => state.perizinanSantri)
    const { nama } = useSelector((state: RootState) => state.auth)
    useEffect(() => {
        dispatch(perizinanSantriActions.clearOnePerizinanSantri())
        if (uuid !== "") {
            dispatch(getCetakKartu(uuid))
        }
    }, [uuid])
    return (
        <div>
            <ReactToPrint
                trigger={() => <button className='border border-blue-600 px-8 py-2 m-2 hover:bg-white transition-colors ease-in-out duration-300'>Print</button>}
                content={() => componentRef.current}
            />
            {Object.keys(perizinanOne).length !== 0 ?
                <div ref={componentRef} className="w-[10.16cm] h-[20.32cm] bg-white mx-auto p-3 relative">
                    <div>
                        <h1 className='text-center text-lg mt-2'>PONPES ABU HURAIRAH MATARAM</h1>
                        <h1 className='text-center text-md '>KARTU PERIZINAN</h1>
                    </div>
                    <div className='w-full h-[1px] bg-black mt-5'></div>
                    <div className='mt-3'>
                        <div className='flex'>
                            <h1 className='mr-2'>Tanggal Izin</h1>
                            <h1 className='mr-2'>: </h1>
                            <h1>{formatDay(perizinanOne.dari)}</h1>
                        </div>
                        <div className='flex'>
                            <h1 className='mr-[46px]'>NUWB</h1>
                            <h1 className='mr-2'>: </h1>
                            <h1>{perizinanOne?.santri?.nuwb}</h1>
                        </div>
                        <div className='flex'>
                            <h1 className='mr-[52px]'>Nama</h1>
                            <h1 className='mr-2'>: </h1>
                            <h1>{perizinanOne?.santri?.nama}</h1>
                        </div>
                        <div className='flex'>
                            <h1 className='mr-[18px]'>Alasan Izin</h1>
                            <h1 className='mr-2'>: </h1>
                            <h1>{perizinanOne?.listIzin?.nama}</h1>
                        </div>
                    </div>
                    <div className='flex justify-center mt-5 mx-[2%] flex-col'>
                        <h1 className='font-extrabold text-center'>Batas Izin</h1>
                        <div className='border-2 w-[100%] flex-col flex p-[3px] space-y-1 border-black'>
                            <div className='flex gap-1'>
                                <div className='w-[75%] border text-center border-black'>Hari/Tanggal</div>
                                <div className='w-[25%] border text-center border-black'>Pukul</div>
                            </div>
                            <div className='flex gap-1'>
                                <div className='w-[75%] border text-center border-black'>{formatDayNoTime(perizinanOne?.sampai)}</div>
                                <div className='w-[25%] border text-center border-black'>{formatTimeOnly(perizinanOne?.sampai)}</div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-between mx-[5%] h-[15%] mt-5'>
                        <div className='flex flex-col justify-between text-center w-[45%] font-extrabold h-[100%]'>
                            <h1>Konfirmasi</h1>
                            <h1>{perizinanOne?.santri?.gender === "L" ? "087778209414" : "085338576797"}</h1>
                        </div>
                        <div className='flex flex-col justify-between text-center w-[45%] font-extrabold h-[100%]'>
                            <div>
                                <h1>Mengetahui,</h1>
                                <h1>Pemberi Izin</h1>
                            </div>
                            <h1>{nama}</h1>
                        </div>

                    </div>
                    <div className='w-full h-[1px] bg-black mt-5'></div>
                    <div>
                        <h1 className='font-extrabold text-sm'>Catatan:</h1>
                        <ul className='list-decimal text-sm mx-[5%] text-justify space-y-2 mt-2'>
                            <li><b>Wajib lapor</b> ketika kembali ke ponpes dengan <b>membawa kartu perizinan ini</b> ke bagian perizinan.</li>
                            <li>Santri yang <b>terlambat dan tidak konfirmasi akan diberikan</b> sanksi sesuai dengan peraturan yang berlaku</li>
                        </ul>
                    </div>
                </div>
                :
                <></>
            }
        </div>
    )
}

export default CetakPerizinan