import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import Button from '../others/Button'
import RadioForm from '../fields/RadioForm'
import Input from '../fields/Input'
import { FormAttributes } from './interfaces'

function AddAkumulasi({closeForm,showForm,submit,data}:FormAttributes) {
    const methodAddAkumulasi = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid_santri: yup.string().required("tidak boleh kosong"),
                thn_ajar: yup.string().required("tidak boleh kosong"),
                akumulasi: yup.string().oneOf(["true", "false"], "pilih salah satu").required("tidak boleh kosong"),
                status: yup.string().oneOf(["true", "false"], "pilih salah satu").required("tidak boleh kosong"),
            })
        )
    })
    useEffect(()=>{
        if(showForm && Object.keys(data).length !== 0){
            methodAddAkumulasi.reset({uuid_santri:data?.santri?.uuid,thn_ajar:data.thn_ajar,akumulasi:data?.akumulasi.toString(),status:data?.status.toString()})
        }
    },[showForm,data])
    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Akumulasi</h1>
                </div>
                <form onSubmit={methodAddAkumulasi.handleSubmit(submit)} className='px-6 py-7 space-y-1'>
                    <div className={``}>
                        <h1 className='font-light text-lg mb-2'>Akumulasikan</h1>
                        <div className='flex my-auto md:space-x-0 space-x-2'>
                            <RadioForm method={methodAddAkumulasi} methodName="akumulasi" value_="true" label="Iya" />
                            <RadioForm method={methodAddAkumulasi} methodName="akumulasi" value_="false" label="Tidak" />
                        </div>
                        {methodAddAkumulasi.formState.errors["akumulasi"] && (
                            <p className="text-red-600 mt-1 mx-1">
                                {methodAddAkumulasi.formState.errors["akumulasi"].message}
                            </p>
                        )}
                    </div>
                    <div className={``}>
                        <h1 className='font-light text-lg mb-2'>Status</h1>
                        <div className='flex my-auto md:space-x-0 space-x-2'>
                            <RadioForm method={methodAddAkumulasi} methodName="status" value_="true" label="Ditampilkan" />
                            <RadioForm method={methodAddAkumulasi} methodName="status" value_="false" label="Tidak" />
                        </div>
                        {methodAddAkumulasi.formState.errors["status"] && (
                            <p className="text-red-600 mt-1 mx-1">
                                {methodAddAkumulasi.formState.errors["status"].message}
                            </p>
                        )}
                    </div>
                    <div className='flex justify-end pt-4'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default AddAkumulasi