import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiPointAbsensi } from "../../api/pointAbsensi";

export const getAllPointAbsensi = createAsyncThunk(
    'get/point-absensi/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiPointAbsensi.getAll()
            if(res.status === 200){
                return res.data
            }
        }catch(r:any){
            return rejectWithValue(r?.response?.data?.msg)
        }
    }
)

export const postPointAbsensi = createAsyncThunk(
    'post/point-absensi',
    async(data:{uuid_point:string},{rejectWithValue})=>{
        try{
            const res = await ApiPointAbsensi.post(data)
            if(res.status === 200){
                return res.data
            }
        }catch(r:any){
            return rejectWithValue(r?.response?.data?.msg)
        }
    }
)

export const deletePointAbsensi = createAsyncThunk(
    'delete/point-absensi',
    async(uuid:string,{rejectWithValue})=>{
        try{
            const res = await ApiPointAbsensi.delete(uuid)
            if(res.status === 200){
                return res.data
            }
        }catch(r:any){
            return rejectWithValue(r?.response?.data?.msg)
        }
    }
)