import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiPoint } from "../../api/point";
import { ErrorData } from "../interfaces";

export const allPointSantri = createAsyncThunk<[],string,{rejectValue:ErrorData}>(
    'point/santri/get',
    async(approve:string,{rejectWithValue})=>{
        try{
            const res : any = await ApiPoint.getPointSantri(approve)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const allcanceledPointSantri = createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'point/santri/get/canceled',
    async(_,{rejectWithValue})=>{
        try{
            const res : any = await ApiPoint.getCanceledPointSantri()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const allPointSantriByUuid = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'point/santri/uuid',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiPoint.getBySantriandThnAjr(data.uuid,data.thn)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const allPointSantriByDate = createAsyncThunk(
    'get/point-santri/by/date',
    async(data:{start:string,end:string},{rejectWithValue})=>{
        try{
            const res = await ApiPoint.getByDate(data)
            if(res.status === 200){
                return res.data
            }
        }catch(r:any){
            return rejectWithValue(r.response?.data?.msg)
        }
    }
)
export const addPointSantri = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'point/santri/post',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiPoint.postPointSantri(data)
            if(res.status === 200){
                return res.data
            }
        }catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const editPointSantri = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'point/santri/update',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiPoint.updatePointSantri(data.uuid,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const dropPointSantri = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'point/santri/delete',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiPoint.deletePointSantriByUser(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const dropcanceledPointSantri = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'point/santri/delete/succes',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiPoint.deleteCanceledPointSantri(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const generatePdfSantri = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'point-santri/generate/pdf/santri',
    async(data:any,{rejectWithValue})=>{
        try{
            const res = await ApiPoint.getPdfSantri(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const generatePdfPointLembaga = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'point-santri/generate/pdf/lembaga',
    async(data:any,{rejectWithValue})=>{
        try{
            const res = await ApiPoint.getPdfLembaga(data)
            if(res.status === 200){
                return res.data
            }
        }catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)