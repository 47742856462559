import { LoginAttributes } from "../type";
import api from "./http";

export const ApiAuth = {
    login : (e:LoginAttributes) =>{
        return api.post(`/login`,e)
    },
    logout : () =>{
        return api.get(`/logout`)
    }
}