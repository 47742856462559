import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import RadioForm from '../fields/RadioForm'
import Button from '../others/Button'
import SelectorForm from '../fields/SelectorForm'
import InputForm from '../fields/InputForm'
import TextAreaForm from '../fields/TextAreaForm'
import moment from 'moment'
import { FormAttributes } from './interfaces'

function EditPointSantri({closeForm,showForm,submit,data,thnOpt,pointOpt}:FormAttributes) {
    const methodEditPoint = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid_santri: yup.string().required("tidak boleh kosong"),
                uuid_point: yup.string().required("tidak boleh kosong"),
                thn_ajar: yup.string().required("tidak boleh kosong"),
                deskripsi: yup.string().required("tidak boleh kosong"),
                tanggal_kejadian: yup.string().required("tidak boleh kosong"),
            })
        )
    })
    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            methodEditPoint.reset({
                uuid_santri: data?.santri?.uuid,
                uuid_point: data?.list_point?.uuid,
                thn_ajar: data?.thn_ajar,
                deskripsi:data?.deskripsi,
                tanggal_kejadian:moment(data?.tanggal_kejadian).format("YYYY-MM-DD")
            })
        }
    }, [showForm, data])
    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Edit Point Santri</h1>
                </div>
                <form onSubmit={methodEditPoint.handleSubmit(submit)} className='px-6 py-7'>
                    <div className='mb-4 space-y-1'>
                        <SelectorForm label="Point" method={methodEditPoint} methodName="uuid_point" options={pointOpt||[]} />
                        <SelectorForm label="Tahun ajar" method={methodEditPoint} methodName="thn_ajar" options={thnOpt||[]} />
                        <InputForm label="Tanggal Kejadian" type="date" method={methodEditPoint} methodName="tanggal_kejadian" />
                        <TextAreaForm method={methodEditPoint} methodName="deskripsi" label="Dskripsi" />
                    </div>

                    <div className='flex justify-end'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default EditPointSantri