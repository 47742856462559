import React, { ReactNode } from 'react'

interface InputFormAttributes{
    methodName:string;
    className?:string;
    label:string;
    method:any;
    max?:number;
    step?:number;
    type?:string;
    read?:string;
    icon?:ReactNode
}

function InputForm({methodName,label,method,className,icon,max,read,step,type}:InputFormAttributes) {
    const { error } = method.getFieldState(methodName)
    return (
        <div className={` ${className} flex flex-col w-full relative`}>
            <label className='font-sans text-gray-700'>{label}</label>
            <div className='flex'>
                <input {...method.register(methodName)} max={max} step={step} type={type} className={`py-[7px] px-2 outline-none border border-gray-400 rounded-md mt-1 w-full text-gray-800 ${method.formState.errors[methodName] ? "border-red-600" : "border-[#624d4d] hover:border-gray-400"}`} readOnly={read}/>
                {icon}
            </div>
            {error && <p className='text-red-600 mt-1 mx-[2px] text-sm font-light'>{error.message}</p>}
        </div>
    )
}

export default InputForm