import React, { ReactNode, useEffect, useState } from 'react'
import { HiOutlineXMark } from 'react-icons/hi2'

interface DialogAttributes{
    show:boolean;
    classDialog?:string;
    className?:string;
    children:ReactNode;
    classExit?:string;
    toggle:(e:boolean)=>void;
}

function Dialog({children,show,classDialog,className,classExit,toggle}:DialogAttributes) {
    // const [show, setShow] = useState(show)
    // useEffect(() => {
    //     setShow(show)
    // }, [show])
    return (
        <div className={`bg-black fixed top-0 left-0 z-10 w-full bg-opacity-10 h-full flex justify-center transition-all ease-in-out duration-300 ${show ? "visible" : "invisible"} overflow-y-auto`}>
            <div className='flex my-10 flex-col '>
                <div className={`bg-white shadow-2xl relative rounded-md mx-1 z-50 ${classDialog}  ${show ? "visible bg-opacity-100 text-opacity-100" : "md:-mt-[170vw] -mt-[400vw]"} transition-all ease-in-out duration-700`}>
                    <div className={`absolute md:right-6 right-3 top-3 cursor-pointer transition-all ease-in-out duration-300 ${classExit}`} onClick={() => toggle(false)}><HiOutlineXMark className='w-8 h-8'/></div>
                    <div className={`${className}`}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dialog