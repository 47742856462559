import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from "yup"
import { allListLembaga, allRole } from '../../store/actions/options'
import Dialog from '../../templates/Dialog'
import RadioForm from '../fields/RadioForm'
import SelectorForm from '../fields/SelectorForm'
import Button from '../others/Button'
import { FormAttributes } from './interfaces'

function AddSistem({closeForm,showForm,submit}:FormAttributes) {
    const dispatch = useDispatch<any>()
    const { sistemAll, roleAll, listLembagaAll } = useSelector((state: any) => state.options)
    const methodAddSistem = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid_sistem: yup.string().required("Tidak Boleh Kosong"),
                uuid_role: yup.string().required("Tidak Boleh Kosong"),
                super_admin: yup.string().oneOf(["true", "false"], "Pilih Salah Satu").required("Tidak Boleh Kosong"),
                no_lembaga: yup.string()
            })
        )
    })
    const sistemWatch = methodAddSistem.watch("uuid_sistem")
    const roleWatch = methodAddSistem.watch("uuid_role")
    const sistemOpt = sistemAll.map((d: any) => {
        return { value: d.uuid, label: d.nama_sistem }
    })
    const roleOpt = roleAll.map((d: any) => {
        return { value: d.uuid, label: d.nama_role }
    })
    const lemOpt = listLembagaAll.map((d: any) => {
        return { value: d.no_lembaga, label: d.nama_lembaga }
    })
    useEffect(() => {
        if (sistemWatch !== "" && sistemWatch !== undefined && sistemWatch !== null && showForm) {
            dispatch(allRole(sistemWatch))
        }
    }, [sistemWatch, showForm])
    useEffect(() => {
        if (roleWatch !== "" && roleWatch !== undefined && roleWatch !== null && showForm) {
            dispatch(allListLembaga())
        }
    }, [roleWatch, showForm])
    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Tambah Sistem Baru</h1>
                </div>
                <form onSubmit={methodAddSistem.handleSubmit(submit)} className='px-6 py-7 space-y-1'>
                    <SelectorForm label="Sistem" method={methodAddSistem} methodName="uuid_sistem" options={sistemOpt} />
                    <SelectorForm label="Role" method={methodAddSistem} methodName="uuid_role" options={roleOpt} />
                    <div className='mb-1'>
                        <h1 className='font-light text-lg mb-2'>Super Admin</h1>
                        <div className='flex my-auto md:space-x-0 space-x-2'>
                            <RadioForm method={methodAddSistem} methodName={`super_admin`} value_="true" label="Iya" />
                            <RadioForm method={methodAddSistem} methodName={`super_admin`} value_="false" label="Tidak" />
                        </div>
                        {methodAddSistem.formState.errors[`super_admin`] && (
                            <p className="text-red-600 mt-1 mx-1">
                                {methodAddSistem.formState.errors[`super_admin`].message}
                            </p>
                        )}
                    </div>
                    <div className={roleWatch === roleOpt.find((e:any) => e.label === "admin-lembaga")?.value && roleWatch !== undefined ? "block" : "hidden"}>
                        <SelectorForm label="Lembaga" method={methodAddSistem} methodName="no_lembaga" options={lemOpt} />
                    </div>
                    <div className='flex justify-end'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default AddSistem