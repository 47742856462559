import { createSlice } from "@reduxjs/toolkit";
import { allPointSantri, addPointSantri, editPointSantri, dropPointSantri, allPointSantriByUuid, allcanceledPointSantri, dropcanceledPointSantri, generatePdfSantri, generatePdfPointLembaga, allPointSantriByDate } from "../actions/pointSantri";
import { PointSantri } from "../interfaces";

const initialState: PointSantri = {
    pointSantriAll: [],
    pointSantriOne: {},
    loadingPointSantri: false,
    succesPdfPoint : false,
    msgPointSantri: ""
}

export const pointSantriStore = createSlice({
    name: "pointSantri",
    initialState,
    reducers: {
        clearPointSantri: (state) => {
            state.pointSantriAll = []
            state.msgPointSantri = ""
        },
        clearOnePointSantri: (state) => {
            state.pointSantriOne = {}
            state.msgPointSantri = ""
        },
        clearPdf : (state)=>{
            state.succesPdfPoint = false
            state.msgPointSantri = ""
        }
    },
    extraReducers: builder => {
        builder
            .addCase(allPointSantri.pending, (state) => {
                state.loadingPointSantri = true
            })
            .addCase(allPointSantri.fulfilled, (state,action) => {
                state.loadingPointSantri = false
                state.pointSantriAll = action.payload
            })
            .addCase(allPointSantri.rejected, (state,action) => {
                state.loadingPointSantri = false
                state.pointSantriAll = []
                state.msgPointSantri = action.payload?.message
            })
            .addCase(allcanceledPointSantri.pending, (state) => {
                state.loadingPointSantri = true
            })
            .addCase(allcanceledPointSantri.fulfilled, (state,action) => {
                state.loadingPointSantri = false
                state.pointSantriAll = action.payload
            })
            .addCase(allcanceledPointSantri.rejected, (state,action) => {
                state.loadingPointSantri = false
                state.pointSantriAll = []
                state.msgPointSantri = action.payload?.message
            })
            .addCase(allPointSantriByUuid.pending, (state) => {
                state.loadingPointSantri = true
            })
            .addCase(allPointSantriByUuid.fulfilled, (state,action) => {
                state.loadingPointSantri = false
                state.pointSantriAll = action.payload
            })
            .addCase(allPointSantriByUuid.rejected, (state,action) => {
                state.loadingPointSantri = false
                state.pointSantriAll = []
                state.msgPointSantri = action.payload?.message
            })
            .addCase(allPointSantriByDate.pending, (state) => {
                state.loadingPointSantri = true
            })
            .addCase(allPointSantriByDate.fulfilled, (state,action) => {
                state.loadingPointSantri = false
                state.pointSantriAll = action.payload
            })
            .addCase(allPointSantriByDate.rejected, (state,action) => {
                state.loadingPointSantri = false
                state.pointSantriAll = []
                state.msgPointSantri = action.payload
            })
            .addCase(addPointSantri.pending, (state) => {
                state.loadingPointSantri = true
            })
            .addCase(addPointSantri.fulfilled, (state,action) => {
                state.loadingPointSantri = false
                state.msgPointSantri = action.payload.msg
            })
            .addCase(addPointSantri.rejected, (state,action) => {
                state.loadingPointSantri = false
                state.msgPointSantri = action.payload?.message
            })
            .addCase(editPointSantri.pending, (state) => {
                state.loadingPointSantri = true
            })
            .addCase(editPointSantri.fulfilled, (state,action) => {
                state.loadingPointSantri = false
                state.msgPointSantri = action.payload.msg
            })
            .addCase(editPointSantri.rejected, (state,action) => {
                state.loadingPointSantri = false
                state.msgPointSantri = action.payload?.message
            })
            .addCase(dropPointSantri.pending, (state) => {
                state.loadingPointSantri = true
            })
            .addCase(dropPointSantri.fulfilled, (state,action) => {
                state.loadingPointSantri = false
                state.msgPointSantri = action.payload.msg
            })
            .addCase(dropPointSantri.rejected, (state,action) => {
                state.loadingPointSantri = false
                state.msgPointSantri = action.payload?.message
            })
            .addCase(dropcanceledPointSantri.pending, (state) => {
                state.loadingPointSantri = true
            })
            .addCase(dropcanceledPointSantri.fulfilled, (state,action) => {
                state.loadingPointSantri = false
                state.msgPointSantri = action.payload.msg
            })
            .addCase(dropcanceledPointSantri.rejected, (state,action) => {
                state.loadingPointSantri = false
                state.msgPointSantri = action.payload?.message
            })
            .addCase(generatePdfSantri.pending, (state) => {
                state.loadingPointSantri = true
                state.succesPdfPoint = false
            })
            .addCase(generatePdfSantri.fulfilled, (state,action) => {
                state.loadingPointSantri = false
                state.msgPointSantri = action.payload.msg
                state.succesPdfPoint = true
            })
            .addCase(generatePdfSantri.rejected, (state,action) => {
                state.loadingPointSantri = false
                state.msgPointSantri = action.payload?.message
                state.succesPdfPoint = false
            })
            .addCase(generatePdfPointLembaga.pending, (state) => {
                state.loadingPointSantri = true
                state.succesPdfPoint = false
            })
            .addCase(generatePdfPointLembaga.fulfilled, (state,action) => {
                state.loadingPointSantri = false
                state.msgPointSantri = action.payload.msg
                state.succesPdfPoint = true
            })
            .addCase(generatePdfPointLembaga.rejected, (state,action) => {
                state.loadingPointSantri = false
                state.msgPointSantri = action.payload?.message
                state.succesPdfPoint = false
            })


    }
})
export const pointSantriAction = pointSantriStore.actions
export default pointSantriStore.reducer