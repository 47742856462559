import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiPerizinan } from "../../api/perizinan";
import { ErrorData } from "../interfaces";

export const allPerizinan = createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'perizinan/santri/get',
    async(_,{rejectWithValue})=>{
        try{
            const res : any =  await ApiPerizinan.getPerizinan()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const allPerizinanByKey = createAsyncThunk<[],any,{rejectValue:ErrorData}>(
    'perizinan/santri/get/key',
    async(key:any,{rejectWithValue})=>{
        try{
            const res : any =  await ApiPerizinan.getPerizinanByKey(key)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const allPerizinanBySantri =  createAsyncThunk<[],any,{rejectValue:ErrorData}>(
    'perizinan/santri/get/one',
    async(uuid:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiPerizinan.getPerizinanBySantri(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const addPerizinan = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'perizinan/santri/post',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiPerizinan.postPerizinan(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const editPerizinan = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'perizinan/santri/update',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any =  await ApiPerizinan.updatePerizinan(data.uuid,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const dropPerizinan = createAsyncThunk<any,string,{rejectValue:ErrorData}>(
    'perizinan/santri/delete',
    async(uuid:string,{rejectWithValue})=>{
        try{
            const res : any = await ApiPerizinan.deletePerizinan(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const editStatusPerizinan =  createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'perizinan/santri/change/status',
    async(uuid:string,{rejectWithValue})=>{
        try{
            const res : any = await ApiPerizinan.changeStatus(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const getCetakKartu = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'perizinan/perizinan-santri/kartu',
    async(uuid,{rejectWithValue})=>{
        try{
            const res = await ApiPerizinan.getKartu(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)