import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import InputForm from '../fields/InputForm'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import RadioForm from '../fields/RadioForm'
import Button from '../others/Button'
import { FormAttributes } from './interfaces'

function EditListKamar({closeForm,showForm,submit,data}:FormAttributes) {
    const methodAddListKamar = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                namaKamar: yup.string().required("Tidak boleh kosong"),
                jumlahKasur: yup.string().min(1,"Minimal 1").required("Tidak boleh kosong"),
                milikAsrama: yup.string().oneOf(["L", "P"], "Pilih salah satu").required("Tidak boleh kosong")
            })
        )
    })
    useEffect(()=>{
        if(Object.keys(data).length !== 0){
            methodAddListKamar.reset(data)
        }
    },[showForm,data])
    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Tambah List Kamar</h1>
                </div>
                <form onSubmit={methodAddListKamar.handleSubmit(submit)} className='px-6 py-7 space-y-1'>
                    <InputForm label="Nama Kamar" method={methodAddListKamar} methodName="namaKamar" />
                    <InputForm label="Jumlah Kasur" method={methodAddListKamar} methodName="jumlahKasur" type="number" />
                    <div>
                        <h1 className='font-light text-lg mb-2'>Milik Asrama</h1>
                        <div className='flex my-auto md:space-x-0 space-x-2'>
                            <RadioForm method={methodAddListKamar} methodName="milikAsrama" value_="L" label="Putra" />
                            <RadioForm method={methodAddListKamar} methodName="milikAsrama" value_="P" label="Putri" />
                        </div>
                        {methodAddListKamar.formState.errors["milikAsrama"] && (
                            <p className="text-red-600 mt-1 mx-1">
                                {methodAddListKamar.formState.errors["milikAsrama"].message}
                            </p>
                        )}
                    </div>
                    <div className='flex justify-end'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default EditListKamar