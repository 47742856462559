import api from "./http";

export const ApiOptions = {
    getThnAjar: () => {
        return api.get(`/tahun`)
    },
    getLembaga: () => {
        return api.get(`/lembaga/cakepin`)
    },
    sistem: () => {
        return api.get(`/sistem`)
    },
    role: (uuid:string) => {
        return api.get(`/role/${uuid}`)
    },
    LembagaList : () =>{
        return api.get(`/apakah/list-lembaga`)
    },
}