import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import InputForm from '../fields/InputForm'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import RadioForm from '../fields/RadioForm'
import Button from '../others/Button'
import { FormAttributes } from './interfaces'

function ApproveDelete({closeForm,showForm,submit,data}:FormAttributes) {
  const methodDeleteApprovePoint = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        uuid: yup.string().required(),
        uuid_canceled :yup.string().required(),
        process: yup.string().oneOf(["yes","no"]).required("tidak boleh kosong")
      })
    )
  })
  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      if(data?.point_santri){
        methodDeleteApprovePoint.reset({ uuid: data?.point_santri?.uuid,uuid_canceled:data?.uuid })
      }
      else{
        methodDeleteApprovePoint.reset({ uuid: data?.prestasiSantri?.uuid,uuid_canceled:data?.uuid })
      }
    }
  }, [showForm, data])
  return (
    <Dialog show={showForm} toggle={closeForm}>
      <div className='bg-white md:w-[40vw] w-[95vw]'>
        <div className='py-4 px-6 border-b border-slate-200'>
          <h1 className='text-xl'>Penghapusan point</h1>
        </div>
        <form onSubmit={methodDeleteApprovePoint.handleSubmit(submit)} className='px-6 py-7 space-y-1'>
          <div>
            <h1 className='font-light text-lg mb-2'>Yakin untuk dihapus?</h1>
            <div className='flex my-auto md:space-x-0 space-x-2'>
              <RadioForm method={methodDeleteApprovePoint} methodName="process" value_="yes" label="Iya" />
              <RadioForm method={methodDeleteApprovePoint} methodName="process" value_="no" label="Tidak" />
            </div>
            {methodDeleteApprovePoint.formState.errors["process"] && (
              <p className="text-red-600 mt-1 mx-1">
                {methodDeleteApprovePoint.formState.errors["process"].message}
              </p>
            )}
          </div>
          <div className='flex justify-end'>
            <Button type="submit" title="Simpan" />
          </div>
        </form>
      </div>
    </Dialog>)
}

export default ApproveDelete