import React, { useEffect, useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { useSelector } from 'react-redux'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import HeaderUi from '../templates/HeaderUi'
import { useParams } from 'react-router-dom'
import { allPointSantriByUuid } from '../store/actions/pointSantri'
import { oneSantri } from '../store/actions/santri'
import Button from '../component/others/Button'


function CetakPointIndividu() {
    let componentRef = useRef<HTMLDivElement>(null);
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { pointSantriAll, loadingPointSantri, msgPointSantri } = useSelector((state: RootState) => state.pointSantri)
    const { santriOne } = useSelector((state: RootState) => state.santri)
    const [totalPoint, setTotalPoint] = useState(0)
    const [start,setStart] = useState(0)
    const [step, setStep] = useState(10)
    const [previous, setPrevious] = useState(10)
    const [loadPoint,setLoadPoint] = useState<any>([])
    const { uuid, tahun } = useParams()
    useEffect(() => {
        if (uuid) {
            dispatch(oneSantri(uuid))
            dispatch(allPointSantriByUuid({ uuid: uuid, thn: tahun }))
        }
    }, [uuid])
    const nextHandler = ()=>{
        if(step + 10 < pointSantriAll.length){
            setStart(step)
            setStep((e)=>e+10)
        }else{
            setStart(step)
            setStep((e)=>e+(pointSantriAll.length-step-1))
        }
    }
    console.log(start,step)
    return (
        <div>
            <HeaderUi title="Cetak point" />
            <div className='flex'>
                <ReactToPrint
                    trigger={() => <button className='border border-blue-600 px-8 py-2 m-2 hover:bg-white transition-colors ease-in-out duration-300'>Print</button>}
                    content={() => componentRef.current}
                />
                <div className='flex gap-4 my-2'>
                    <Button title="Previous" onClick={() => {
                        console.log("heello")
                    }} />
                    <Button title="Next" onClick={nextHandler} />
                </div>
            </div>
            <div className='flex justify-center items-center py-5'>
                <div ref={componentRef} className='bg-white w-[21.0cm] h-[29.7cm] p-12 shadow-xl'>
                    <div className='text-center'>
                        <h1 className='text-3xl'>PONPES ABU HURAIRAH MATARAM</h1>
                        <h1 className='text-2xl mt-4'>POINT SANTRI</h1>
                    </div>
                    <div className='w-[100%] h-1 bg-black mt-5 mb-[2px]'></div>
                    <div className='w-[100%] h-[2px] bg-black mb-5'></div>
                    <div className='flex flex-wrap text-lg'>
                        <div className='flex w-[50%]'>
                            <h1 className='w-[25%]'>Nuwb</h1>
                            <h1 className='w-[5%]'>:</h1>
                            <h1 className='w-[75%]'>{santriOne.santri?.nuwb}</h1>
                        </div>
                        <div className='flex w-[50%]'>
                            <h1 className='w-[25%]'>Lembaga</h1>
                            <h1 className='w-[5%]'>:</h1>
                            <h1 className='w-[75%]'>{santriOne.santri?.lembaga?.nama}</h1>
                        </div>
                        <div className='flex w-[50%]'>
                            <h1 className='w-[25%]'>Nama</h1>
                            <h1 className='w-[5%]'>:</h1>
                            <h1 className='w-[75%]'>{santriOne.santri?.nama}</h1>
                        </div>
                        <div className='flex w-[50%]'>
                            <h1 className='w-[25%]'>Kelas</h1>
                            <h1 className='w-[5%]'>:</h1>
                            <h1 className='w-[75%]'>{santriOne.santri?.kelas}</h1>
                        </div>
                    </div>
                    <div className='my-5 h-[80%] w-[100%] text-sm'>
                        <table>
                            <thead>
                                <tr>
                                    <th className='px-6 py-3 border border-black'>No</th>
                                    <th className='px-6 py-3 border-r border-y border-black'>Pelanggaran</th>
                                    <th className='px-6 py-3 border border-black'>Point</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pointSantriAll?.map((data: any, id: number) => {
                                    return <tr key={id}>
                                        <td className='px-6 py-3 border-b border-x border-black'>{id + 1}</td>
                                        <td className='px-6 py-3 border-r border-b border-black'>{data.list_point?.judul}</td>
                                        <td className='px-6 py-3 border-b border-x border-black'>{data.list_point?.jumlahPoint}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <div className='flex justify-end w-[100%]'>
                            <div className='flex justify-around text-lg font-semibold my-1 w-[13%]'>
                                <h1 className=''>Total</h1>
                                <h1 className=''>:</h1>
                                <h1 className=''>{totalPoint}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CetakPointIndividu