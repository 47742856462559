import { HiUser,HiOutlineChevronDown } from "react-icons/hi2"
import {AiOutlineControl} from "react-icons/ai"
import {BsDoorOpenFill} from "react-icons/bs"
import {BiNotepad} from "react-icons/bi"
import {MdAccessTimeFilled} from "react-icons/md"
import { LuBookOpen } from "react-icons/lu";


export const menuSantri = [
    {link:"/home/santri",title:"Santri",icon:<HiUser className="w-6 h-6 mx-2 cursor-pointer"/>},
    {link:"/home/absensi",title:"Absensi",icon:<LuBookOpen className="w-6 h-6 mx-2 cursor-pointer"/>},
    {link:"/home/kamar",title:"Kamar",icon:<BsDoorOpenFill className="w-6 h-6 mx-2 cursor-pointer"/>},
    {link:"/home/point",title:"Point",icon:<BiNotepad className="w-6 h-6 mx-2 cursor-pointer"/>},
    {link:"/home/perizinan",title:"Perizinan",icon:<MdAccessTimeFilled className="w-6 h-6 mx-2 cursor-pointer"/>},
]
export const menuPanel = [
    {  title: "Panel", logo: <AiOutlineControl className="w-6 h-6 mx-2" />,dropIcon: <HiOutlineChevronDown className='w-5 h-5' />, drop: true,menuDrop:[
        { link: "/home/point/all", title: "Akumulasi Point" },
        { link: "/home/prestasi/all", title: "Akumulasi Prestasi" },
        { link: "/home/rekap/lembaga", title: "Rekap Lembaga" },
        { link: "/home/list/izin",title: "List Izin" },
        { link: "/home/list/kamar",title: "List Kamar" },
        { link: "/home/list/point", title: "List Point" },
        { link: "/home/point/absensi", title: "Point Absensi" },
        { link: "/home/list/prestasi", title: "List Prestasi" },
        { link: "/home/list/user", title: "List User" },
        
    ] }
]