import { CanceledPrestasiSantriAttributes, DeletePrestasiSantriAttributes, ListPrestasiAttributes, PdfLembagaAttributes, PdfSantriAttributes, PrestasiSantriAttributes } from "../type";
import api from "./http";


export const ApiPrestasi = {
    getList : () =>{
        return api.get(`/perizinan/list-prestasi`)
    },
    getListByUuid : (uuid:string) =>{
        return api.get(`/perizinan/list-prestasi/one/${uuid}`)
    },
    getListByKey : (key:string)=>{
        return api.get(`/perizinan/list-prestasi/${key}`)
    },
    postList :(data:ListPrestasiAttributes)=>{
        return api.post(`/perizinan/list-prestasi`,data)
    },
    updateList : (uuid:string,data:ListPrestasiAttributes)=>{
        return api.put(`/perizinan/list-prestasi/${uuid}`,data)
    },
    deleteList : (uuid:string)=>{
        return api.delete(`/perizinan/list-prestasi/${uuid}`)
    },
    getPrestasiSantri : (approve:string) =>{
        return api.get(`/perizinan/prestasi-santri/${approve}`)
    },
    getPrestasiBySantrinThn : (uuid:string,thn:string)=>{
        return api.get(`/perizinan/prestasi-santri/${uuid}/${thn}`)
    },
    getByDate :(data:{start:string,end:string})=>{
        return api.post(`/perizinan/prestasi-santri/report-prestasi`,data)
    },
    postPrestasiSantri : (data:PrestasiSantriAttributes)=>{
        return api.post(`/perizinan/prestasi-santri`,data)
    },
    updatePrestasiSantri : (uuid:string,data:PrestasiSantriAttributes)=>{
        return api.put(`/perizinan/prestasi-santri/${uuid}`,data)
    },
    deletePrestasiSantriByUser : (data:DeletePrestasiSantriAttributes)=>{
        return api.post(`/perizinan/prestasi-santri/delete`,data)
    },
    getCanceledPrestasiSantri : () =>{
        return api.get(`/perizinan/prestasi-santri/prestasi/canceled`)
    },
    deleteCanceledPrestasiSantri : (data:CanceledPrestasiSantriAttributes)=>{
        return api.post(`/perizinan/prestasi-santri/approve`,data)
    },
    getPdfSantri : (data:PdfSantriAttributes)=>{
        return api.post(`/perizinan/prestasi-santri/generate-pdf/santri`,data)
    },
    getPdfLembaga :(data:PdfLembagaAttributes)=>{
        return api.post(`/perizinan/prestasi-santri/generate-pdf/lembaga`,data)
    }
}