import React, { useEffect} from 'react'
import { useForm } from 'react-hook-form'
import InputForm from '../fields/InputForm'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import RadioForm from '../fields/RadioForm'
import Button from '../others/Button'
import TextAreaForm from '../fields/TextAreaForm'
import { FormAttributes } from './interfaces'

function EditStatusMondok({closeForm,showForm,submit,data}:FormAttributes) {
    const methodEditStatusSantri = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid : yup.string().required("tidak boleh kosong"),
                mondok  : yup.string().oneOf(["iya","tidak"],"Pilih salah satu").required("Tidak boleh kosong")
            })
        )
    })
    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            if(data?.mondok){
                methodEditStatusSantri.reset({uuid:data?.uuid_santri,mondok:"iya"})
            }
            else{
                methodEditStatusSantri.reset({uuid:data?.uuid_santri,mondok:"tidak"})
            }
        }
    }, [showForm, data])
    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Edit Status Santri</h1>
                </div>
                <form onSubmit={methodEditStatusSantri.handleSubmit(submit)} className='px-6 py-7 space-y-1'>
                    <div>
                        <h1 className='font-light text-lg mb-2'>Status Mondok</h1>
                        <div className='flex my-auto md:space-x-0 space-x-2'>
                            <RadioForm method={methodEditStatusSantri} methodName="mondok" value_="iya" label="Mondok" />
                            <RadioForm method={methodEditStatusSantri} methodName="mondok" value_="tidak" label="Tidak Mondok" />
                        </div>
                        {methodEditStatusSantri.formState.errors["mondok"] && (
                            <p className="text-red-600 mt-1 mx-1">
                                {methodEditStatusSantri.formState.errors["mondok"].message}
                            </p>
                        )}
                    </div>
                    <div className='flex justify-end'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default EditStatusMondok