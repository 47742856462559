import React, { useEffect, useState } from 'react'
import HeaderUi from '../templates/HeaderUi'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import { PointAbsensiActions } from '../store/slice/pointAbsensi'
import { deletePointAbsensi, getAllPointAbsensi, postPointAbsensi } from '../store/actions/pointAbsensi'
import Table from '../templates/Table'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'
import { HiOutlineTrash } from 'react-icons/hi2'
import FormAddPointAbsensi from '../component/form/FormAddPointAbsensi'
import Button from '../component/others/Button'
import Loading from '../templates/Loading'
import Alert from '../templates/Alert'
import DialogDelete from '../component/others/DialogDelete'

export default function PointAbsensi() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { allPointAbsensi, loadingPointAbsensi, messagePointAbsensi } = useSelector((state: RootState) => state.pointAbsensi)
    const headTable = ["No", "Point", "Delete"]
    const [saveOne, setSaveOne] = useState<any>({})
    const [showForm, setShowForm] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    useEffect(() => {
        dispatch(PointAbsensiActions.clearPointAbsensi())
        dispatch(getAllPointAbsensi())
    }, [dispatch, showAlert])
    const save = (e: any) => {
        dispatch(postPointAbsensi(e))
        setShowForm(false)
        setShowAlert(true)
        setTimeout(() => {
            setShowAlert(false)
        }, 3000)
    }
    const hapusPointAbsensi = ()=>{
        setShowDelete(false)
        dispatch(deletePointAbsensi(saveOne?.uuid))
        setShowAlert(true)
        setTimeout(() => {
            setShowAlert(false)
        }, 3000)
    }
    return (
        <div>
            <HeaderUi title='Point Absensi' />
            <Loading show={loadingPointAbsensi} />
            <Alert show={showAlert} msg={messagePointAbsensi} />
            <FormAddPointAbsensi showForm={showForm} closeForm={setShowForm} submit={save} />
            <DialogDelete  show={showDelete} close={setShowDelete} msg='Delete Point Absensi' onClick={hapusPointAbsensi}/>  
            <Table>
                <Button title='Tambah' onClick={() => setShowForm(!showForm)} />
            </Table>
            <Table>
                {allPointAbsensi.length > 0 ?
                    <TableFields head={headTable}>
                        {allPointAbsensi.map((e: any, id: number) => (
                            <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                <td className='px-6 py-3'>{id + 1}</td>
                                <td className='px-6 py-3'>{e.list_point?.judul}</td>
                                <td className='px-6 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-2' onClick={() => {
                                    setSaveOne(e)
                                    setShowDelete(true)
                                }} /></td>
                            </tr>
                        ))}
                    </TableFields>
                    :
                    <EmptyTable title='Tidak ada point absensi yang tersedia' />
                }
            </Table>
        </div>
    )
}
