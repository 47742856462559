import React, { useEffect} from 'react'
import { useForm } from 'react-hook-form'
import InputForm from '../fields/InputForm'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import RadioForm from '../fields/RadioForm'
import Button from '../others/Button'
import TextAreaForm from '../fields/TextAreaForm'
import { FormAttributes } from './interfaces'

function EditListPrestasi({closeForm,showForm,submit,data}:FormAttributes) {
    const methodEditListPrestasi = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                judul: yup.string().required("tidak boleh kosong"),
                deskripsi: yup.string().required("tidak boleh kosong"),
                jumlahPoint: yup.number().required("tidak boleh kosong"),
                accumulate: yup.string().oneOf(["true", "false"], "pilih salah satu").required("tidak boleh kosong"),
            })
        )
    })
    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            methodEditListPrestasi.reset({...data,accumulate:data.accumulate.toString()})
        }
    }, [showForm, data])
    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Tambah List Point</h1>
                </div>
                <form onSubmit={methodEditListPrestasi.handleSubmit(submit)} className='px-6 py-7 space-y-1'>
                    <InputForm label="Judul" method={methodEditListPrestasi} methodName="judul" />
                    <TextAreaForm label="deskripsi" method={methodEditListPrestasi} methodName="deskripsi" />
                    <InputForm label="Jumlah Point" method={methodEditListPrestasi} methodName="jumlahPoint" type="number" />
                    <div>
                        <h1 className='font-light text-lg mb-2'>Akumulasi</h1>
                        <div className='flex my-auto md:space-x-0 space-x-2'>
                            <RadioForm method={methodEditListPrestasi} methodName="accumulate" value_="true" label="Iya" />
                            <RadioForm method={methodEditListPrestasi} methodName="accumulate" value_="false" label="Tidak" />
                        </div>
                        {methodEditListPrestasi.formState.errors["accumulate"] && (
                            <p className="text-red-600 mt-1 mx-1">
                                {methodEditListPrestasi.formState.errors["accumulate"].message}
                            </p>
                        )}
                    </div>
                    <div className='flex justify-end'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default EditListPrestasi