import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import { RootState, TypeDispatch, useAppDispatch } from '../../store'
import { allListPoint } from '../../store/actions/listPoint'
import { useSelector } from 'react-redux'
import { FormAttributes } from './interfaces'
import SelectorForm from '../fields/SelectorForm'
import Button from '../others/Button'


export default function FormAddPointAbsensi({ showForm, closeForm, submit }: FormAttributes) {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { listPointAll, loadingListPoint } = useSelector((state: RootState) => state.listPoint)
    const method = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid_point: yup.string().required("Point tidak boleh kosong")
            })
        )
    })
    const listPointOptions = listPointAll.map((e: any) => {
        return { value: e.uuid, label: e.judul }
    })
    useEffect(() => {
        dispatch(allListPoint())
    }, [dispatch])
    return (
        <Dialog show={showForm} toggle={closeForm} >
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Add Point Absensi</h1>
                </div>
                <form onSubmit={method.handleSubmit(submit)} className='px-6 py-7'>
                    <SelectorForm label="Point" method={method} methodName="uuid_point" options={listPointOptions} />
                    <div className='flex justify-end mt-5'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}
