import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import RadioForm from '../fields/RadioForm'
import Button from '../others/Button'
import SelectorForm from '../fields/SelectorForm'
import { RootState, TypeDispatch, useAppDispatch } from '../../store'
import { allListPointByUuid } from '../../store/actions/listPoint'
import { useSelector } from 'react-redux'
import Input from '../fields/Input'
import { allListPrestasiByUuid } from '../../store/actions/listPrestasi'
import InputForm from '../fields/InputForm'
import TextAreaForm from '../fields/TextAreaForm'
import { FormAttributes } from './interfaces'

function AddPrestasiSantri({closeForm,showForm,submit,uuid,thnOpt,prestasiOpt}:FormAttributes) {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { listPrestasiOne } = useSelector((state: RootState) => state.listPrestasi)
    const methodAddPrestasi = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid_santri: yup.string().required("tidak boleh kosong"),
                uuid_prestasi: yup.string().required("tidak boleh kosong"),
                thn_ajar: yup.string().required("tidak boleh kosong"),
                akumulasi: yup.boolean().required("tidak boleh kosong"),
                status: yup.boolean().required("tidak boleh kosong"),
                deskripsi: yup.string().required("tidak boleh kosong"),
                tanggal_kejadian: yup.string().required("tidak boleh kosong")
            })
        ),
        defaultValues: { akumulasi: false, status: false }
    })
    const watchPrestasi = methodAddPrestasi.watch("uuid_prestasi")
    useEffect(() => {
        methodAddPrestasi.reset({ uuid_santri: uuid })
    }, [showForm, uuid])
    useEffect(() => {
        if (showForm && (watchPrestasi !== undefined || watchPrestasi !== "")) {
            dispatch(allListPrestasiByUuid(watchPrestasi))
            methodAddPrestasi.setValue("akumulasi", false)
            methodAddPrestasi.setValue("status", false)
        }
    }, [watchPrestasi, showForm])
    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Tambah Prestasi Santri</h1>
                </div>
                <form onSubmit={methodAddPrestasi.handleSubmit(submit)} className='px-6 py-7'>
                    <div className='mb-4 space-y-1'>
                        <SelectorForm label="Prestasi" method={methodAddPrestasi} methodName="uuid_prestasi" options={prestasiOpt||[]} />
                        <div className={`${watchPrestasi !== undefined && watchPrestasi !== "" && watchPrestasi !== null ? "block" : "hidden"}`}>
                            <Input className="w-full" label="Point" value={listPrestasiOne !== undefined && listPrestasiOne !== null && Object.keys(listPrestasiOne)?.length !== 0 ? listPrestasiOne?.jumlahPoint : 0} read={true} />
                        </div>
                        <SelectorForm label="Tahun ajar" method={methodAddPrestasi} methodName="thn_ajar" options={thnOpt||[]} />
                        <InputForm label="Tanggal Kejadian" type="date" method={methodAddPrestasi} methodName="tanggal_kejadian" />
                        <TextAreaForm method={methodAddPrestasi} methodName="deskripsi" label="Deskripsi" />
                    </div>
                    <div className='flex justify-end'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>)
}

export default AddPrestasiSantri