import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../component/fields/Input'
import { searchSantri } from '../store/actions/santri'
import { santriActions } from '../store/slice/santri'
import Alert from '../templates/Alert'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import { TfiPanel } from "react-icons/tfi"
import { useNavigate } from 'react-router-dom'
import Loading from '../templates/Loading'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'


function Santri() {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const [key, setkey] = useState<string>("")
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [tableHead, settableHead] = useState(["No", "Nuwb", "Nama", "Lembaga", "Kelas", "Ruang", "Panel"])
  const { santriAll, loadingSantri } = useSelector((state: any) => state.santri)
  useEffect(() => {
    if ((key !== "" || key !== undefined) && key.length > 2) {
      dispatch(santriActions.clearSantri())
      dispatch(searchSantri(key))
    }
    else {
      dispatch(santriActions.clearSantri())
    }
  }, [key])
  return (
    <div>
      <Loading show={loadingSantri} />
      <Alert msg="Succes" show={showAlert} />
      <HeaderUi title="Santri" location_="/list" />
      <Table>
        <Input label="Masukkan nama santri" value={key} onChange={(e: any) => setkey(e.target.value)} className="md:w-80 w-full" />
      </Table>
      <Table>
        {Object.keys(santriAll).length === 0 ?
          <EmptyTable title='Tidak ada santri' />
          :
          <TableFields head={tableHead}>
            {santriAll.map((d: any, id: any) => (
              <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                <td className='px-6 py-3'>{id + 1}</td>
                <td className='px-6 py-3'>{d.nuwb}</td>
                <td className='px-6 py-3'>{d.nama}</td>
                <td className='px-6 py-3'>{d.lembaga?.nama}</td>
                <td className='px-6 py-3'>{d.kelas}</td>
                <td className='px-6 py-3'>{d.ruang}</td>
                <td className='px-6 py-3'><TfiPanel className='w-6 h-6 cursor-pointer mx-2' onClick={() => navigate(`/home/panel/${d.uuid}`)} /></td>
              </tr>
            ))}
          </TableFields>
        }
      </Table>
    </div>
  )
}

export default Santri