import axios, { Axios } from "axios";
import { isLogout } from "../store/actions/auth";

const api : Axios = axios.create({
    // baseURL:"http://localhost:9999"
    // baseURL:"http://192.168.1.12:9999",
    // baseURL:"http://192.168.248.232:9999"
    baseURL:"https://sipahamv2.ponpesabuhurairah.id"
})  

let store:any;
export const injectStore = (_store: any) => {
  store = _store
}

api.interceptors.request.use((req:any) => {
    if (!req.url.includes("/login")) {
      req.headers = {
        Authorization: `Bearer ${store.getState().auth.token}`,
      };
    }
  return req;
});

api.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401 && store.getState().auth.token !== "") {
     store.dispatch(isLogout())
    }
    return Promise.reject(err);
  }
);


export default api