import { createSlice } from "@reduxjs/toolkit";
import { allListPenjemput,allListPenjemputBySantri,addListPenjemput,updateListPenjemput,dropListPenjemput } from "../actions/listPenjemput";
import { ListPenjemput } from "../interfaces";

const initialState : ListPenjemput ={
    listPenjemputAll : [],
    loadingListPenjemput : false,
    msgListPenjemput : ""
}

export const listPenjemputStore = createSlice({
    name:'listPenjemput',
    initialState,
    reducers : {
        clearListPenjemput : (state)=>{
            state.listPenjemputAll = []
            state.msgListPenjemput = ""
        }
    },
    extraReducers : builder =>{
        builder
        .addCase(allListPenjemput.pending ,(state)=>{
            state.loadingListPenjemput = true
        })
        .addCase(allListPenjemput.fulfilled ,(state,action)=>{
            state.loadingListPenjemput = false
            state.listPenjemputAll = action.payload
        })
        .addCase(allListPenjemput.rejected ,(state,action)=>{
            state.loadingListPenjemput = false
            state.listPenjemputAll = []
            state.msgListPenjemput = action.payload?.message
        })
        .addCase(allListPenjemputBySantri.pending ,(state)=>{
            state.loadingListPenjemput = true
        })
        .addCase(allListPenjemputBySantri.fulfilled ,(state,action)=>{
            state.loadingListPenjemput = false
            state.listPenjemputAll = action.payload
        })
        .addCase(allListPenjemputBySantri.rejected ,(state,action)=>{
            state.loadingListPenjemput = false
            state.listPenjemputAll = []
            state.msgListPenjemput = action.payload?.message
        })
        .addCase(addListPenjemput.pending ,(state)=>{
            state.loadingListPenjemput = true
        })
        .addCase(addListPenjemput.fulfilled ,(state,action)=>{
            state.loadingListPenjemput = false
            state.msgListPenjemput = action.payload.msg
        })
        .addCase(addListPenjemput.rejected ,(state,action)=>{
            state.loadingListPenjemput = false
            state.msgListPenjemput = action.payload?.message
        })
        .addCase(updateListPenjemput.pending ,(state)=>{
            state.loadingListPenjemput = true
        })
        .addCase(updateListPenjemput.fulfilled ,(state,action)=>{
            state.loadingListPenjemput = false
            state.msgListPenjemput = action.payload.msg
        })
        .addCase(updateListPenjemput.rejected ,(state,action)=>{
            state.loadingListPenjemput = false
            state.msgListPenjemput = action.payload?.message
        })
        .addCase(dropListPenjemput.pending ,(state)=>{
            state.loadingListPenjemput = true
        })
        .addCase(dropListPenjemput.fulfilled ,(state,action)=>{
            state.loadingListPenjemput = false
            state.msgListPenjemput = action.payload.msg
        })
        .addCase(dropListPenjemput.rejected ,(state,action)=>{
            state.loadingListPenjemput = false
            state.msgListPenjemput = action.payload?.message
        })
    }
})

export const listPenjemputActions = listPenjemputStore.actions
export default listPenjemputStore.reducer