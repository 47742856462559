import React, { useEffect, useState } from 'react'
import { HiOutlineTrash } from 'react-icons/hi2'
import { useSelector } from 'react-redux'
import ApproveDelete from '../component/form/ApproveDelete'
import DialogDelete from '../component/others/DialogDelete'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { allcanceledPointSantri, dropcanceledPointSantri } from '../store/actions/pointSantri'
import Alert from '../templates/Alert'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import Button from '../component/others/Button'
import { Link } from 'react-router-dom'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'

function CanceledPoint() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { pointSantriAll, msgPointSantri } = useSelector((state: RootState) => state.pointSantri)
    const [showDeletePoint, setShowDeletePoint] = useState<boolean>(false)
    const [showAlertPoint, setShowAlertPoint] = useState<boolean>(false)
    const [tableHead, setTableHead] = useState(["No", "Nama", "Point", "Ket", "Catatan", "Delete"])
    const [saveOne, setSaveOne] = useState<any>({})
    const hapus = (e: any) => {
        if (e.process === "yes") {
            e.process = true
        }
        else {
            e.process = false
        }
        dispatch(dropcanceledPointSantri(e))
        setShowDeletePoint(false)
        setShowAlertPoint(true)
    }
    useEffect(() => {
        if (showAlertPoint) {
            const interval = setInterval(() => {
                setShowAlertPoint(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            dispatch(allcanceledPointSantri())
        }
    }, [showAlertPoint])
    return (
        <div>
            <Alert show={showAlertPoint} msg={msgPointSantri} />
            <ApproveDelete showForm={showDeletePoint} closeForm={setShowDeletePoint} data={saveOne} submit={hapus} />
            <HeaderUi title="Laporan Point Terhapus" />
            <Table>
                <div>
                    <Link to={"/home/laporan/prestasi"}>
                        <Button title="Prestasi" />
                    </Link>
                    {Object.keys(pointSantriAll).length === 0 ?
                        <EmptyTable title="Tidak ada point" />
                        :
                        <TableFields head={tableHead}>
                            {pointSantriAll.map((d: any, id: any) => (
                                <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                    <td className='px-6 py-3'>{id + 1}</td>
                                    <td className='px-6 py-3'>{d.point_santri?.santri?.nama}</td>
                                    <td className='px-6 py-3'>{d.point_santri?.list_point?.jumlahPoint}</td>
                                    <td className='px-6 py-3'>{d.point_santri?.list_point?.judul}</td>
                                    <td className='px-6 py-3'>{d.catatan}</td>
                                    <td className='px-6 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                        setSaveOne(d)
                                        setShowDeletePoint(true)
                                    }} /></td>
                                </tr>
                            ))}
                        </TableFields>
                    }
                </div>
            </Table>
        </div>
    )
}

export default CanceledPoint