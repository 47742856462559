import React, { ReactNode } from 'react'

interface InputAttributes{
    label:string;
    max?:number;
    step?:number;
    type?:string;
    icon?:ReactNode;
    className?:string;
    value:string;
    onChange?:(e:any)=>void;
    read?:boolean;
}

function Input({label,className,icon,max,onChange,read,step,type,value}:InputAttributes) {
    return (
        <div className={`flex flex-col w-full relative`}>
            <label className='font-sans text-gray-700'>{label}</label>
            <div className='flex'>
                <input max={max} step={step} type={type} className={`py-[7px] px-2 outline-none border border-gray-400 rounded-md mt-1 text-gray-800 hover:border-gray-400 ${className}`} readOnly={read} value={value} onChange={onChange} />
                {icon}
            </div>
        </div>)
}

export default Input