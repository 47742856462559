import React, { useEffect, useState } from 'react'
import Table from '../templates/Table'
import HeaderUi from '../templates/HeaderUi'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import { allPerizinanByLembaga, getAllSantriInAsrama } from '../store/actions/dashboard'
import { useParams } from 'react-router-dom'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'
import Loading from '../templates/Loading'

function DashboardSantriInAsrama() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const route = useParams<any>()
    const { allSantriInAsrama,loadingDashboard } = useSelector((state: RootState) => state.dashboard)
    const [headTable, setHeadTable] = useState(["No", "Nuwb","Lembaga", "Nama","Kelas","Ruang","Kamar"])
    useEffect(() => {
        dispatch(getAllSantriInAsrama(route.kode_lembaga))
    }, [route])
    return (
        <div>
            <Loading show={loadingDashboard}/>
            <HeaderUi title="Santri di asrama" />
            <Table>
                {allSantriInAsrama.length === 0 ?
                    <EmptyTable title="Tidak ada list" />
                    :
                    <TableFields head={headTable}>
                        {allSantriInAsrama.map((d: any, i: number) => (
                            <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={i}>
                                <td className='px-6 py-3'>{i + 1}</td>
                                <td className='px-6 py-3'>{d.nuwb}</td>
                                <td className='px-6 py-3'>{d.lembaga?.nama}</td>
                                <td className='px-6 py-3'>{d.nama}</td>
                                <td className='px-6 py-3'>{d.kelas}</td>
                                <td className='px-6 py-3'>{d.ruang}</td>
                                <td className='px-6 py-3'>{d.kamar_santri?.list_kamar?.namaKamar || "-"}</td>
                            </tr>
                        ))}
                    </TableFields>
                }
            </Table>
        </div>
    )
}

export default DashboardSantriInAsrama