import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import AddKamarSantri from '../component/form/AddKamarSantri'
import AddPerizinan from '../component/form/AddPerizinan'
import Button from '../component/others/Button'
import { penjemputOpt } from '../component/others/Penjemput'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { addkamarSantri } from '../store/actions/kamarSantri'
import { allListKamarByGender } from '../store/actions/listKamar'
import { addPerizinan } from '../store/actions/perizinan'
import { oneSantri, oneStatusSantri, updateStatusMondokSantri } from '../store/actions/santri'
import Alert from '../templates/Alert'
import HeaderUi from '../templates/HeaderUi'
import Loading from '../templates/Loading'
import Table from '../templates/Table'
import { allListPenjemputBySantri } from '../store/actions/listPenjemput'
import { allListPerizinan } from '../store/actions/listIzin'
import EditStatusMondok from '../component/form/EditStatusMondok'

function Panel() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const navigate = useNavigate()
    const { uuid } = useParams<string>()
    const [kamar, setKamar] = useState<any>([])
    const { santriOne, loadingSantri, statusOne, msgSantri } = useSelector((state: RootState) => state.santri)
    const { listKamarAll } = useSelector((state: RootState) => state.listKamar)
    const { msgKamarSantri, loadingKamarSantri } = useSelector((state: RootState) => state.kamarSantri)
    const { loadingPerizinan, msgPerizinan } = useSelector((state: RootState) => state.perizinanSantri)
    const [showFormAddKamar, setShowFormAddKamar] = useState<boolean>(false)
    const [showFormAddPerizinan, setShowFormAddPerizinan] = useState<boolean>(false)
    const [showAlertKamar, setShowAlertKamar] = useState<boolean>(false)
    const [showAlertPerizinan, setShowAlertPerizinan] = useState<boolean>(false)
    const [showFormStatus, setShowFormStatus] = useState<boolean>(false)
    const [showAlertSantri, setShowAlertSantri] = useState<boolean>(false)
    const tambahKamar = (e: any) => {
        dispatch(addkamarSantri(e))
        setShowFormAddKamar(false)
        setShowAlertKamar(true)
    }
    const tambahPerizinan = (e: any) => {
        dispatch(addPerizinan(e))
        setShowFormAddPerizinan(false)
        setShowAlertPerizinan(true)
    }
    const saveStatus = (e: any) => {
        if (e.mondok === "iya") {
            e.mondok = true
        }
        else {
            e.mondok = false
        }
        dispatch(updateStatusMondokSantri(e))
        setShowFormStatus(false)
        setShowAlertSantri(true)
    }
    useEffect(() => {
        if (showAlertPerizinan) {
            const interval = setInterval(() => {
                setShowAlertPerizinan(false)
                navigate(`/home/perizinan/cetak/${uuid}`)
            }, 2000)
            return () => clearInterval(interval)
        }
    }, [showAlertPerizinan])
    useEffect(() => {
        if (showAlertKamar) {
            const interval = setInterval(() => {
                setShowAlertKamar(false)
            }, 2000)
            return () => clearInterval(interval)
        }
    }, [showAlertKamar])
    useEffect(() => {
        if (showAlertSantri) {
            const interval = setInterval(() => {
                setShowAlertSantri(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            dispatch(oneSantri(uuid))
            dispatch(allListPenjemputBySantri(uuid))
            dispatch(allListPerizinan())
            dispatch(oneStatusSantri(uuid))
        }
    }, [uuid, showAlertSantri])
    useEffect(() => {
        dispatch(allListKamarByGender(santriOne?.santri?.gender))
    }, [santriOne])
    useEffect(() => {
        setKamar(() =>
            listKamarAll.map((d: any) => {
                return { value: d.uuid, label: d.namaKamar }
            })
        )
    }, [listKamarAll])
    return (
        <div>
            <Loading show={loadingSantri || loadingKamarSantri || loadingPerizinan} />
            <Alert show={showAlertKamar} msg={msgKamarSantri} />
            <Alert show={showAlertPerizinan} msg={msgPerizinan} />
            <Alert show={showAlertSantri} msg={msgSantri} />
            <AddKamarSantri showForm={showFormAddKamar} closeForm={setShowFormAddKamar} submit={tambahKamar} data={santriOne} options_={kamar} />
            <AddPerizinan showForm={showFormAddPerizinan} closeForm={setShowFormAddPerizinan} submit={tambahPerizinan} uuid={uuid} />
            <EditStatusMondok showForm={showFormStatus} closeForm={setShowFormStatus} data={statusOne} submit={saveStatus} />
            <HeaderUi title="Panel Santri" />
            <Table>
                <div className=' p-4'>
                    <div className='border-b border-b-gray-300 pb-2'><h1 className='text-3xl font-semibold text-gray-700'>Informasi Santri</h1></div>
                    {Object.keys(santriOne).length !== 0 ?
                        <div>
                            <div className=' flex justify-between p-1 mt-4 text-gray-600'>
                                <h1 className='text-2xl'>Nuwb :</h1>
                                <h1 className='text-2xl'>{santriOne?.santri?.nuwb}</h1>
                            </div>
                            <div className=' flex justify-between p-1 mt-4 text-gray-600'>
                                <h1 className='text-2xl'>Nama :</h1>
                                <h1 className='text-2xl'>{santriOne?.santri.nama}</h1>
                            </div>
                            <div className=' flex justify-between p-1 mt-4 text-gray-600'>
                                <h1 className='text-2xl'>Lembaga :</h1>
                                <h1 className='text-2xl'>{santriOne?.santri?.lembaga?.nama}</h1>
                            </div>
                            <div className=' flex justify-between p-1 mt-4 text-gray-600'>
                                <h1 className='text-2xl'>Kelas :</h1>
                                <h1 className='text-2xl'>{santriOne?.santri.kelas}</h1>
                            </div>
                            <div className=' flex justify-between p-1 mt-4 mb-7 text-gray-600'>
                                <h1 className='text-2xl'>Ruang :</h1>
                                <h1 className='text-2xl'>{santriOne?.santri?.ruang}</h1>
                            </div>
                            <div className=' flex justify-between p-1 mt-4 mb-7 text-gray-600'>
                                <h1 className='text-2xl'>Kamar :</h1>
                                <h1 className='text-2xl'>{santriOne?.santri?.kamar_santri?.list_kamar?.namaKamar}</h1>
                            </div>
                            <div className=' flex justify-between p-1 mt-4 mb-7 text-gray-600'>
                                <h1 className='text-2xl'>No Wali :</h1>
                                <h1 className='text-2xl'>{`${santriOne?.wali?.no_wa !== undefined ? santriOne?.wali?.no_wa : ""}/${santriOne?.wali?.no_hp !== undefined ? santriOne?.wali?.no_hp : ""}`}</h1>
                            </div>
                            <div className='w-full bg-gray-400 h-[1px] mb-5'></div>
                            <div className='flex gap-5'>
                                {statusOne?.mondok &&
                                    <Button title="Perizinan" onClick={() => {
                                        setShowFormAddPerizinan(true)
                                    }} />
                                }
                                {statusOne?.mondok &&
                                    <Button title="Kamar" onClick={() => {
                                        setShowFormAddKamar(true)

                                    }} />
                                }
                                <Button title="Point" onClick={() => {
                                    navigate(`/home/panel/point/${santriOne?.santri.uuid}`)
                                }} />
                                <Button title="Prestasi" onClick={() => {
                                    navigate(`/home/panel/prestasi/${santriOne?.santri.uuid}`)
                                }} />
                                <Button title="List Penjemput" onClick={() => {
                                    navigate(`/home/panel/list-penjemput/${santriOne?.santri.uuid}`)
                                }} />
                                <Button title="Status Mondok" onClick={() => {
                                    setShowFormStatus(true)
                                }} />
                                <Button title="List Perizinan" onClick={() => {
                                    navigate(`/home/panel/list-perizinan/${santriOne?.santri.uuid}`)
                                }} />
                            </div>
                        </div>
                        :
                        <div></div>
                    }
                </div>
            </Table>
        </div>
    )
}

export default Panel