import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { HiEyeSlash, HiEye } from 'react-icons/hi2'
import InputForm from '../fields/InputForm'
import Button from '../others/Button'
import { useSelector } from 'react-redux'
import Dialog from '../../templates/Dialog'
import { FormAttributes } from './interfaces'

function EditUserSelf({closeForm,showForm,submit}:FormAttributes) {
    const [eye, setEye] = useState(false)
    const [eye2, setEye2] = useState(false)
    const { username, nama } = useSelector((state: any) => state.auth)
    const methodUser = useForm<any>({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                nama: yup.string().required("Tidak Boleh Kosong"),
                username: yup.string().required("Tidak Boleh Kosong"),
                new_pass: yup.string().min(8,"minimal 8 karakter"),
                old_pass: yup.string().when("new_pass", (new_pass, field) => new_pass ? field.required() : field.required()),
            })
        )
    })
    useEffect(() => {
        methodUser.reset({ username: username, nama: nama })
    }, [])

    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[90vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Formulir Edit User</h1>
                </div>
                <form onSubmit={methodUser.handleSubmit(submit)} className='px-6 py-7 space-y-1'>
                    <InputForm
                        label="Nama"
                        method={methodUser}
                        methodName="nama"
                    />
                    <InputForm
                        label="username"
                        method={methodUser}
                        methodName="username"
                    />
                    <InputForm
                        type={eye ? "text" : "password"}
                        label="Password Lama"
                        method={methodUser}
                        methodName="old_pass"
                        icon={eye ? <HiEye className='w-6 h-6 cursor-pointer absolute right-5 mt-3 ' onClick={() => setEye(!eye)} /> : <HiEyeSlash className='w-6 h-6 my-autocursor-pointer absolute right-5 mt-3' onClick={() => setEye(!eye)} />}
                    />
                    <InputForm
                        type={eye2 ? "text" : "password"}
                        label="Password Baru"
                        method={methodUser}
                        methodName="new_pass"
                        icon={eye2 ? <HiEye className='w-6 h-6 cursor-pointer absolute right-5 mt-3' onClick={() => setEye2(!eye2)} /> : <HiEyeSlash className='w-6 h-6 my-auto cursor-pointer absolute right-5 mt-3' onClick={() => setEye2(!eye2)} />}
                    />
                    <div className='flex justify-end pt-5'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default EditUserSelf