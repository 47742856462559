import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiDashboard } from "../../api/dashboard";
import { ErrorData } from "../interfaces";


export const allDasboardSantri = createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'santri/dashboard',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiDashboard.getDashboardSantri()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const allDasboardSantriByLem = createAsyncThunk<[],any,{rejectValue:ErrorData}>(
    'santri/dashboard/lembaga',
    async(gender:any,{rejectWithValue})=>{
        try{
            const res = await ApiDashboard.getDasboardSantriByLembaga(gender)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const allPerizinanByIzin = createAsyncThunk<[],string,{rejectValue:ErrorData}>(
    'perizinan/filter',
    async(id:string,{rejectWithValue})=>{
        try{
            const res = await ApiDashboard.getPerizinanByIzin(id)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const allDashboardListPerizinan = createAsyncThunk<[],any,{rejectValue:ErrorData}>(
    'perizinan/izin/filter',
    async(gender:any,{rejectWithValue})=>{
        try{
            const res = await ApiDashboard.getDashboardListPerizinan(gender)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const allMostManyPoint = createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'dashboard/many/point',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiDashboard.getMostManyPoint()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const allMostManyPrestasi = createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'dashboard/many/prestasi',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiDashboard.getMostManyPrestasi()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const allMostManyPerizinan = createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'dashboard/many/perizinan',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiDashboard.getMostManyPerizinan()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const allPerizinanByLembaga = createAsyncThunk<[],any,{rejectValue:ErrorData}>(
    'dashboard/perizinan/lembaga',
    async(kode_lembaga:string,{rejectWithValue})=>{
        try{
            const res =await ApiDashboard.getPerizinanByLembaga(kode_lembaga)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const getAllSantriInAsrama = createAsyncThunk<[],any,{rejectValue:ErrorData}>(
    'dashboard/perizinan/in-asrama',
    async(kode_lembaga:string,{rejectWithValue})=>{
        try{
            const res = await ApiDashboard.getSantriInAsrama(kode_lembaga)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)