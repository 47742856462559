import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { RootState, TypeDispatch, useAppDispatch } from '../store';
import { useSelector } from 'react-redux';
import { allDashboardListPerizinan } from '../store/actions/dashboard';
import HeaderUi from '../templates/HeaderUi';
import EmptyTable from '../templates/EmptyTable';
import Table from '../templates/Table';
import CardDashboard from '../templates/CardDashboard';
import Loading from '../templates/Loading';

function DashboardPerizinan() {
    const { gender } = useParams<any>()
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { perizinanByIzinAll, loadingDashboard } = useSelector((state: RootState) => state.dashboard)
    const [showRekapData, setShowRekapData] = useState<any>([])
    useEffect(() => {
        if (gender !== "") {
            dispatch(allDashboardListPerizinan(gender))
            // console.log(gender)
        }
    }, [gender])
    console.log(perizinanByIzinAll)
    return (
        <div>
            <Loading show={loadingDashboard} />
            <HeaderUi title="Dashboard Perizinan" />
            <Table>
                <h1 className='pb-4 text-xl font-semibold'>Rekap per lembaga</h1>
                {perizinanByIzinAll.length === 0 ?
                    <EmptyTable title="Tidak ada list" />
                    :
                    <div className='grid md:grid-cols-4 grid-cols-1 w-full gap-5'>
                        {perizinanByIzinAll?.filterIzinByLembaga.map((d: any, id: number) => (
                            <Link key={id} to={`/home/dashboard/perizinan/lembaga/${d.kode_lembaga}`}>
                                <CardDashboard key={id} title={d?.nama} value={d?.santris?.length} />
                            </Link>
                        ))}
                    </div>
                }
            </Table>
            <Table>
                <h1 className='pb-4 text-xl font-semibold'>Rekap per list izin</h1>
                {perizinanByIzinAll.length === 0 ?
                    <EmptyTable title="Tidak ada list" />
                    :
                    <div className='grid md:grid-cols-4 grid-cols-1 w-full gap-5'>
                        {perizinanByIzinAll?.filterIzinByIzin.map((d: any, id: number) => (
                            <CardDashboard key={id} title={d?.nama} value={d?.perizinans?.length} />
                        ))}
                    </div>
                }
            </Table>
        </div>
    )
}

export default DashboardPerizinan