import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiPoint } from "../../api/point";
import { ErrorData } from "../interfaces";

export const allListPoint = createAsyncThunk<[], void, { rejectValue: ErrorData }>(
    'list/point/get',
    async (_, { rejectWithValue }) => {
        try {
            const res: any = await ApiPoint.getList()
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err: any) {
            return rejectWithValue({ message: err.response.data.msg })
        }
    }
)
export const allListPointByUuid = createAsyncThunk<{},string,{rejectValue:ErrorData}>(
    'list/point/get/uuid',
    async(uuid:string,{rejectWithValue})=>{
        try{
            const res : any =  await ApiPoint.getListByUuid(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const allListPointByKey = createAsyncThunk<[],string,{rejectValue:ErrorData}>(
    'list/point/get/key',
    async(key:string,{rejectWithValue})=>{
        try{
            const res : any = await ApiPoint.getListByKey(key)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const addListPoint = createAsyncThunk<any, any, { rejectValue: ErrorData }>(
    'list/point/post',
    async (data: any, { rejectWithValue }) => {
        try {
            const res: any = await ApiPoint.postList(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err: any) {
            return rejectWithValue({ message: err.response.data.msg })
        }
    }
)
export const editListPoint = createAsyncThunk<any, any, { rejectValue: ErrorData }>(
    'list/point/update',
    async (data: any, { rejectWithValue }) => {
        try {
            const res: any = await ApiPoint.updateList(data.uuid, data.update)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err: any) {
            return rejectWithValue({ message: err.response.data.msg })
        }
    }
)
export const dropListPoint = createAsyncThunk<any, string, { rejectValue: ErrorData }>(
    'list/point/delete',
    async (uuid: string, { rejectWithValue }) => {
        try {
            const res: any = await ApiPoint.deleteList(uuid)
            if (res.status === 200) {
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)