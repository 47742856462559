import React from 'react'
import { useLocation } from 'react-router-dom'

interface HeaderUiAttributes{
    location_?:string;
    title:string;
}

function HeaderUi({title,location_}:HeaderUiAttributes) {
    const getLocation = useLocation()
    const location = getLocation.pathname.replace("/","")
    
    return (
        <div className=''>
            <header className='shadow-md bg-white w-full px-6 py-4 flex flex-col justify-between h-32'>
                <h1 className='font-light text-lg tracking-widest'>{location}{location_}</h1>
                <h1 className='font-normal text-3xl mb-1'>{title}</h1>
            </header>
        </div>
    )
}

export default HeaderUi