import React, { useEffect, useState } from 'react'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import Selector from '../component/fields/Selector'
import Button from '../component/others/Button'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { addPrestasiSantri, allPrestasiBySantrinThn, dropPrestasiSantri, generatePdfPrestasiSantri, updatePrestasiSantri } from '../store/actions/prestasiSantri'
import { listPrestasiActions } from '../store/slice/listPrestasi'
import { allThnAjar } from '../store/actions/options'
import { allListPrestasi } from '../store/actions/listPrestasi'
import { oneSantri } from '../store/actions/santri'
import { HiOutlinePencilSquare, HiOutlineTrash } from 'react-icons/hi2'
import { FaPeopleArrows } from 'react-icons/fa'
import AddPrestasiSantri from '../component/form/AddPrestasiSantri'
import Loading from '../templates/Loading'
import Alert from '../templates/Alert'
import EditPrestasiSantri from '../component/form/EditPrestasiSantri'
import DeletePrestasiByUser from '../component/form/DeletePrestasiByUser'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'
import { prestasiSantriActions } from '../store/slice/prestasiSantri'
import { formatDatePdf, formatWithoutDay } from '../helper/formatTime'

function PrestasiSantri() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const params = useParams()
    const { santriOne } = useSelector((state: RootState) => state.santri)
    const { tahunAjar } = useSelector((state: RootState) => state.options)
    const { listPrestasiAll } = useSelector((state: RootState) => state.listPrestasi)
    const { prestasiSantriAll, loadingPrestasiSantri, msgPrestasiSantri,succesPdfPrestasi } = useSelector((state: RootState) => state.prestasiSantri)
    const [showFormAddPrestasi, setShowFormAddPrestasi] = useState<boolean>(false)
    const [showFormEditPrestasi, setShowFormEditPrestasi] = useState<boolean>(false)
    const [showFormDeletePrestasi, setShowFormDeletePrestasi] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [saveOne, setSaveOne] = useState<any>({})
    const [totalPrestasi, setTotalPrestasi] = useState<number>(0)
    const [tahun, setTahun] = useState<any>({})
    const [tableHead,setTableHead] = useState(["No","Tanggal","Prestasi","Jumlah","Deskripsi","Edit","Delete"])
    const tahunOpt = tahunAjar.map((d: any) => {
        return { value: d.tahun, label: d.tahun }
    })
    const prestasiOpt = listPrestasiAll.map((d: any) => {
        return { value: d.uuid, label: d.judul }
    })
    const tambah = (e: any) => {
        dispatch(addPrestasiSantri(e))
        setShowFormAddPrestasi(false)
        setShowAlert(true)
    }
    const edit = (e: any) => {
        dispatch(updatePrestasiSantri({ uuid: saveOne.uuid, update: e }))
        setShowFormEditPrestasi(false)
        setShowAlert(true)
    }
    const hapus = (e: any) => {
        dispatch(dropPrestasiSantri(e))
        setShowFormDeletePrestasi(false)
        setShowAlert(true)
    }
    useEffect(() => {
        if (showAlert) {
            const interval = setInterval(() => {
                setShowAlert(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            if (Object.keys(tahun).length !== 0) {
                dispatch(allPrestasiBySantrinThn({ uuid: params.uuid, thn: tahun?.value?.replace("/", "-") }))
            }
        }
    }, [showAlert, params, tahun])
    useEffect(()=>{
        if(Object.keys(tahunAjar).length !== 0){
            tahunAjar.map((d:any)=>{
                if(d.active){
                    setTahun({value:d.tahun,label:d.tahun})
                }
            })
        }
    },[tahunAjar])
    useEffect(() => {
        setTotalPrestasi(0)
        if (Object.keys(prestasiSantriAll).length !== 0) {
            let total_: number = 0
            prestasiSantriAll.map((d: any) => {
                total_ += parseInt(d.prestasi?.jumlahPoint)
            })
            setTotalPrestasi(total_)
        }
    }, [prestasiSantriAll])
    useEffect(() => {
        dispatch(listPrestasiActions.clearPrestasi())
        dispatch(allThnAjar())
        dispatch(allListPrestasi())
        dispatch(oneSantri(params?.uuid))
    }, [params])
    useEffect(() => {
        if (succesPdfPrestasi) {
            const date = new Date()
            window.open(`https://upload-ppdb.ponpesabuhurairah.id/perizinan/PRESTASI_${santriOne?.santri?.nuwb}_${formatDatePdf(date)}.pdf`)
            dispatch(prestasiSantriActions.clearPdf())
        }
    }, [succesPdfPrestasi])

    return (
        <div>
            <Loading show={loadingPrestasiSantri} />
            <Alert show={showAlert} msg={msgPrestasiSantri} />
            <AddPrestasiSantri showForm={showFormAddPrestasi} closeForm={setShowFormAddPrestasi} submit={tambah} uuid={params?.uuid} prestasiOpt={prestasiOpt} thnOpt={tahunOpt} />
            <EditPrestasiSantri showForm={showFormEditPrestasi} closeForm={setShowFormEditPrestasi} submit={edit} data={saveOne} prestasiOpt={prestasiOpt} thnOpt={tahunOpt} />
            <DeletePrestasiByUser showForm={showFormDeletePrestasi} closeForm={setShowFormDeletePrestasi} submit={hapus} data={saveOne} />
            <HeaderUi title="Prestasi Santri" />
            <Table>
                <div className='flex justify-between w-full'>
                    <div className='w-72'>
                        <Selector title="Tahun Ajar" value={tahun} onChange={(e: any) => setTahun(e)} options={tahunOpt} />
                    </div>
                    <div className='mt-6 space-x-5'>
                        <Button type="button" title="Tambah" onClick={() => setShowFormAddPrestasi(true)} />
                        <Button type="button" title="Download PDF" onClick={() => dispatch(generatePdfPrestasiSantri({nuwb:santriOne?.santri?.nuwb,thn_ajar:tahun?.value}))} />
                    </div>
                </div>
            </Table>
            <Table>
                <div>
                    <div className='flex'>
                        {Object.keys(santriOne).length !== 0 &&
                            <div className='grid grid-cols-4 gap-4'>
                                <div>
                                    <div className='flex space-x-1 text-lg font-light pr-2'>
                                        <h1 className='font-normal'>Nama</h1>
                                        <h1>:</h1>
                                        <h1>{santriOne?.santri?.nama}</h1>
                                    </div>
                                    <div className='w-full h-[2px] bg-gray-400 mt-2'></div>
                                </div>
                                <div>
                                    <div className='flex space-x-1 text-lg font-light pr-3'>
                                        <h1 className='font-normal'>Lembaga</h1>
                                        <h1>:</h1>
                                        <h1>{santriOne?.santri?.lembaga?.nama}</h1>
                                    </div>
                                    <div className='w-full h-[2px] bg-gray-400 mt-2'></div>
                                </div>
                                <div>
                                    <div className='flex space-x-1 text-lg font-light pr-2'>
                                        <h1 className='font-normal'>Kelas</h1>
                                        <h1>:</h1>
                                        <h1>{santriOne?.santri?.kelas}</h1>
                                    </div>
                                    <div className='w-full h-[2px] bg-gray-400 mt-2'></div>
                                </div>
                                <div>
                                    <div className='flex space-x-1 text-lg font-light pr-2'>
                                        <h1 className='font-normal'>Ruang</h1>
                                        <h1>:</h1>
                                        <h1>{santriOne?.santri?.ruang}</h1>
                                    </div>
                                    <div className='w-full h-[2px] bg-gray-400 mt-2'></div>
                                </div>
                                <h1 className='mx-2 text-blue-700 text-lg'>Jumlah Prestasi : {totalPrestasi}</h1>
                            </div>
                        }
                    </div>
                    {Object.keys(prestasiSantriAll).length === 0 ?
                    <EmptyTable title='Tidak ada prestasi'/>
                        :
                    <TableFields head={tableHead}>
                                    {prestasiSantriAll.map((d: any, id: any) => (
                                        <tr className={`bg-white border-b hover:bg-gray-100 overflow-y-auto ${d.canceledPrestasi === null ? "text-gray-900" : "text-red-600"}`} key={id}>
                                            <td className='px-6 py-3'>{id + 1}</td>
                                            <td className='px-6 py-3'>{d.tanggal_kejadian ? formatWithoutDay(d.tanggal_kejadian):"-"}</td>
                                            <td className='px-6 py-3'>{d.prestasi?.judul}</td>
                                            <td className='px-6 py-3'>{d.prestasi?.jumlahPoint}</td>
                                            <td className='px-6 py-3'>{d.deskripsi}</td>
                                            <td className='px-6 py-3'><HiOutlinePencilSquare className={`w-6 h-6  cursor-pointer`} onClick={() => {
                                                if (d.canceledPrestasi === null) {
                                                    setSaveOne(d)
                                                    setShowFormEditPrestasi(true)
                                                }
                                            }} /></td>
                                            <td className='px-12 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                                if (d.canceledPrestasi === null) {
                                                    setSaveOne(d)
                                                    setShowFormDeletePrestasi(true)
                                                }
                                            }} /></td>
                                        </tr>
                                    ))}
                    </TableFields>
                    }
                </div>
            </Table>
        </div>
    )
}

export default PrestasiSantri