import React from 'react'
import { Controller } from 'react-hook-form'

interface RadioFormAttributes{
    method:any;
    methodName:string;
    onChange_?:()=>void;
    id?:string;
    value_:string;
    label:string;
}

function RadioForm({id,label,method,methodName,value_,onChange_}:RadioFormAttributes) {
  return (
    <div>
    <div className='flex w-full md:px-2'>
        <Controller
            control={method.control}
            name={methodName}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
            }) => {
                return (
                < input
                    onChange={(e) =>{
                        onChange(e.target.value)
                        if(onChange_){
                            onChange(e.target.value)
                        }
                    }} 
                    type="radio"
                    className={`w-4 h-4 mt-1`}
                    id={id} 
                    value={value_}
                    checked={value === value_}
                    ref={ref}
                />
             )}}

        />
        < label className='ml-2 text-md font-light'>{label}</label>
    </div>
</div>  )
}

export default RadioForm