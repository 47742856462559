import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import Button from '../others/Button'
import TextAreaForm from '../fields/TextAreaForm'
import { FormAttributes } from './interfaces'

function DeletePrestasiByUser({ closeForm, showForm, submit, data }: FormAttributes) {
    const methodDeletePrestasi = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                catatan: yup.string().required("tidak boleh kosong"),
                uuidPrestasiSantri: yup.string().required("tidak boleh kosong"),
            })
        )
    })
    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            methodDeletePrestasi.reset({ uuidPrestasiSantri: data?.uuid, catatan: "" })
        }
    }, [showForm, data])
    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Hapus Prestasi Santri</h1>
                </div>
                <form onSubmit={methodDeletePrestasi.handleSubmit(submit)} className='px-6 py-7 space-y-1'>
                    <TextAreaForm label="Catatan" method={methodDeletePrestasi} methodName="catatan" />
                    <div className='flex justify-end pt-3'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>)
}

export default DeletePrestasiByUser