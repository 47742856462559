import { createSlice } from "@reduxjs/toolkit";
import { oneSantri, oneStatusSantri, searchSantri, updateStatusMondokSantri } from "../actions/santri";
import { Santri } from "../interfaces";

const initialState : Santri = {
    santriAll: [],
    statusOne : {},
    santriOne: {},
    loadingSantri: false,
    msgSantri: "",
}

export const santriStore = createSlice({
    name: "santri",
    initialState,
    reducers: {
        clearSantri: (state) => {
            state.santriAll = []
            state.msgSantri = ""
        },
        clearOne : (state)=>{
            state.santriOne = {}
            state.msgSantri = ""
        }
    },
    extraReducers: (builder): void => {
        builder
            .addCase(searchSantri.pending, (state) => {
                state.loadingSantri = true
            })
            .addCase(searchSantri.fulfilled, (state, action) => {
                state.loadingSantri = false
                state.santriAll = action.payload
            })
            .addCase(searchSantri.rejected, (state, action) => {
                state.loadingSantri = false
                state.msgSantri = action.payload
            })
            .addCase(oneSantri.pending, (state) => {
                state.loadingSantri = true
            })
            .addCase(oneSantri.fulfilled, (state, action) => {
                state.loadingSantri = false
                state.santriOne = action.payload
            })
            .addCase(oneSantri.rejected, (state, action) => {
                state.loadingSantri = false
                state.msgSantri = action.payload
            })
            .addCase(oneStatusSantri.pending, (state) => {
                state.loadingSantri = true
            })
            .addCase(oneStatusSantri.fulfilled, (state, action) => {
                state.loadingSantri = false
                state.statusOne = action.payload
            })
            .addCase(oneStatusSantri.rejected, (state, action) => {
                state.loadingSantri = false
                state.msgSantri = action.payload
            })
            .addCase(updateStatusMondokSantri.pending, (state) => {
                state.loadingSantri = true
            })
            .addCase(updateStatusMondokSantri.fulfilled, (state, action) => {
                state.loadingSantri = false
                state.msgSantri = action.payload?.msg
            })
            .addCase(updateStatusMondokSantri.rejected, (state, action) => {
                state.loadingSantri = false
                state.msgSantri = action.payload?.message
            });

    }
})
export const santriActions = santriStore.actions
export default santriStore.reducer;