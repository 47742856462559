import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../component/fields/Input'
import { allPerizinan, allPerizinanByKey, dropPerizinan, editPerizinan, editStatusPerizinan } from '../store/actions/perizinan'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import { TiArrowBackOutline } from "react-icons/ti"
import { perizinanSantriActions } from '../store/slice/perizinan'
import Loading from '../templates/Loading'
import DialogDelete from '../component/others/DialogDelete'
import Alert from '../templates/Alert'
import { allLembaga } from '../store/actions/options'
import { HiOutlinePencilSquare, HiOutlineTrash } from 'react-icons/hi2'
import EditPerizinan from '../component/form/EditPerizinan'
import { TfiPanel } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { AiOutlineIdcard } from 'react-icons/ai'
import { allListPerizinan } from '../store/actions/listIzin'
import { allListPenjemputBySantri } from '../store/actions/listPenjemput'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'
import { formatDay } from '../helper/formatTime'

function PerizinanSantri() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const navigate = useNavigate()
    const { perizinanAll, msgPerizinan, loadingPerizinan } = useSelector((state: RootState) => state.perizinanSantri)
    const [showCheckStatus, setShowCheckStatus] = useState<boolean>(false)
    const [showAlertStatus, setShowAlertStatus] = useState<boolean>(false)
    const [showFormEditPerizinan, setShowFormEditPerizinan] = useState<boolean>(false)
    const [showFormDeletePerizinan, setShowFormDeletePerizinan] = useState<boolean>(false)
    const [key, setKey] = useState("")
    const { lembagaAll } = useSelector((state: any) => state.options)
    const [tableHead, setTableHead] = useState(["No", "Nuwb", "Nama", "Dari", "Sampai", "Penjemput", "No Hp", "Keterangan", "Edit", "Ubah Status", "Panel", "Kartu", "Hapus"])
    const [saveUuid, setSaveUuid] = useState<string>("")
    const [saveOne, setSaveOne] = useState<any>({})
    const lembagaOpt: any = lembagaAll.map((d: any) => {
        return { value: d.kode_lembaga, label: d.nama }
    })
    const ubahStatus = () => {
        dispatch(editStatusPerizinan(saveUuid))
        setShowCheckStatus(false)
        setShowAlertStatus(true)
    }
    const edit = (e: any) => {
        dispatch(editPerizinan({ uuid: saveOne?.uuid, update: e }))
        setShowFormEditPerizinan(false)
        setShowAlertStatus(true)
    }
    const hapusPerizinan = () => {
        dispatch(dropPerizinan(saveOne?.uuid))
        setShowFormDeletePerizinan(false)
        setShowAlertStatus(true)
    }
    useEffect(() => {
        if (showAlertStatus) {
            const interval = setInterval(() => {
                setShowAlertStatus(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            if (key.length > 2) {
                dispatch(allPerizinanByKey(key))
            }
            else {
                dispatch(allPerizinan())
            }
        }
    }, [showAlertStatus, key])
    useEffect(() => {
        dispatch(allListPerizinan())
        dispatch(allLembaga())
        dispatch(perizinanSantriActions.clearPerizinanSantri())
    }, [])
    useEffect(() => {
        if (Object.keys(saveOne).length !== 0) {
            dispatch(allListPenjemputBySantri(saveOne?.uuid_santri))
        }
    }, [saveOne])
    return (
        <div>
            <Loading show={loadingPerizinan} />
            <Alert show={showAlertStatus} msg={msgPerizinan} />
            <EditPerizinan showForm={showFormEditPerizinan} closeForm={setShowFormEditPerizinan} submit={edit} data={saveOne} />
            <DialogDelete msg="Apakah santri sudah kembali?" show={showCheckStatus} close={setShowCheckStatus} onClick={ubahStatus} />
            <DialogDelete msg="Yakin mau menghapus perizinan?" show={showFormDeletePerizinan} close={setShowFormDeletePerizinan} onClick={hapusPerizinan} />
            <HeaderUi title="Perizinan santri" />
            <Table>
                <div className='w-72'>
                    <Input label="Masukkan nama" value={key} onChange={(e: any) => setKey(e.target.value)} />
                </div>
            </Table>
            <Table>
                {Object.keys(perizinanAll).length === 0 ?
                    <EmptyTable title="Tidak ada santri yang izin" />
                    :
                    <TableFields head={tableHead}>
                        {perizinanAll.map((d: any, id: any) => (
                            <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                <td className='px-6 py-3'>{id + 1}</td>
                                <td className='px-6 py-3'>{d.santri?.nuwb}</td>
                                <td className='px-6 py-3'>{d.santri?.nama}</td>
                                <td className='px-6 py-3'>{d.dari ? formatDay(d.dari) : "-"}</td>
                                <td className='px-6 py-3'>{d.sampai ? formatDay(d.sampai) : "-"}</td>
                                <td className='px-6 py-3'>{d.listPenjemput?.nama}</td>
                                <td className='px-6 py-3'>{d.listPenjemput?.no_telpon}</td>
                                <td className='px-6 py-3'>{d.keterangan}</td>
                                <td className='px-6 py-3'><HiOutlinePencilSquare className='w-6 h-6 cursor-pointer mx-2' onClick={() => {
                                    setSaveOne(d)
                                    setShowFormEditPerizinan(true)
                                }} /></td>
                                <td className='px-6 py-3'><TiArrowBackOutline className='w-6 h-6 cursor-pointer mx-2' onClick={() => {
                                    setSaveUuid(d.uuid)
                                    setShowCheckStatus(true)
                                }} /></td>
                                <td className='px-6 py-3'><TfiPanel className='w-6 h-6 cursor-pointer mx-2' onClick={() => navigate(`/home/panel/${d.santri?.uuid}`)} /></td>
                                <td className='px-6 py-3'><AiOutlineIdcard className='w-6 h-6 cursor-pointer mx-2' onClick={() => navigate(`/home/perizinan/cetak/${d.santri?.uuid}`)} /></td>
                                <td className='px-6 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-2' onClick={() => {
                                    setSaveOne(d)
                                    setShowFormDeletePerizinan(true)
                                }} /></td>
                            </tr>
                        ))}
                    </TableFields>
                }
            </Table>
        </div>
    )
}

export default PerizinanSantri