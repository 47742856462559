import React, { ReactNode } from 'react'

interface TableAttributes{
  children:ReactNode
}

function Table({children}:TableAttributes) {
  return (
    <div>
        <div className='bg-white shadow-xl md:my-10 my-5 mx-5 p-4 '>
            {children}
        </div>
    </div>
  )
}

export default Table