import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { allPointSantri } from '../store/actions/pointSantri'
import { pointSantriAction } from '../store/slice/PointSantri'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import { CiCalculator2 } from "react-icons/ci"
import AddAkumulasi from '../component/form/AddAkumulasi'
import { addAkumulasiPoint } from '../store/actions/akumulasiPoint'
import Alert from '../templates/Alert'
import Button from '../component/others/Button'
import { useNavigate } from 'react-router-dom'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'
import { formatDayNoTime } from '../helper/formatTime'
import Loading from '../templates/Loading'

function AkumulasiSucces() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const navigate = useNavigate()
    const { pointSantriAll,loadingPointSantri } = useSelector((state: RootState) => state.pointSantri)
    const { msgAkumulasi } = useSelector((state: RootState) => state.akumulasiPoint)
    const [showFormAddAkumulasi, setShowFormAddAkumulasi] = useState<boolean>(false)
    const [showAlertAkumulasi, setShowAlertAkumulasi] = useState<boolean>(false)
    const [tableHead, setTableHead] = useState(["No", "Tahun Ajar", "Lembaga", "Nama", "Kelas", "Ruang", "Tanggal", "Ket", "Jumlah Point", "Pembinaan", "Status", "Akumulasi", "Akumulasikan"])
    const [saveOne, setSaveOne] = useState<any>({})
    const saveAkumulasi = (e: {}) => {
        dispatch(addAkumulasiPoint({ uuid: saveOne.uuid, post: e }))
        setShowFormAddAkumulasi(false)
        setShowAlertAkumulasi(true)
    }
    useEffect(() => {
        dispatch(pointSantriAction.clearPointSantri())
    }, [])
    useEffect(() => {
        if (showAlertAkumulasi) {
            const interval = setInterval(() => {
                setShowAlertAkumulasi(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            dispatch(allPointSantri("1"))
        }
    }, [showAlertAkumulasi])
    return (
        <div>
            <Loading show={loadingPointSantri}/>
            <Alert show={showAlertAkumulasi} msg={msgAkumulasi} />
            <AddAkumulasi showForm={showFormAddAkumulasi} closeForm={setShowFormAddAkumulasi} data={saveOne} submit={saveAkumulasi} />
            <HeaderUi title="Point santri (Approve)" />
            <Table>
                <div>
                    {Object.keys(pointSantriAll).length === 0 ?
                        <EmptyTable title="Tidak ada point" />
                        :
                        <TableFields head={tableHead}>
                            {pointSantriAll.map((d: any, id: any) => (
                                <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                    <td className='px-6 py-3'>{id + 1}</td>
                                    <td className='px-6 py-3'>{d.thn_ajar}</td>
                                    <td className='px-6 py-3'>{d.santri?.lembaga?.nama}</td>
                                    <td className='px-6 py-3'>{d.santri?.nama}</td>
                                    <td className='px-6 py-3'>{d.santri.kelas}</td>
                                    <td className='px-6 py-3'>{d.santri.ruang}</td>
                                    <td className='px-6 py-3'>{d.tanggal_kejadian ? formatDayNoTime(d.tanggal_kejadian) : "-"}</td>
                                    <td className='px-6 py-3'>{d.list_point?.judul}</td>
                                    <td className='px-6 py-3'>{d.list_point?.jumlahPoint}</td>
                                    <td className='px-6 py-3'>{d.pembinaan?.deskripsi}</td>
                                    <td className='px-6 py-3'>{d.status ? "Ditampilkan" : "Tidak ditampilkan"}</td>
                                    <td className='px-6 py-3'>{d.akumulasi ? "Diakumulasikan" : "Tidak diakumulasikan"}</td>
                                    <td className='px-6 py-3'><CiCalculator2 className='w-8 h-8 mx-10 cursor-pointer' onClick={() => {
                                        setSaveOne(d)
                                        setShowFormAddAkumulasi(true)
                                    }} /></td>
                                </tr>
                            ))}

                        </TableFields>
                    }
                </div>
            </Table>
        </div>)
}

export default AkumulasiSucces