import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAuth } from "../../api/auth";
import { ErrorData } from "../interfaces";


export const isLogin =  createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'login/user',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiAuth.login(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const isLogout = createAsyncThunk<any,void,{rejectValue:ErrorData}>(
    'logout/user',
    async(_,{rejectWithValue})=>{
        try{
            const res : any = await ApiAuth.logout()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)