import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiPrestasi } from "../../api/prestasi";
import { ErrorData } from "../interfaces";
import { ListPrestasiAttributes } from "../../type";


export const allListPrestasi = createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'list-prestasi/get',
    async(_,{rejectWithValue})=>{
        try{
            const res : any = await ApiPrestasi.getList()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const allListPrestasiByUuid = createAsyncThunk<[],string,{rejectValue:ErrorData}>(
    'list-prestasi/get/uuid',
    async(uuid:string,{rejectWithValue})=>{
        try{
            const res : any = await ApiPrestasi.getListByUuid(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const allListPrestasiByKey = createAsyncThunk<[],string,{rejectValue:ErrorData}>(
    'list-prestasi/get/key',
    async(key:string,{rejectWithValue})=>{
        try{
            const res : any = await ApiPrestasi.getListByKey(key)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const addListPrestasi =  createAsyncThunk<any,ListPrestasiAttributes,{rejectValue:ErrorData}>(
    'list-prestasi/add',
    async(data:ListPrestasiAttributes,{rejectWithValue})=>{
        try{
            const res : any = await ApiPrestasi.postList(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const editListPrestasi = createAsyncThunk<any,{},{rejectValue:ErrorData}>(
    'list-prestasi/update',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiPrestasi.updateList(data.uuid,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const dropListPrestasi = createAsyncThunk<any,string,{rejectValue:ErrorData}>(
    'list-prestasi/drop',
    async(uuid:string,{rejectWithValue})=>{
        try{
            const res = await ApiPrestasi.deleteList(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)