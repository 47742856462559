import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Sidebar from './templates/Sidebar';
import AkumulasiPointAll from './UI/AkumulasiPointAll';
import AkumulasiPoint from './UI/AkumulasiPoint';
import AkumulasiPointSucces from './UI/AkumulasiPointSucces';
import CanceledPoint from './UI/CanceledPoint';
import Dashboard from './UI/Dashboard';
import Kamarsantri from './UI/Kamarsantri';
import ListKamar from './UI/ListKamar';
import ListPoint from './UI/ListPoint';
import ListUser from './UI/ListUser';
import Login from './UI/Login';
import Panel from './UI/Panel';
import PerizinanSantri from './UI/PerizinanSantri';
import PointSantri from './UI/PointSantri';
import Santri from './UI/Santri';
import ListPrestasi from './UI/ListPrestasi';
import PrestasiSantri from './UI/PrestasiSantri';
import CanceledPrestasi from './UI/CanceledPrestasi';
import AkumulasiPrestasiAll from './UI/AkumulasiPrestasiAll';
import AkumulasiPrestasiSucces from './UI/AkumulasiPrestasiSucces';
import ListPenjemput from './UI/ListPenjemput';
import ListIzin from './UI/ListIzin';
import CetakPerizinan from './UI/CetakPerizinan';
import DashboardPerizinan from './UI/DashboardPerizinan';
import PerizinanOneSantri from './UI/PerizinanOneSantri';
import DashboardSantriByLembaga from './UI/DashboardSantriByLembaga';
import DashboardPerizinanByLembaga from './UI/DashboardPerizinanByLembaga';
import CetakPointIndividu from './UI/CetakPointIndividu';
import DashboardSantriInAsrama from './UI/DashboardSantriInAsrama';
import RekapPointLembaga from './UI/RekapPointLembaga';
import AbsensiSantri from './UI/AbsensiSantri';
import PointAbsensi from './UI/PointAbsensi';
import ReportPoint from './UI/ReportPoint';

function App() {
  const { token, super_admin, generalUser } = useSelector((state: any) => state.auth)
  return (
    <div>
      <Routes>
        <Route path='/' element={token !== "" ? <Navigate to={"/home/santri"} replace /> : <Login />} />
        <Route path='/home' element={token !== "" ? <Sidebar /> : <Navigate to={"/"} replace />}>
          <Route path='/home/dashboard' element={<Dashboard />} />
          <Route path='/home/dashboard/perizinan/:gender' element={<DashboardPerizinan />} />
          <Route path='/home/dashboard/santri/:gender' element={<DashboardSantriByLembaga />} />
          <Route path='/home/dashboard/perizinan/lembaga/:kode_lembaga' element={<DashboardPerizinanByLembaga />} />
          <Route path='/home/dashboard/perizinan/in-asrama/:kode_lembaga' element={<DashboardSantriInAsrama />} />
          <Route path='/home/report/point' element={<ReportPoint />} />
          <Route path='/home/santri' element={<Santri />} />
          <Route path='/home/kamar' element={<Kamarsantri />} />
          <Route path='/home/absensi' element={<AbsensiSantri/>}/>
          <Route path='/home/point' element={<AkumulasiPoint />} />
          <Route path='/home/perizinan' element={<PerizinanSantri />} />
          <Route path='/home/panel/:uuid' element={<Panel />} />
          <Route path='/home/perizinan/cetak/:uuid' element={<CetakPerizinan />} />
          <Route path='/home/panel/point/:uuid' element={<PointSantri />} />
          <Route path='/home/point/cetak/:uuid/:tahun' element={<CetakPointIndividu/>} />
          <Route path='/home/panel/prestasi/:uuid' element={<PrestasiSantri />} />
          <Route path='/home/panel/list-penjemput/:uuid' element={<ListPenjemput />} />
          <Route path='/home/panel/list-perizinan/:uuid' element={<PerizinanOneSantri />} />
          <Route path='/home/point/all' element={super_admin || generalUser ? <AkumulasiPointAll /> : <Navigate to={"/home/santri"} replace />} />
          <Route path='/home/prestasi/all' element={super_admin || generalUser ? <AkumulasiPrestasiAll /> : <Navigate to={"/home/santri"} replace />} />
          <Route path='/home/rekap/lembaga' element={super_admin || generalUser ? <RekapPointLembaga /> : <Navigate to={"/home/santri"} replace />} />
          <Route path='/home/laporan/point' element={super_admin || generalUser ? <CanceledPoint /> : <Navigate to={"/home/santri"} replace />} />
          <Route path='/home/laporan/prestasi' element={super_admin || generalUser ? <CanceledPrestasi /> : <Navigate to={"/home/santri"} replace />} />
          <Route path='/home/point/succes' element={super_admin || generalUser ? <AkumulasiPointSucces /> : <Navigate to={"/home/santri"} replace />} />
          <Route path='/home/prestasi/succes' element={super_admin || generalUser ? <AkumulasiPrestasiSucces /> : <Navigate to={"/home/santri"} replace />} />
          <Route path='/home/list/izin' element={super_admin || generalUser ? <ListIzin /> : <Navigate to={"/home/santri"} replace />} />
          <Route path='/home/list/kamar' element={super_admin || generalUser ? <ListKamar /> : <Navigate to={"/home/santri"} replace />} />
          <Route path='/home/list/point' element={super_admin || generalUser ? <ListPoint /> : <Navigate to={"/home/santri"} replace />} />
          <Route path='/home/point/absensi' element={super_admin || generalUser ? <PointAbsensi /> : <Navigate to={"/home/santri"} replace />} />
          <Route path='/home/list/prestasi' element={super_admin || generalUser ? <ListPrestasi /> : <Navigate to={"/home/santri"} replace />} />
          <Route path='/home/list/user' element={super_admin || generalUser ? <ListUser /> : <Navigate to={"/home/santri"} replace />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
