import { EditSistemAttributes, EditUserAttributes, EditUserSelfAttributes, SistemAttributes, UserAttributes } from "../type";
import api from "./http";

export const ApiUser = {
    get : (nama_sistem:string) =>{
        return api.get(`/user/${nama_sistem}`)
    },
    post : (data:UserAttributes) =>{
        return api.post(`/user`,data)
    },
    updateSelf : (data:EditUserSelfAttributes) =>{
        return api.put(`/user`,data)
    },
    update : (uuid:string,data:EditUserAttributes) =>{
        return api.put(`/user/${uuid}`,data)
    },
    delete : (uuid:string) =>{
        return api.delete(`/user/${uuid}`)
    },
    postSistem : (uuid:string,data:SistemAttributes)=>{
        return api.post(`/user/sistem/${uuid}`,data)
    },
    deleteSistem : (uuid:string,uuid_sis:string)=>{
        return api.delete(`/user/sistem/${uuid}/${uuid_sis}`)
    },
    updateSistem : (uuid:string,uuid_sistem:string,data:EditSistemAttributes) =>{
        return api.put(`/user/sistem/${uuid}/${uuid_sistem}`,data)
    },
}