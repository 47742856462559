import React, { useEffect, useState } from 'react'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import Button from '../component/others/Button'
import AddListIzin from '../component/form/AddListIzin'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import { HiOutlinePencilSquare, HiOutlineTrash } from 'react-icons/hi2'
import { addListPerizinan, allListPerizinan, dropListPerizinan, updateListPerizinan } from '../store/actions/listIzin'
import { listIzinActions } from '../store/slice/listIzin'
import Loading from '../templates/Loading'
import Alert from '../templates/Alert'
import EditListIzin from '../component/form/EditListIzin'
import DialogDelete from '../component/others/DialogDelete'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'

function ListIzin() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { listIzinAll, loadingListIzin, msgListIzin } = useSelector((state: RootState) => state.listIzin)
    const [showFormAddListIzin, setShowFormAddListIzin] = useState<boolean>(false)
    const [showFormEditListIzin, setShowFormEditListIzin] = useState<boolean>(false)
    const [showFormDeleteListIzin, setShowFormDeleteListIzin] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [tableHead, setTableHead] = useState(["No", "Nama Izin", "Edit", "Delete"])
    const [saveOne, setSaveOne] = useState<any>()
    const saveListIzin = (e: any) => {
        dispatch(addListPerizinan(e))
        setShowFormAddListIzin(false)
        setShowAlert(true)
    }
    const editListIzin = (e: any) => {
        dispatch(updateListPerizinan({ id: saveOne?.id, update: e }))
        setShowFormEditListIzin(false)
        setShowAlert(true)
    }
    const hapusListIzin = () => {
        dispatch(dropListPerizinan(saveOne?.id))
        setShowFormDeleteListIzin(false)
        setShowAlert(true)
    }
    useEffect(() => {
        if (showAlert) {
            const interval = setInterval(() => {
                setShowAlert(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            dispatch(listIzinActions.clearListIzin())
            dispatch(allListPerizinan())
        }
    }, [showAlert])
    return (
        <div>
            <Loading show={loadingListIzin} />
            <Alert show={showAlert} msg={msgListIzin} />
            <HeaderUi title="List Izin" />
            <AddListIzin showForm={showFormAddListIzin} closeForm={setShowFormAddListIzin} submit={saveListIzin} />
            <EditListIzin showForm={showFormEditListIzin} closeForm={setShowFormEditListIzin} submit={editListIzin} nama={saveOne?.nama} />
            <DialogDelete msg="Anda yakin ingin menghapus?" show={showFormDeleteListIzin} close={setShowFormDeleteListIzin} onClick={hapusListIzin} />
            <Table>
                <div className='flex justify-end'>
                    <div className='flex items-end'>
                        <Button type="button" title="Tambah" onClick={() => setShowFormAddListIzin(true)} />
                    </div>
                </div>
            </Table>
            <Table>
                {Object.keys(listIzinAll).length === 0 ?
                    <EmptyTable title="Tidak ada list izin" />
                    :
                    <TableFields head={tableHead}>
                        {listIzinAll.map((d: any, id: any) => (
                            <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                <td className='px-6 py-3'>{id + 1}</td>
                                <td className='px-6 py-3'>{d.nama}</td>
                                <td className='px-6 py-3'><HiOutlinePencilSquare className='w-6 h-6  cursor-pointer' onClick={() => {
                                    setSaveOne(d)
                                    setShowFormEditListIzin(true)
                                }} /></td>
                                <td className='px-6 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                    setSaveOne(d)
                                    setShowFormDeleteListIzin(true)
                                }} /></td>
                            </tr>
                        ))}

                    </TableFields>
                }
            </Table>
        </div>
    )
}

export default ListIzin