import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiPenjemput } from "../../api/penjemput";
import { ErrorData } from "../interfaces";


export const allListPenjemput =  createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'list/penjemput/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiPenjemput.getAllListPenjemput()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const allListPenjemputBySantri = createAsyncThunk<[],any,{rejectValue:ErrorData}>(
    'list/penjemput/santri',
    async(uuid:any,{rejectWithValue})=>{
        try{
            const res = await ApiPenjemput.getListPenjemputBysantri(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const addListPenjemput = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'list/penjemput/add',
    async(data:any,{rejectWithValue})=>{
        try{
            const res = await ApiPenjemput.postListPenjmput(data)
            if(res.status ===200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const updateListPenjemput = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'list/penjemput/update',
    async(data:any,{rejectWithValue})=>{
        try{
            const res = await ApiPenjemput.updateListPenjemput(data.uuid,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)

export const dropListPenjemput = createAsyncThunk<any,string,{rejectValue:ErrorData}>(
    'list/penjemput/drop',
    async(uuid,{rejectWithValue})=>{
        try{
            const res = await ApiPenjemput.deleteListPenjemput(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)