import { MondokAttributes } from "../type";
import api from "./http";

export const ApiSantri  = {
    getByKey : (key:string) =>{
        return api.get(`/santri/gender/${key}`)
    },
    getOne : (uuid:string) =>{
        return api.get(`/santri/get/${uuid}`)
    },
    getOneStatus: (uuid:string)=>{
        return api.get(`/perizinan/status-mondok/${uuid}`)
    },
    putStatus : (data:MondokAttributes)=>{
        return api.put(`/perizinan/status-mondok`,data)
    }
}