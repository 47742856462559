import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiKamar } from "../../api/kamar";
import { ErrorData } from "../interfaces";

export const allListKamar = createAsyncThunk<string[],void,{rejectValue:ErrorData}>(
    'list/kamar/get',
    async(_,{rejectWithValue})=>{
        try{
            const res : any =  await ApiKamar.getList()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const allListKamarByGender = createAsyncThunk<[],string,{rejectValue:ErrorData}>(
    'list/kamar/get/gender',
    async(key:string,{rejectWithValue})=>{
        try{
            const res : any = await ApiKamar.getListByGender(key)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const addListKamar = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'list/kamar/post',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any =  await ApiKamar.postList(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const editListKamar = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'list/kamar/update',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any = await ApiKamar.updateList(data.uuid,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const dropListKamar = createAsyncThunk<any,string,{rejectValue:ErrorData}>(
    'list/kamar/delete',
    async(uuid:string,{rejectWithValue})=>{
        try{
            const res : any = await ApiKamar.deleteList(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
