import React, { useEffect, useState } from 'react'
import { HiOutlineTrash } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../component/fields/Input'
import Selector from '../component/fields/Selector'
import DialogDelete from '../component/others/DialogDelete'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { allAkumulasipointByKey, dropAkumulasi } from '../store/actions/akumulasiPoint'
import { allThnAjar } from '../store/actions/options'
import { akumualasiPointAction } from '../store/slice/akumulasiPoint'
import Alert from '../templates/Alert'
import HeaderUi from '../templates/HeaderUi'
import Loading from '../templates/Loading'
import Table from '../templates/Table'
import TableFields from '../templates/TableFields'
import EmptyTable from '../templates/EmptyTable'

function AkumulasiPoint() {
  const dispatch: TypeDispatch<any> = useAppDispatch()
  const { akumulasiAll, loadingAkumulasi, msgAkumulasi } = useSelector((state: RootState) => state.akumulasiPoint)
  const { tahunAjar } = useSelector((state: any) => state.options)
  const [saveOne, setSaveOne] = useState<any>({})
  const [showDeleteAkumulasi, setShowDeleteAkumulasi] = useState<boolean>(false)
  const [showAlertAkumulasi, setShowAlertAkumulasi] = useState<boolean>(false)
  const [headTable, setHeadTable] = useState(["No", "Thn Ajar", "Nuwb", "Nama", "Lembaga", "Total Point", "Total Prestasi", "Keseluruhan", "Delete"])
  const [key, setKey] = useState<string>("")
  const [tahun, setTahun] = useState<any>({})
  const tahunOpt = tahunAjar.map((d: any) => {
    return { value: d.tahun, label: d.tahun }
  })
  const hapusAkumulasi = () => {
    dispatch(dropAkumulasi(saveOne.uuid))
    setShowDeleteAkumulasi(false)
    setShowAlertAkumulasi(true)
  }
  useEffect(() => {
    if (Object.keys(tahunAjar).length !== 0) {
      tahunAjar.map((d: any) => {
        if (d.active) {
          setTahun({ value: d.tahun, label: d.tahun })
        }
      })
    }
  }, [tahunAjar])
  useEffect(() => {
    if (showAlertAkumulasi) {
      const interval = setInterval(() => {
        setShowAlertAkumulasi(false)
      }, 2000)
      return () => clearInterval(interval)
    }
    else {
      if (key.length > 2 && Object.keys(tahun).length !== 0) {
        dispatch(allAkumulasipointByKey({ key: key, thn: tahun.value?.replace("/", "-") }))
      }
    }
  }, [showAlertAkumulasi, key, tahun])
  useEffect(() => {
    dispatch(akumualasiPointAction.clearAkumulasi())
    dispatch(allThnAjar())
  }, [])
  return (
    <div>
      <Loading show={loadingAkumulasi} />
      <Alert show={showAlertAkumulasi} msg={msgAkumulasi} />
      <DialogDelete msg="Apakah anda yakin ingin menghapus?" show={showDeleteAkumulasi} close={setShowDeleteAkumulasi} onClick={hapusAkumulasi} />
      <HeaderUi title="Akumulasi Point" />
      <Table>
        <div className='flex justify-start space-x-3'>
          <div className='w-72 mt-[3px]'>
            <Selector title="Tahun ajar" value={tahun} onChange={(e: any) => setTahun(e)} options={tahunOpt} />
          </div>
          <div className='w-72'>
            <Input label="Masukkan nama/nuwb" value={key} onChange={(e: any) => setKey(e.target.value)} />
          </div>
        </div>
      </Table>
      <Table>
        {Object.keys(akumulasiAll).length === 0 ?
          <EmptyTable title="Tidak ada akumulasi" />
          :
          <TableFields
            head={headTable}
          >
            {akumulasiAll.map((d: any, id: any) => (
              <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                <td className='px-6 py-3'>{id + 1}</td>
                <td className='px-6 py-3'>{d.thn_ajar}</td>
                <td className='px-6 py-3'>{d.santri?.nuwb}</td>
                <td className='px-6 py-3'>{d.santri?.nama}</td>
                <td className='px-6 py-3'>{d.santri?.lembaga?.nama}</td>
                <td className='px-6 py-3'>{d.totalPoint}</td>
                <td className='px-6 py-3'>{d.totalPrestasi}</td>
                <td className='px-6 py-3'>{d.totalPrestasi - d.totalPoint}</td>
                <td className='px-6 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                  setSaveOne(d)
                  setShowDeleteAkumulasi(true)
                }} /></td>
              </tr>
            ))}
          </TableFields>
        }
      </Table>
    </div>
  )
}

export default AkumulasiPoint