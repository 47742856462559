import React, { useEffect, useState } from 'react'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import { allDasboardSantri, allMostManyPerizinan, allMostManyPoint, allMostManyPrestasi, allPerizinanByIzin } from '../store/actions/dashboard'
import { IoPeopleCircleOutline } from "react-icons/io5"
import { allListPerizinan } from '../store/actions/listIzin'
import Selector from '../component/fields/Selector'
import Loading from '../templates/Loading'
import CardDashboard from '../templates/CardDashboard'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'
import { useNavigate } from 'react-router-dom'

function Dashboard() {
  const dispatch: TypeDispatch<any> = useAppDispatch()
  const navigate = useNavigate()
  const [idRekap, setIdRekap] = useState<any>({})
  const { generalUser, role } = useSelector((state: RootState) => state.auth)
  const { dashboardSantriAll, mostManyPoint, mostManyPrestasi, mostManyPerizinan, loadingDashboard } = useSelector((state: RootState) => state.dashboard)
  const { listIzinAll, loadingListIzin } = useSelector((state: RootState) => state.listIzin)
  const [tableHead, setTableHead] = useState(["No", "Nuwb", "Nama", "Lembaga", "Kelas", "Ruang","Kamar", "Total"])
  const [listRekapTerbanyak, setListRekapTerbanya] = useState([
    { value: 1, label: "Point Terbanyak" },
    { value: 2, label: "Prestasi Terbanyak" },
    { value: 3, label: "Izin Terbanyak" },
  ])
  useEffect(() => {
    dispatch(allDasboardSantri())
    dispatch(allListPerizinan())
  }, [])
  useEffect(() => {
    if (idRekap?.value === 1) {
      dispatch(allMostManyPoint())
    }
    else if (idRekap?.value === 2) {
      dispatch(allMostManyPrestasi())
    }
    else {
      dispatch(allMostManyPerizinan())
    }
  }, [idRekap])
  return (
    <div>
      <Loading show={loadingDashboard} />
      <HeaderUi title="Dashboard" />
      <Table>
        <div className='flex md:flex-row flex-col gap-5'>
          {generalUser || role === "L" ?
            <CardDashboard title="Santri Putra di pondok" value={dashboardSantriAll?.santriPutraDiPondok} onClick={() => navigate(`/home/dashboard/santri/L`)} />
            :
            <></>
          }
          {generalUser || role === "P" ?
            <CardDashboard title="Santri Putri di pondok" value={dashboardSantriAll?.santriPutriDiPondok} onClick={() => navigate(`/home/dashboard/santri/P`)} />
            :
            <></>
          }
          {generalUser || role === "L" ?
            <CardDashboard title="Santri Putra yang izin" value={dashboardSantriAll?.santriPutraPulang} onClick={() => navigate(`/home/dashboard/perizinan/L`)} />
            :
            <></>
          }
          {generalUser || role === "P" ?
            <CardDashboard title="Santri Putri yang izin" value={dashboardSantriAll?.santriPutriPulang} onClick={() => navigate(`/home/dashboard/perizinan/P`)} />
            :
            <></>
          }
        </div>
      </Table>
      <Table>
        <div className=' w-80'>
          <Selector title="Pilih Rekapan" value={idRekap} onChange={(e: any) => setIdRekap(e)} options={listRekapTerbanyak} />
        </div>
      </Table>
      <Table>
        {Object.keys(idRekap).length === 0 ?
          <EmptyTable title="Tidak ada rekapan" />
          :
          <TableFields head={tableHead}>
            {idRekap?.value === 1 ?
              mostManyPoint.map((d: any, id: any) => (
                <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                  <td className='px-6 py-3'>{id + 1}</td>
                  <td className='px-6 py-3'>{d.santri?.nuwb}</td>
                  <td className='px-6 py-3'>{d.santri?.nama}</td>
                  <td className='px-6 py-3'>{d.santri?.lembaga?.nama}</td>
                  <td className='px-6 py-3'>{d.santri?.kelas}</td>
                  <td className='px-6 py-3'>{d.santri?.ruang}</td>
                  <td className='px-6 py-3'>{d.santri?.kamar_santri?.list_kamar?.namaKamar || '-'}</td>
                  <td className='px-6 py-3'>{d.totalPoint}</td>
                </tr>
              ))
              :
              idRekap?.value === 2 ?
                mostManyPrestasi.map((d: any, id: any) => (
                  <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                    <td className='px-6 py-3'>{id + 1}</td>
                    <td className='px-6 py-3'>{d.santri?.nuwb}</td>
                    <td className='px-6 py-3'>{d.santri?.nama}</td>
                    <td className='px-6 py-3'>{d.santri?.lembaga?.nama}</td>
                    <td className='px-6 py-3'>{d.santri?.kelas}</td>
                    <td className='px-6 py-3'>{d.santri?.ruang}</td>
                    <td className='px-6 py-3'>{d.santri?.kamar_santri?.list_kamar?.namaKamar || '-'}</td>
                    <td className='px-6 py-3'>{d.totalPrestasi}</td>
                  </tr>
                ))
                :
                mostManyPerizinan.map((d: any, id: any) => (
                  <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                    <td className='px-6 py-3'>{id + 1}</td>
                    <td className='px-6 py-3'>{d.nuwb}</td>
                    <td className='px-6 py-3'>{d.nama}</td>
                    <td className='px-6 py-3'>{d.lembaga?.nama}</td>
                    <td className='px-6 py-3'>{d.kelas}</td>
                    <td className='px-6 py-3'>{d.ruang}</td>
                    <td className='px-6 py-3'>{d.kamar_santri?.list_kamar?.namaKamar || "-"}</td>
                    <td className='px-6 py-3'>{d?.perizinans?.length}</td>
                  </tr>
                ))
            }
          </TableFields>
        }
      </Table>
    </div>
  )
}

export default Dashboard