import { useEffect, useState } from 'react'
import Loading from '../templates/Loading'
import Alert from '../templates/Alert'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import { milikOpt } from '../component/Gender'
import Selector from '../component/fields/Selector'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import { listKamarAction } from '../store/slice/listKamar'
import { kamarSantriAction } from '../store/slice/kamarSantri'
import { allListKamar, allListKamarByGender } from '../store/actions/listKamar'
import { getAllAbsensi, postAbsensi } from '../store/actions/absensi'
import Button from '../component/others/Button'
import { AbsensiActions } from '../store/slice/absensi'

export default function AbsensiSantri() {
  const dispatch: TypeDispatch<any> = useAppDispatch()
  const { allAbsensi, loadingAbsensi, messageAbsensi } = useSelector((state: RootState) => state.absensi)
  const { listKamarAll, loadingListKamar, msgListKamar } = useSelector((state: RootState) => state.listKamar)
  const tableHead = ["", "No", "Nuwb", "Nama", "Lembaga", "kamar"]
  const { role, super_admin, generalUser } = useSelector((state: RootState) => state.auth)
  const [gender, setGender] = useState<any>({})
  const [kamar, setKamar] = useState<any>({})
  const [showAlert, setShowAlert] = useState(false)
  const [uuidSantri, setuuidSantri] = useState<string[]>([])
  const kamarOpt = listKamarAll.map((d: any) => {
    return { value: d.uuid, label: d.namaKamar }
  })
  useEffect(() => {
    dispatch(listKamarAction.clearList())
    dispatch(kamarSantriAction.clearKamarSantri())
    if (super_admin) {
      if (gender.value !== '-') {
        dispatch(allListKamarByGender(gender.value))
      }
      else {
        dispatch(allListKamar())
      }
    }
    else {
      if (role === "putra") {
        dispatch(allListKamarByGender("L"))
      }
      else {
        dispatch(allListKamarByGender("P"))
      }
    }
  }, [gender, role, super_admin])
  useEffect(() => {
    if (showAlert) {
      const interval = setInterval(() => {
        setShowAlert(false)
      }, 2000)
      return () => clearInterval(interval)
    }
    else {
      if (kamar.value !== undefined) {
        dispatch(getAllAbsensi(kamar?.value))
      }
    }
  }, [showAlert, gender, kamar])
  useEffect(() => {
    if (allAbsensi.length > 0) {
      let saveUuid = allAbsensi.map((e: any) => {
        return e.uuid
      })
      setuuidSantri(saveUuid)
    }
  }, [allAbsensi])
  const saveAbsen = () => {
    if (uuidSantri.length > 0 && kamar?.value) {
      dispatch(postAbsensi({uuid_kamar:kamar?.value,santri:uuidSantri}))
      dispatch(AbsensiActions.clearAbsensi())
      setGender({})
      setKamar({})
      setShowAlert(true)
    }
  }
  return (
    <div>
      <Loading show={loadingAbsensi} />
      <Alert show={showAlert} msg={messageAbsensi} />
      <HeaderUi title="Absensi Santri" />
      <Table>
        <div className='flex justify-between'>
          <div className=' grid grid-cols-2 gap-4 w-[50%]'>
            <div className={`${generalUser ? "block" : "hidden"}`}>
              <Selector title="Asrama" value={gender} onChange={(e: any) => setGender(e)} options={milikOpt} />
            </div>
            <Selector title="Kamar" value={kamar} onChange={(e: any) => setKamar(e)} options={kamarOpt} />
          </div>
          <div className='mt-6'>
            <Button title='Absen' type='button' onClick={saveAbsen} />
          </div>
        </div>
      </Table>
      <Table>
        {Object.keys(allAbsensi).length === 0 ?
          <EmptyTable title='Tidak ada santri' />
          :
          <TableFields head={tableHead}>
            {allAbsensi.map((d: any, id: any) => (
              <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                <td className='px-6 py-3'>
                  <input type='checkbox' defaultChecked={true} onClick={(e: any) => {
                    if (e.target?.checked) {
                      setuuidSantri([...uuidSantri, d.uuid])
                    } else {
                      let index = uuidSantri.indexOf(d.uuid)
                      uuidSantri.splice(index, 1)
                      setuuidSantri([...uuidSantri])
                    }
                  }} />
                </td>
                <td className='px-6 py-3'>{id + 1}</td>
                <td className='px-6 py-3'>{d.nuwb}</td>
                <td className='px-6 py-3'>{d.nama}</td>
                <td className='px-6 py-3'>{d.lembaga?.nama}</td>
                <td className='px-6 py-3'>{d.kamar_santri?.list_kamar?.namaKamar}</td>
              </tr>
            ))}
          </TableFields>
        }
      </Table>
    </div>
  )
}
