import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAbsensi } from "../../api/absensi";

export const getAllAbsensi = createAsyncThunk(
    'get/absensi/all',
    async(uuid_kamar:string,{rejectWithValue})=>{
        try{
            const res = await ApiAbsensi.get(uuid_kamar)
            if(res.status === 200){
                return res.data
            }
        }catch(r:any){
            return rejectWithValue(r.response?.data?.msg)
        }
    }
)

export const postAbsensi = createAsyncThunk(
    'post/absensi',
    async(data:{uuid_kamar:string,santri:string[]},{rejectWithValue})=>{
        try{
            const res = await ApiAbsensi.post(data)
            if(res.status === 200){
                return res.data
            }
        }catch(r:any){
            return rejectWithValue(r.response?.data?.msg)
        }
    }
)