import { createSlice } from "@reduxjs/toolkit";
import { allPrestasiSantri,allPrestasiBySantrinThn,addPrestasiSantri,updatePrestasiSantri, allCanceledPrestasiSantri, dropPrestasiSantri, dropCanceledPrestasiSantri, generatePdfPrestasiLembaga, generatePdfPrestasiSantri, allPrestasiSantriByDate } from "../actions/prestasiSantri";
import { PrestasiSantri } from "../interfaces";

const initialState : PrestasiSantri = {
    prestasiSantriAll : [],
    canceledPrestasiAll : [],
    prestasiSantriOne : {},
    loadingPrestasiSantri : false,
    succesPdfPrestasi:false,
    msgPrestasiSantri : ""
}

export const prestasiSantriStore = createSlice({
    name:"prestasiSantri",
    initialState,
    reducers : {
        clearPrestasiSantri : (state)=>{
            state.prestasiSantriAll = []
            state.msgPrestasiSantri = ""
        },
        clearPrestasiSantriOne : (state) =>{
            state.prestasiSantriOne = {}
            state.msgPrestasiSantri = ""
        },
        clearCanceled : (state)=>{
            state.canceledPrestasiAll = []
            state.msgPrestasiSantri = ""
        },
        clearPdf :(state)=>{
            state.succesPdfPrestasi = false
            state.msgPrestasiSantri = ""
        }
    },
    extraReducers : builder =>{
        builder
        .addCase(allPrestasiSantri.pending,(state)=>{
            state.loadingPrestasiSantri =  true
        })
        .addCase(allPrestasiSantri.fulfilled,(state,action)=>{
            state.loadingPrestasiSantri =false
            state.prestasiSantriAll = action.payload
        })
        .addCase(allPrestasiSantri.rejected,(state,action)=>{
            state.loadingPrestasiSantri = false
            state.prestasiSantriAll = []
            state.msgPrestasiSantri = action.payload?.message
        })
        .addCase(allCanceledPrestasiSantri.pending,(state)=>{
            state.loadingPrestasiSantri =  true
        })
        .addCase(allCanceledPrestasiSantri.fulfilled,(state,action)=>{
            state.loadingPrestasiSantri =false
            state.canceledPrestasiAll = action.payload
        })
        .addCase(allCanceledPrestasiSantri.rejected,(state,action)=>{
            state.loadingPrestasiSantri = false
            state.canceledPrestasiAll = []
            state.msgPrestasiSantri = action.payload?.message
        })
        .addCase(allPrestasiBySantrinThn.pending,(state)=>{
            state.loadingPrestasiSantri =  true
        })
        .addCase(allPrestasiBySantrinThn.fulfilled,(state,action)=>{
            state.loadingPrestasiSantri =false
            state.prestasiSantriAll = action.payload
        })
        .addCase(allPrestasiBySantrinThn.rejected,(state,action)=>{
            state.loadingPrestasiSantri = false
            state.prestasiSantriAll = []
            state.msgPrestasiSantri = action.payload?.message
        })
        .addCase(allPrestasiSantriByDate.pending,(state)=>{
            state.loadingPrestasiSantri =  true
        })
        .addCase(allPrestasiSantriByDate.fulfilled,(state,action)=>{
            state.loadingPrestasiSantri =false
            state.prestasiSantriAll = action.payload
        })
        .addCase(allPrestasiSantriByDate.rejected,(state,action)=>{
            state.loadingPrestasiSantri = false
            state.prestasiSantriAll = []
            state.msgPrestasiSantri = action.payload
        })
        .addCase(addPrestasiSantri.pending,(state)=>{
            state.loadingPrestasiSantri =  true
        })
        .addCase(addPrestasiSantri.fulfilled,(state,action)=>{
            state.loadingPrestasiSantri =false
            state.msgPrestasiSantri = action.payload.msg
        })
        .addCase(addPrestasiSantri.rejected,(state,action)=>{
            state.loadingPrestasiSantri = false
            state.msgPrestasiSantri = action.payload?.message
        })
        .addCase(updatePrestasiSantri.pending,(state)=>{
            state.loadingPrestasiSantri =  true
        })
        .addCase(updatePrestasiSantri.fulfilled,(state,action)=>{
            state.loadingPrestasiSantri =false
            state.msgPrestasiSantri = action.payload.msg
        })
        .addCase(updatePrestasiSantri.rejected,(state,action)=>{
            state.loadingPrestasiSantri = false
            state.msgPrestasiSantri = action.payload?.message
        })
        .addCase(dropPrestasiSantri.pending,(state)=>{
            state.loadingPrestasiSantri =  true
        })
        .addCase(dropPrestasiSantri.fulfilled,(state,action)=>{
            state.loadingPrestasiSantri =false
            state.msgPrestasiSantri = action.payload.msg
        })
        .addCase(dropPrestasiSantri.rejected,(state,action)=>{
            state.loadingPrestasiSantri = false
            state.msgPrestasiSantri = action.payload?.message
        })
        .addCase(dropCanceledPrestasiSantri.pending,(state)=>{
            state.loadingPrestasiSantri =  true
        })
        .addCase(dropCanceledPrestasiSantri.fulfilled,(state,action)=>{
            state.loadingPrestasiSantri =false
            state.msgPrestasiSantri = action.payload.msg
        })
        .addCase(dropCanceledPrestasiSantri.rejected,(state,action)=>{
            state.loadingPrestasiSantri = false
            state.msgPrestasiSantri = action.payload?.message
        })
        .addCase(generatePdfPrestasiSantri.pending,(state)=>{
            state.loadingPrestasiSantri =  true
            state.succesPdfPrestasi =false
        })
        .addCase(generatePdfPrestasiSantri.fulfilled,(state,action)=>{
            state.loadingPrestasiSantri =false
            state.msgPrestasiSantri = action.payload.msg
            state.succesPdfPrestasi = true
        })
        .addCase(generatePdfPrestasiSantri.rejected,(state,action)=>{
            state.loadingPrestasiSantri = false
            state.msgPrestasiSantri = action.payload?.message
            state.succesPdfPrestasi =false
        })
        .addCase(generatePdfPrestasiLembaga.pending,(state)=>{
            state.loadingPrestasiSantri =  true
            state.succesPdfPrestasi =false
        })
        .addCase(generatePdfPrestasiLembaga.fulfilled,(state,action)=>{
            state.loadingPrestasiSantri =false
            state.msgPrestasiSantri = action.payload.msg
            state.succesPdfPrestasi = true
        })
        .addCase(generatePdfPrestasiLembaga.rejected,(state,action)=>{
            state.loadingPrestasiSantri = false
            state.msgPrestasiSantri = action.payload?.message
            state.succesPdfPrestasi =false
        })


    }
})

export const prestasiSantriActions = prestasiSantriStore.actions
export default prestasiSantriStore.reducer