import { PenjmputAttributes } from "../type";
import api from "./http";

export const ApiPenjemput = {
    getAllListPenjemput : ()=>{
        return api.get(`/perizinan/list-penjemput`)
    },
    getListPenjemputBysantri : (uuid:string)=>{
        return api.get(`/perizinan/list-penjemput/santri/${uuid}`)
    },
    postListPenjmput : (data:PenjmputAttributes)=>{
        return api.post(`/perizinan/list-penjemput`,data)
    },
    updateListPenjemput :(uuid:string,data:PenjmputAttributes)=>{
        return api.put(`/perizinan/list-penjemput/${uuid}`,data)
    },
    deleteListPenjemput : (uuid:string)=>{
        return api.delete(`/perizinan/list-penjemput/${uuid}`)
    }
}