import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import InputForm from '../fields/InputForm'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import RadioForm from '../fields/RadioForm'
import Button from '../others/Button'
import TextAreaForm from '../fields/TextAreaForm'
import { FormAttributes } from './interfaces'

function AddPembinaan({closeForm,showForm,submit,data}:FormAttributes) {
    const methodAddPembinaan = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                deskripsi : yup.string().required("tidak boleh kosong"),
                // status : yup.string().oneOf(["true","false"],"pilih salah satu").required("tidak boleh kosong"),
                uuidPointSantri : yup.string().required("tidak boleh kosong"),
            })
        ),
        // defaultValues:{status:"true"}
    })
    useEffect(()=>{
        if(Object.keys(data).length !== 0){
            methodAddPembinaan.reset({uuidPointSantri:data?.uuid,deskripsi:""})
        }
    },[showForm,data])
    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Pembinaan Santri</h1>
                </div>
                <form onSubmit={methodAddPembinaan.handleSubmit(submit)} className='px-6 py-7 space-y-1'>
                    <TextAreaForm label="deskripsi" method={methodAddPembinaan} methodName="deskripsi"/>
                    {/* <div>
                        <h1 className='font-light text-lg mb-2'>Sudah melakukan bimbingan</h1>
                        <div className='flex my-auto md:space-x-0 space-x-2'>
                            <RadioForm method={methodAddPembinaan} methodName="status" value_="true" label="Iya" />
                            <RadioForm method={methodAddPembinaan} methodName="status" value_="false" label="Tidak" />
                        </div>
                        {methodAddPembinaan.formState.errors["status"] && (
                            <p className="text-red-600 mt-1 mx-1">
                                {methodAddPembinaan.formState.errors["status"].message}
                            </p>
                        )}
                    </div> */}
                    <div className='flex justify-end'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default AddPembinaan