import { createSlice } from "@reduxjs/toolkit";
import { allDasboardSantri, allDasboardSantriByLem, allDashboardListPerizinan, allMostManyPerizinan, allMostManyPoint, allMostManyPrestasi, allPerizinanByIzin, allPerizinanByLembaga, getAllSantriInAsrama } from "../actions/dashboard";
import { Dashboard } from "../interfaces";

const initialState: Dashboard = {
    dashboardSantriAll: [],
    perizinanByIzinAll: [],
    dashboardByLembaga : [],
    perizinanByLembaga : [],
    allSantriInAsrama :[],
    loadingDashboard: false,
    mostManyPerizinan: [],
    mostManyPoint: [],
    mostManyPrestasi: [],
    msgDashboard: ""
}

export const dashboardStore = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        clearDashboard: (state) => {
            state.dashboardSantriAll = []
            state.msgDashboard = ""
        },
        clearPerizinan: (state) => {
            state.perizinanByIzinAll = []
            state.msgDashboard = ""
        }
    },
    extraReducers: builder => {
        builder
            .addCase(allDasboardSantri.pending, (state) => {
                state.loadingDashboard = true
            })
            .addCase(allDasboardSantri.fulfilled, (state, action) => {
                state.loadingDashboard = false
                state.dashboardSantriAll = action.payload
            })
            .addCase(allDasboardSantri.rejected, (state, action) => {
                state.loadingDashboard = false
                state.msgDashboard = action.payload?.message
                state.dashboardSantriAll = []
            })
            .addCase(allDasboardSantriByLem.pending, (state) => {
                state.loadingDashboard = true
            })
            .addCase(allDasboardSantriByLem.fulfilled, (state, action) => {
                state.loadingDashboard = false
                state.dashboardByLembaga = action.payload
            })
            .addCase(allDasboardSantriByLem.rejected, (state, action) => {
                state.loadingDashboard = false
                state.msgDashboard = action.payload?.message
                state.dashboardSantriAll = []
            })
            .addCase(allPerizinanByIzin.pending, (state) => {
                state.loadingDashboard = true
            })
            .addCase(allPerizinanByIzin.fulfilled, (state, action) => {
                state.loadingDashboard = false
                state.perizinanByIzinAll = action.payload
            })
            .addCase(allPerizinanByIzin.rejected, (state, action) => {
                state.loadingDashboard = false
                state.msgDashboard = action.payload?.message
                state.perizinanByIzinAll = []
            })
            .addCase(allDashboardListPerizinan.pending, (state) => {
                state.loadingDashboard = true
            })
            .addCase(allDashboardListPerizinan.fulfilled, (state, action) => {
                state.loadingDashboard = false
                state.perizinanByIzinAll = action.payload
            })
            .addCase(allDashboardListPerizinan.rejected, (state, action) => {
                state.loadingDashboard = false
                state.msgDashboard = action.payload?.message
                state.perizinanByIzinAll = []
            })
            .addCase(allMostManyPoint.pending, (state) => {
                state.loadingDashboard = true
            })
            .addCase(allMostManyPoint.fulfilled, (state, action) => {
                state.loadingDashboard = false
                state.mostManyPoint = action.payload
            })
            .addCase(allMostManyPoint.rejected, (state, action) => {
                state.loadingDashboard = false
                state.msgDashboard = action.payload?.message
                state.mostManyPoint = []
            })
            .addCase(allMostManyPerizinan.pending, (state) => {
                state.loadingDashboard = true
            })
            .addCase(allMostManyPerizinan.fulfilled, (state, action) => {
                state.loadingDashboard = false
                state.mostManyPerizinan = action.payload
            })
            .addCase(allMostManyPerizinan.rejected, (state, action) => {
                state.loadingDashboard = false
                state.msgDashboard = action.payload?.message
                state.mostManyPerizinan = []
            })
            .addCase(allMostManyPrestasi.pending, (state) => {
                state.loadingDashboard = true
            })
            .addCase(allMostManyPrestasi.fulfilled, (state, action) => {
                state.loadingDashboard = false
                state.mostManyPrestasi = action.payload
            })
            .addCase(allMostManyPrestasi.rejected, (state, action) => {
                state.loadingDashboard = false
                state.msgDashboard = action.payload?.message
                state.mostManyPrestasi = []
            })
            .addCase(allPerizinanByLembaga.pending, (state) => {
                state.loadingDashboard = true
            })
            .addCase(allPerizinanByLembaga.fulfilled, (state, action) => {
                state.loadingDashboard = false
                state.perizinanByLembaga = action.payload
            })
            .addCase(allPerizinanByLembaga.rejected, (state, action) => {
                state.loadingDashboard = false
                state.msgDashboard = action.payload?.message
                state.perizinanByLembaga = []
            })
            .addCase(getAllSantriInAsrama.pending, (state) => {
                state.loadingDashboard = true
            })
            .addCase(getAllSantriInAsrama.fulfilled, (state, action) => {
                state.loadingDashboard = false
                state.allSantriInAsrama = action.payload
            })
            .addCase(getAllSantriInAsrama.rejected, (state, action) => {
                state.loadingDashboard = false
                state.msgDashboard = action.payload?.message
                state.allSantriInAsrama = []
            })


    }
})

export const dashboardActions = dashboardStore.actions
export default dashboardStore.reducer;