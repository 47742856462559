import { createSlice } from "@reduxjs/toolkit";
import { allAkumulasipoint,addAkumulasiPoint,dropAkumulasi, allAkumulasipointByKey } from "../actions/akumulasiPoint";
import { AkumulasiPoint } from "../interfaces";

const initialState : AkumulasiPoint = {
    akumulasiAll : [],
    akumulasiOne : {},
    loadingAkumulasi : false,
    msgAkumulasi : ""
}
export const akumualasiPointStore = createSlice({
    name:"akumulasiPoint",
    initialState,
    reducers:{
        clearAkumulasi : (state)=>{
            state.akumulasiAll = []
            state.msgAkumulasi = ""
        },
        clearOneAkumulasi : (state) =>{
            state.akumulasiOne = {}
        }
    },
    extraReducers : builder =>{
        builder
        .addCase(allAkumulasipoint.pending,(state)=>{
            state.loadingAkumulasi = true
        })
        .addCase(allAkumulasipoint.fulfilled,(state,action)=>{
            state.loadingAkumulasi = false
            state.akumulasiAll = action.payload
        })
        .addCase(allAkumulasipoint.rejected,(state,action)=>{
            state.loadingAkumulasi = false
            state.akumulasiAll = []
            state.msgAkumulasi = action.payload?.message
        })
        .addCase(allAkumulasipointByKey.pending,(state)=>{
            state.loadingAkumulasi = true
        })
        .addCase(allAkumulasipointByKey.fulfilled,(state,action)=>{
            state.loadingAkumulasi = false
            state.akumulasiAll = action.payload
        })
        .addCase(allAkumulasipointByKey.rejected,(state,action)=>{
            state.loadingAkumulasi = false
            state.akumulasiAll = []
            state.msgAkumulasi = action.payload?.message
        })
        .addCase(addAkumulasiPoint.pending,(state)=>{
            state.loadingAkumulasi = true
        })
        .addCase(addAkumulasiPoint.fulfilled,(state,action)=>{
            state.loadingAkumulasi = false
            state.msgAkumulasi = action.payload.msg
        })
        .addCase(addAkumulasiPoint.rejected,(state,action)=>{
            state.loadingAkumulasi = false
            state.msgAkumulasi = action.payload?.message
        })
        .addCase(dropAkumulasi.pending,(state)=>{
            state.loadingAkumulasi = true
        })
        .addCase(dropAkumulasi.fulfilled,(state,action)=>{
            state.loadingAkumulasi = false
            state.msgAkumulasi = action.payload.msg
        })
        .addCase(dropAkumulasi.rejected,(state,action)=>{
            state.loadingAkumulasi = false
            state.msgAkumulasi = action.payload?.message
        })
    }
})
export const akumualasiPointAction = akumualasiPointStore.actions
export default akumualasiPointStore.reducer