import React, { useEffect, useState } from 'react'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useParams } from 'react-router-dom'
import { allPerizinanBySantri } from '../store/actions/perizinan'
import { useSelector } from 'react-redux'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'
import Loading from '../templates/Loading'
import { formatDay } from '../helper/formatTime'

function PerizinanOneSantri() {
    const { uuid } = useParams<any>()
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { perizinanAll, loadingPerizinan } = useSelector((state: RootState) => state.perizinanSantri)
    const [tableHead, setTableHead] = useState(["No", "Nuwb", "Nama", "Penjemput", "Keterangan", "Dari", "Sampai", "Waktu kembali", "Status"])
    useEffect(() => {
        if (uuid !== "") {
            dispatch(allPerizinanBySantri(uuid))
        }
    }, [uuid])
    return (
        <div>
            <Loading show={loadingPerizinan} />
            <HeaderUi title="Perizinan Santri" />
            <Table>
                {perizinanAll.length === 0 ?
                    <EmptyTable title="Tidak ada perizinan" />
                    :
                    <TableFields head={tableHead}>
                        {perizinanAll.map((d: any, id: number) => (
                            <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                <td className='px-6 py-3'>{id + 1}</td>
                                <td className='px-6 py-3'>{d.santri?.nuwb}</td>
                                <td className='px-6 py-3'>{d.santri?.nama}</td>
                                <td className='px-6 py-3'>{d.listPenjemput?.nama}</td>
                                <td className='px-6 py-3'>{d.keterangan}</td>
                                <td className='px-6 py-3'>{d.dari ? formatDay(d.dari) : "-"}</td>
                                <td className='px-6 py-3'>{d.sampai ? formatDay(d.sampai) : "-"}</td>
                                <td className='px-6 py-3'>{d.waktu_balik ? formatDay(d.waktu_balik) : "-"}</td>
                                <td className='px-6 py-3'>{d.status ? "Kembali" : "Belum Kembali"}</td>
                            </tr>
                        ))}
                    </TableFields>
                }
            </Table>
        </div>
    )
}

export default PerizinanOneSantri