import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiOptions } from "../../api/options";
import { ErrorData } from "../interfaces";

export const allThnAjar = createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'thn/ajar/get',
    async(_,{rejectWithValue})=>{
        try{
            const res : any =  await ApiOptions.getThnAjar()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
) 
export const allLembaga = createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'lembaga/get',
    async(_,{rejectWithValue})=>{
        try{
            const res : any = await ApiOptions.getLembaga()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const allSistem = createAsyncThunk<any,void,{rejectValue:ErrorData}>(
    'sistem/get',
    async(_,{rejectWithValue})=>{
        try{
            const res : any = await ApiOptions.sistem()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message: err.response.data.msg})
        }
    }
)
export const allRole = createAsyncThunk<any,string,{rejectValue:ErrorData}>(
    'role/get',
    async(uuid,{rejectWithValue})=>{
        try{
            const res : any = await ApiOptions.role(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message: err.response.data.msg})
        }
    }
)
export const allListLembaga = createAsyncThunk<any,void,{rejectValue:ErrorData}>(
    'lembaga/list/all',
    async (_, { rejectWithValue }) => {
        try {
            const res = await ApiOptions.LembagaList()
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err:any) {
            return rejectWithValue({message: err.response.data.msg})
        }
    }
)