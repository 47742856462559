import { createSlice } from "@reduxjs/toolkit";
import { allListKamar,addListKamar,editListKamar,dropListKamar, allListKamarByGender } from "../actions/listKamar";
import { ListKamar } from "../interfaces";

const initialState : ListKamar = {
    listKamarAll : [],
    listKamarOne : {},
    loadingListKamar : false,
    msgListKamar : "",
}

export const listKamarStore = createSlice({
    name : "listKamar",
    initialState,
    reducers:{
        clearList : (state:any) =>{
            state.listKamarAll = []
            state.msgListKamar = ""
        },
        clearOneList : (state:any) =>{
            state.listKamarOne = {}
            state.msgListKamar = ""
        }
    },
    extraReducers : builder => {
        builder
        .addCase(allListKamar.pending,(state)=>{
            state.loadingListKamar = true
        })
        .addCase(allListKamar.fulfilled,(state,action)=>{
            state.loadingListKamar = false
            state.listKamarAll = action.payload
        })
        .addCase(allListKamar.rejected,(state,action)=>{
            state.loadingListKamar = false
            state.listKamarAll = []
            state.msgListKamar = action.payload?.message
        })
        .addCase(allListKamarByGender.pending,(state)=>{
            state.loadingListKamar = true
        })
        .addCase(allListKamarByGender.fulfilled,(state,action)=>{
            state.loadingListKamar = false
            state.listKamarAll = action.payload
        })
        .addCase(allListKamarByGender.rejected,(state,action)=>{
            state.loadingListKamar = false
            state.listKamarAll = []
            state.msgListKamar = action.payload?.message
        })
        .addCase(addListKamar.pending,(state)=>{
            state.loadingListKamar = true
        })
        .addCase(addListKamar.fulfilled,(state,action)=>{
            state.loadingListKamar = false
            state.msgListKamar = action.payload.msg
        })
        .addCase(addListKamar.rejected,(state,action)=>{
            state.loadingListKamar = false
            state.msgListKamar = action.payload?.message
        })
        .addCase(editListKamar.pending,(state)=>{
            state.loadingListKamar = true
        })
        .addCase(editListKamar.fulfilled,(state,action)=>{
            state.loadingListKamar = false
            state.msgListKamar = action.payload.msg
        })
        .addCase(editListKamar.rejected,(state,action)=>{
            state.loadingListKamar = false
            state.msgListKamar = action.payload?.message
        })
        .addCase(dropListKamar.pending,(state)=>{
            state.loadingListKamar = true
        })
        .addCase(dropListKamar.fulfilled,(state,action)=>{
            state.loadingListKamar = false
            state.msgListKamar = action.payload.msg
        })
        .addCase(dropListKamar.rejected,(state,action)=>{
            state.loadingListKamar = false
            state.msgListKamar = action.payload?.message
        })
    }
})
export const listKamarAction = listKamarStore.actions
export default listKamarStore.reducer