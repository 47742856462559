import React, { useEffect, useState } from 'react'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import Input from '../component/fields/Input'
import Button from '../component/others/Button'
import { HiOutlinePencilSquare, HiOutlineTrash } from 'react-icons/hi2'
import { listPrestasiActions } from '../store/slice/listPrestasi'
import { addListPrestasi, allListPrestasi, allListPrestasiByKey, dropListPrestasi, editListPrestasi } from '../store/actions/listPrestasi'
import AddListPrestasi from '../component/form/AddListPrestasi'
import EditListPrestasi from '../component/form/EditListPrestasi'
import Loading from '../templates/Loading'
import Alert from '../templates/Alert'
import DialogDelete from '../component/others/DialogDelete'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'

function ListPrestasi() {
  const dispatch: TypeDispatch<any> = useAppDispatch()
  const { listPrestasiAll, loadingPrestasi, msgPrestasi } = useSelector((state: RootState) => state.listPrestasi)
  const [saveOne, setSaveOne] = useState<any>({})
  const [key, setKey] = useState<string>("")
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [showFormAddPrestasi, setShowFormAddPrestasi] = useState<boolean>(false)
  const [showFormEditPerstasi, setShowFormEditPrestasi] = useState<boolean>(false)
  const [showFormDeletePrestasi, setShowFormDeletePrestasi] = useState<boolean>(false)
  const [tableHead, setTableHead] = useState(["No", "Judul", "Deskripsi", "Jumlah Point", "Akumulasi", "Edit", "Delete"])
  const tambahList = (e: any) => {
    dispatch(addListPrestasi(e))
    setShowFormAddPrestasi(false)
    setShowAlert(true)
  }
  const editList = (e: any) => {
    dispatch(editListPrestasi({ uuid: saveOne.uuid, update: e }))
    setShowFormEditPrestasi(false)
    setShowAlert(true)
  }
  const hapusList = () => {
    dispatch(dropListPrestasi(saveOne.uuid))
    setShowFormDeletePrestasi(false)
    setShowAlert(true)
  }
  useEffect(() => {
    if (showAlert) {
      const interval = setInterval(() => {
        setShowAlert(false)
      }, 2000)
      return () => clearInterval(interval)
    }
    else {
      dispatch(listPrestasiActions.clearPrestasi())
      if (key.length > 2) {
        dispatch(allListPrestasiByKey(key))
      }
      else {
        dispatch(allListPrestasi())
      }
    }
  }, [showAlert, key])
  return (
    <div>
      <Loading show={loadingPrestasi} />
      <Alert show={showAlert} msg={msgPrestasi} />
      <AddListPrestasi showForm={showFormAddPrestasi} closeForm={setShowFormDeletePrestasi} submit={tambahList} />
      <EditListPrestasi showForm={showFormEditPerstasi} closeForm={setShowFormEditPrestasi} data={saveOne} submit={editList} />
      <DialogDelete msg="Anda yakin ingin menghapus?" show={showFormDeletePrestasi} close={setShowFormDeletePrestasi} onClick={hapusList} />
      <HeaderUi title="List Prestasi" />
      <Table>
        <div className='flex justify-between'>
          <div className='w-72'>
            <Input label="Masukkan judul" value={key} onChange={(e: any) => setKey(e.target.value)} />
          </div>
          <div className='flex items-end'>
            <Button type="button" title="Tambah" onClick={() => setShowFormAddPrestasi(true)} />
          </div>
        </div>
      </Table>
      <Table>
        {Object.keys(listPrestasiAll).length === 0 ?
          <EmptyTable title="Tidak ada list prestasi" />
          :
          <TableFields head={tableHead}>
            {listPrestasiAll.map((d: any, id: any) => (
              <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                <td className='px-6 py-3'>{id + 1}</td>
                <td className='px-6 py-3'>{d.judul}</td>
                <td className='px-6 py-3'>{d.deskripsi}</td>
                <td className='px-6 py-3'>{d.jumlahPoint}</td>
                <td className='px-6 py-3'>{d.accumulate ? "Iya" : "Tidak"}</td>
                <td className='px-6 py-3'><HiOutlinePencilSquare className='w-6 h-6  cursor-pointer' onClick={() => {
                  setSaveOne(d)
                  setShowFormEditPrestasi(true)
                }} /></td>
                <td className='px-6 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                  setSaveOne(d)
                  setShowFormDeletePrestasi(true)
                }} /></td>
              </tr>
            ))}

          </TableFields>
        }
      </Table>
    </div>
  )
}

export default ListPrestasi