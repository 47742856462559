import React, { useEffect, useState } from 'react'
import { HiOutlinePencilSquare, HiOutlineTrash } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../component/fields/Input'
import AddListPoint from '../component/form/AddListPoint'
import EditListPoint from '../component/form/EditListPoint'
import Button from '../component/others/Button'
import DialogDelete from '../component/others/DialogDelete'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { addListPoint, allListPoint, allListPointByKey, dropListPoint, editListPoint } from '../store/actions/listPoint'
import { listPointAction } from '../store/slice/listPoint'
import Alert from '../templates/Alert'
import HeaderUi from '../templates/HeaderUi'
import Loading from '../templates/Loading'
import Table from '../templates/Table'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'

function ListPoint() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { listPointAll, loadingListPoint, msgListPoint } = useSelector((state: RootState) => state.listPoint)
    const [saveOne, setSaveOne] = useState<any>({})
    const [key, setKey] = useState<string>("")
    const [tableHead, setTableHead] = useState(["No", "Judul", "Deskripsi", "Jumlah Point", "Akumulasi", "Edit", "Delete"])
    const [showFormAddPoint, setShowFormAddPoint] = useState<boolean>(false)
    const [showFormEditPoint, setShowFormEditPoint] = useState<boolean>(false)
    const [showDeletePoint, setShowDeletePoint] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const tambah = (e: any) => {
        dispatch(addListPoint(e))
        setShowFormAddPoint(false)
        setShowAlert(true)
    }
    const edit = (e: any) => {
        dispatch(editListPoint({ uuid: saveOne.uuid, update: e }))
        setShowFormEditPoint(false)
        setShowAlert(true)

    }
    const hapus = () => {
        dispatch(dropListPoint(saveOne.uuid))
        setShowDeletePoint(false)
        setShowAlert(true)
    }
    useEffect(() => {
        if (showAlert) {
            const interval = setInterval(() => {
                setShowAlert(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            dispatch(listPointAction.clearListPoint())
            if (key.length > 2) {
                dispatch(allListPointByKey(key))
            }
            else {
                dispatch(allListPoint())
            }
        }
    }, [showAlert, key])
    return (
        <div>
            <Loading show={loadingListPoint} />
            <Alert show={showAlert} msg={msgListPoint} />
            <AddListPoint showForm={showFormAddPoint} closeForm={setShowFormAddPoint} submit={tambah} />
            <EditListPoint showForm={showFormEditPoint} closeForm={setShowFormEditPoint} data={saveOne} submit={edit} />
            <DialogDelete msg="Anda yakin ingin menghapus?" show={showDeletePoint} close={setShowDeletePoint} onClick={hapus} />
            <HeaderUi title="List Point" />
            <Table>
                <div className='flex justify-between'>
                    <div className='w-72'>
                        <Input label="Masukkan judul" value={key} onChange={(e: any) => setKey(e.target.value)} />
                    </div>
                    <div className='flex items-end'>
                        <Button type="button" title="Tambah" onClick={() => setShowFormAddPoint(true)} />
                    </div>
                </div>
            </Table>
            <Table>
                {Object.keys(listPointAll).length === 0 ?
                    <EmptyTable title="Tidak ada list point" />
                    :
                    <TableFields head={tableHead}>
                        {listPointAll.map((d: any, id: any) => (
                            <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                <td className='px-6 py-3'>{id + 1}</td>
                                <td className='px-6 py-3'>{d.judul}</td>
                                <td className='px-6 py-3'>{d.deskripsi}</td>
                                <td className='px-6 py-3'>{d.jumlahPoint}</td>
                                <td className='px-6 py-3'>{d.accumulate ? "Iya" : "Tidak"}</td>
                                <td className='px-6 py-3'><HiOutlinePencilSquare className='w-6 h-6  cursor-pointer' onClick={() => {
                                    setSaveOne(d)
                                    setShowFormEditPoint(true)
                                }} /></td>
                                <td className='px-6 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                    setSaveOne(d)
                                    setShowDeletePoint(true)
                                }} /></td>
                            </tr>
                        ))}
                    </TableFields>
                }
            </Table>
        </div>
    )
}

export default ListPoint