import React from 'react'
import Select from 'react-select'

interface SelectorAttributes{
    title:string;
    options:Array<{label:string|number,value:string|number}>
    value:{label:string|number,value:string|number}
    onChange:(e:any)=>void;
    defaultValue?:any;
}

function Selector({title,defaultValue,onChange,options,value}:SelectorAttributes) {
    return (
        <div className='space-y-[1px]'>
            <label className='font-light ml-[2px]'>{title}</label>
            <Select
            styles={{
                control: (baseStyles, state:any) => ({
                    ...baseStyles,
                    borderRadius: "4px",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                    borderColor: state.isTouched || state.isDirty ? "#624d4d" : "#624d4d",
                    borderWidth: "2px"
                }),
            }}
            options={options}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            />
        </div>
    )
}

export default Selector