import React, { useEffect, useState } from 'react'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import Button from '../component/others/Button'
import AddPenjemput from '../component/form/AddPenjemput'
import { useParams } from 'react-router-dom'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import { listPenjemputActions } from '../store/slice/listPenjemput'
import { addListPenjemput, allListPenjemputBySantri, dropListPenjemput, updateListPenjemput } from '../store/actions/listPenjemput'
import { HiOutlinePencilSquare, HiOutlineTrash } from 'react-icons/hi2'
import EditPenjemput from '../component/form/EditPenjemput'
import DialogDelete from '../component/others/DialogDelete'
import Loading from '../templates/Loading'
import Alert from '../templates/Alert'
import EmptyTable from '../templates/EmptyTable'
import TableFields from '../templates/TableFields'

function ListPenjemput() {
    const params = useParams<any>()
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { listPenjemputAll, loadingListPenjemput, msgListPenjemput } = useSelector((state: RootState) => state.listPenjemput)
    const [showFormAddPenjemput, setShowFormAddPenjemput] = useState<boolean>(false)
    const [showFormEditPenjemput, setShowFormEditPenjemput] = useState<boolean>(false)
    const [showFormDeletePenjemput, setShowFormDeletePenjemput] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [tableHead, setTableHead] = useState(["No", "Nama Penjemput", "Status Penjemput", "No Telpon", "Edit", "Delete"])
    const [saveOne, setSaveOne] = useState<any>()
    const savePenjemput = (e: any) => {
        dispatch(addListPenjemput(e))
        setShowFormAddPenjemput(false)
        setShowAlert(true)
    }
    const editPenjemput = (e: any) => {
        dispatch(updateListPenjemput({ uuid: saveOne?.uuid, update: e }))
        setShowFormEditPenjemput(false)
        setShowAlert(true)
    }
    const hapusPenjemput = () => {
        dispatch(dropListPenjemput(saveOne?.uuid))
        setShowFormDeletePenjemput(false)
        setShowAlert(true)
    }
    useEffect(() => {
        if (showAlert) {
            const interval = setInterval(() => {
                setShowAlert(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            dispatch(listPenjemputActions.clearListPenjemput())
            dispatch(allListPenjemputBySantri(params?.uuid))
        }
    }, [showAlert, params])
    return (
        <div>
            <Loading show={loadingListPenjemput} />
            <Alert show={showAlert} msg={msgListPenjemput} />
            <AddPenjemput showForm={showFormAddPenjemput} closeForm={setShowFormAddPenjemput} uuid={params?.uuid} submit={savePenjemput} />
            <EditPenjemput showForm={showFormEditPenjemput} closeForm={setShowFormEditPenjemput} data={saveOne} submit={editPenjemput} />
            <DialogDelete msg="Anda yakin ingin menghapus?" show={showFormDeletePenjemput} close={setShowFormDeletePenjemput} onClick={hapusPenjemput} />
            <HeaderUi title="List Penjemput" />
            <Table>
                <div className='flex justify-end'>
                    <div className='flex items-end'>
                        <Button type="button" title="Tambah" onClick={() => setShowFormAddPenjemput(true)} />
                    </div>
                </div>
            </Table>
            <Table>
                {Object.keys(listPenjemputAll).length === 0 ?
                    <EmptyTable title='Tidak ada list penjemput' />
                    :
                    <TableFields head={tableHead}>
                        {listPenjemputAll.map((d: any, id: any) => (
                            <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                <td className='px-6 py-3'>{id + 1}</td>
                                <td className='px-6 py-3'>{d.nama}</td>
                                <td className='px-6 py-3'>{d.status_penjemput}</td>
                                <td className='px-6 py-3'>{d.no_telpon}</td>
                                <td className='px-6 py-3'><HiOutlinePencilSquare className='w-6 h-6  cursor-pointer' onClick={() => {
                                    setSaveOne(d)
                                    setShowFormEditPenjemput(true)
                                }} /></td>
                                <td className='px-6 py-3'><HiOutlineTrash className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                    setSaveOne(d)
                                    setShowFormDeletePenjemput(true)
                                }} /></td>
                            </tr>
                        ))}

                    </TableFields>
                }
            </Table>
        </div>
    )
}

export default ListPenjemput