import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import Dialog from '../../templates/Dialog'
import RadioForm from '../fields/RadioForm'
import Button from '../others/Button'
import SelectorForm from '../fields/SelectorForm'
import { RootState, TypeDispatch, useAppDispatch } from '../../store'
import { allListPointByUuid } from '../../store/actions/listPoint'
import { useSelector } from 'react-redux'
import Input from '../fields/Input'
import TextAreaForm from '../fields/TextAreaForm'
import InputForm from '../fields/InputForm'
import { FormAttributes } from './interfaces'

function AddPointSantri({closeForm,showForm,submit,uuid,thnOpt,pointOpt}:FormAttributes) {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { listPointOne, listPointAll } = useSelector((state: RootState) => state.listPoint)
    const methodAddPoint = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                uuid_santri: yup.string().required("tidak boleh kosong"),
                uuid_point: yup.string().required("tidak boleh kosong"),    
                thn_ajar: yup.string().required("tidak boleh kosong"),
                akumulasi: yup.boolean().required("tidak boleh kosong"),
                status: yup.boolean().required("tidak boleh kosong"),
                deskripsi: yup.string().required("tidak boleh kosong"),
                tanggal_kejadian:yup.string().required("tidak boleh kosong")
            })
        ),
        defaultValues : {akumulasi:false,status:false}
    })
    const watchPoint = methodAddPoint.watch("uuid_point")
    useEffect(() => {
        methodAddPoint.reset({ uuid_santri: uuid })
    }, [showForm, uuid])
    useEffect(() => {
        if (showForm && (watchPoint !== undefined || watchPoint !== "")) {
            dispatch(allListPointByUuid(watchPoint))
            methodAddPoint.setValue("akumulasi",false)
            methodAddPoint.setValue("status",false)
        }
    }, [watchPoint, showForm])
    return (
        <Dialog show={showForm} toggle={closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Tambah Point Santri</h1>
                </div>
                <form onSubmit={methodAddPoint.handleSubmit(submit)} className='px-6 py-7'>
                    <div className='mb-4 space-y-1'>
                        <SelectorForm label="Point" method={methodAddPoint} methodName="uuid_point" options={pointOpt||[]} />
                        <div className={`${watchPoint !== undefined && watchPoint !== "" && watchPoint !== null ? "block" : "hidden"}`}>
                            <Input className="w-full" label="Point" value={listPointOne !== undefined && listPointOne !== null && Object.keys(listPointOne)?.length !== 0 ? listPointOne?.jumlahPoint : 0} read={true} />
                        </div>
                        <SelectorForm label="Tahun ajar" method={methodAddPoint} methodName="thn_ajar" options={thnOpt||[]} />
                        <InputForm label="Tanggal Kejadian" type="date" method={methodAddPoint} methodName="tanggal_kejadian"/>
                        <TextAreaForm method={methodAddPoint} methodName="deskripsi" label="Deskripsi" />
                    </div>
                    <div className='flex justify-end'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default AddPointSantri